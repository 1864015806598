<template>
  <div>
    <v-card flat class="mx-4 rounded-10">
      <v-data-table
        :headers="headers"
        :items="users"
        hide-default-footer
        class="rounded-10"
      >
        <template v-slot:no-data v-if="loading">
          <v-progress-circular indeterminate />
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{ item.firstName + " " + item.lastName }}
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>

      <v-dialog v-model="dialog" max-width="550px" content-class="rounded-20">
        <v-card>
          <v-toolbar flat color="off">
            <v-toolbar-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text class="pb-0 pt-5">
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="editedUser.firstName"
                  label="First Name"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="editedUser.lastName"
                  label="Last Name"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="editedUser.email"
                  label="Email"
                  outlined
                  dense
                />
                <v-text-field
                  v-model="editedUser.phoneNumber"
                  label="Phone Number"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                  :items="[
                    { text: 'Owner', value: 'owner' },
                    { text: 'Member', value: 'member' }
                  ]"
                  :menu-props="{ rounded: '10' }"
                  label="Role"
                  v-model="editedUser.role"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="off">
            <v-spacer></v-spacer>
            <v-btn class="btn-error" @click="close"> Cancel </v-btn>
            <v-btn class="btn-primary" @click="save"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
        content-class="rounded-20"
      >
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete this item?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="btn-error" @click="closeDelete">Cancel</v-btn>
            <v-btn class="btn-primary" @click="deleteItemConfirm">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <v-card-actions class="mx-2">
      <v-spacer></v-spacer>
      <v-btn @click="dialog = true" class="btn-primary" text> Add User</v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "AuthorizedTenantUsers",

  data() {
    return {
      loading: false,
      dialog: false,
      editedIndex: -1,
      dialogDelete: false,
      editedUser: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        role: ""
      },
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "email",
          sortable: false,
          value: "email"
        },
        {
          text: "Phone Number",
          sortable: false,
          value: "phoneNumber"
        },
        {
          text: "Role",
          sortable: false,
          value: "role"
        },
        {
          text: "Actions",
          sortable: false,
          value: "action"
        }
      ],
      users: []
    }
  },

  async beforeMount() {
    this.loading = true
    const fetchTenantRolesResponse =
      await client.gateway.tenants.fetchTenantRoles()
    fetchTenantRolesResponse.forEach((role) => {
      const phoneNumber = role.id_strings.find((item) => /\+[0-9]+/.test(item))
      const email = role.id_strings.find((item) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(item)
      )

      this.users.push({
        firstName: role.first_name,
        lastName: role.last_name,
        email,
        phoneNumber,
        role: role.role,
        userUid: role.user_uid
      })
    })
    this.loading = false
  },

  computed: {
    ...mapGetters(["currentTenant", "signUpSession"]),

    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User"
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  methods: {
    editUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedUser = Object.assign({}, item)
      this.dialog = true
    },

    deleteUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedUser = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      if (
        this.editedUser.role === "owner" &&
        this.users.filter((item) => item.role === "owner").length === 1
      ) {
        this.dialogDelete = false
        return
      }
      this.users.splice(this.editedIndex, 1)

      client.gateway.tenants.deleteRole(this.editedUser.userUid)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedUser = Object.assign(
          {},
          { name: "", email: "", phoneNumber: "", role: "" }
        )
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedUser = Object.assign(
          {},
          { name: "", email: "", phoneNumber: "", role: "" }
        )
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedUser)
        client.gateway.tenants.modifyRole(
          {
            // tenant_uid: this.currentTenant.uid,
            // first_name: this.editedUser.firstName,
            // last_name: this.editedUser.lastName,
            // email_address: this.editedUser.email,
            // phone_number: this.editedUser.phoneNumber,
            role: this.editedUser.role.toLowerCase()
          },
          this.editedUser.userUid
        )
      } else {
        this.users.push(this.editedUser)
        client.gateway.tenants.addRole({
          tenant_uid: this.currentTenant.uid,
          first_name: this.editedUser.firstName,
          last_name: this.editedUser.lastName,
          email_address: this.editedUser.email,
          phone_number: this.editedUser.phoneNumber,
          role: this.editedUser.role.toLowerCase()
        })
      }
      this.close()
    }
  }
}
</script>
