<template>
  <v-dialog
    v-model="showModal"
    content-class="rounded-20"
    max-width="600"
    overlay-opacity="0.8"
  >
    <v-card width="600">
      <v-card-title> Lets get started... </v-card-title>
      <v-card-text class="py-0">
        <BasicInformation
          ref="basicInformation"
          v-model="signUpForm"
          :id-string="idString"
        />
      </v-card-text>
      <v-card-actions class="pb-4 mr-2">
        <v-spacer></v-spacer>
        <v-btn class="btn-primary rounded-10" @click="onSignUp()">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import BasicInformation from "./SignUp/BasicInformation"
import client from "@/lib/ApiClient"

export default {
  name: "SignUp",

  props: ["value", "selectedPlanCode"],

  components: {
    BasicInformation
  },

  data() {
    return {
      signUpForm: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      },
      idString: null
    }
  },

  watch: {
    value(value) {
      if (!value) {
        return
      }
      this.signUpForm.email = this.introspection.email
      this.signUpForm.phone = this.introspection.phone
      this.introspection.email
        ? (this.idString = "email")
        : (this.idString = "phone")
    }
  },

  computed: {
    ...mapGetters(["introspection"]),

    showModal: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    ...mapActions(["setMe"]),

    async onSignUp() {
      if (!this.$refs.basicInformation.validateForm()) {
        return
      }
      try {
        const form = {
          first_name: this.signUpForm.firstName,
          last_name: this.signUpForm.lastName,
          email_address: this.signUpForm.email,
          phone_number: this.signUpForm.phone
        }
        await client.gateway.users.signUp(form)

        const meResponse = await client.gateway.users.fetchMe()
        if (meResponse) {
          this.setMe(meResponse)
        }

        this.$router.push({
          name: "registrationPage",
          params: { planCode: this.selectedPlanCode }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
