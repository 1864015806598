<template>
  <v-card flat class="mr-4 rounded-20">
    <v-card-title class="title-font">
      Case Chat
      <v-spacer />
      <v-tooltip top class="rounded-10">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!fullmode"
            @click="$emit('fullmode')"
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon> mdi-arrow-expand-all </v-icon>
          </v-btn>
        </template>
        <span>Expand Chat</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>
      Use the Case Chat for all case related information and discussion
    </v-card-subtitle>
    <v-card-text>
      <MessageComponent
        :messages="messages"
        :approvals="approvals"
        :loading-message="loadingMessage"
        :sending-message="sendingMessage"
        @compose="sendMessage"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import client from "@/lib/ApiClient"
import MessageComponent from "./CaseMessage/MessageComponent.vue"

export default {
  components: { MessageComponent },

  props: ["order", "fullmode"],

  data() {
    return {
      loadingMessage: false,
      sendingMessage: false,
      messages: [],
      approvals: []
    }
  },

  methods: {
    async fetchMessages() {
      this.messages = await client.instance.messages.fetchMessages(
        this.order.uid
      )
    },
    async sendMessage(formData) {
      this.sendingMessage = true
      await client.instance.messages.sendMessage(this.order.uid, formData)
      this.fetchMessages()
      this.sendingMessage = false
    }
  },

  async mounted() {
    this.loadingMessage = true
    await this.fetchMessages()
    this.loadingMessage = false
  }
}
</script>
