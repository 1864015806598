<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0739 2.75H8.24992C6.52144 2.75 5.65719 2.75 5.12022 3.28697C4.58325 3.82394 4.58325 4.68818 4.58325 6.41667V15.5833C4.58325 17.3118 4.58325 18.1761 5.12022 18.713C5.65719 19.25 6.52144 19.25 8.24992 19.25H13.7499C15.4784 19.25 16.3426 19.25 16.8796 18.713C17.4166 18.1761 17.4166 17.3118 17.4166 15.5833V8.09273C17.4166 7.71804 17.4166 7.53069 17.3468 7.36224C17.277 7.19378 17.1446 7.06131 16.8796 6.79636L13.3702 3.28697C13.1053 3.02203 12.9728 2.88955 12.8043 2.81978C12.6359 2.75 12.4485 2.75 12.0739 2.75Z"
      :stroke="color"
      stroke-width="2"
    />
    <path
      d="M8.25 11.9165L13.75 11.9165"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M8.25 15.5835L11.9167 15.5835"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M11.9167 2.75V6.41667C11.9167 7.28091 11.9167 7.71303 12.1852 7.98151C12.4537 8.25 12.8858 8.25 13.7501 8.25H17.4167"
      :stroke="color"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: "OrderForm",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
