<template>
  <v-card flat class="rounded-20">
    <v-card-title> Select which Business to continue with: </v-card-title>
    <v-card-text>
      <v-select
        v-model="selectedTenantUid"
        :items="tenants"
        item-text="name"
        item-value="tenant_uid"
        label="Business Name"
        :menu-props="{ rounded: '10' }"
        outlined
      >
      </v-select>
    </v-card-text>
    <v-card-text class="d-flex justify-center">
      <v-btn class="btn-primary" @click="$emit('onContinue')">Continue</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SelectTenant",

  props: ["value", "tenants"],

  computed: {
    selectedTenantUid: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    tenantNames() {
      return this.tenants.map((item) => item.name)
    }
  }
}
</script>
