<template>
  <v-row>
    <v-col>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Date"
            prepend-inner-icon="mdi-calendar"
            dense
            readonly
            outlined
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          @input="menu = false"
          color="primary"
        >
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-text-field
        v-model="time"
        label="Time"
        outlined
        dense
        rounded
        type="time"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .slice(0, 10),
      time: null,
      menu: false
    }
  }
}
</script>
