<template>
  <v-card class="rounded-20 ma-4" flat>
    <v-row>
      <v-col cols="8">
        <v-card-text class="black--text">
          To continue with abutment export, confirm the choices below.
        </v-card-text>
        <v-card-text class="black--text">
          Choose the desired Scan Body to match your desired library for
          geometry and design purposes.
        </v-card-text>
        <v-select
          v-model="scanBodyLibrary"
          label="Scan Body Library for Export"
          :items="scanBodyLibraries"
          outlined
          dense
          hide-details
          class="mx-4"
          :menu-props="{ rounded: '10' }"
        />
      </v-col>
      <v-col cols="4">
        <ModelViewer
          v-if="showModel() && !loading"
          :filename="libraryFile()"
          :url="filePath"
          :camera-positions="{ x: 0, y: -10, z: 10 }"
          :height="300"
          :width="250"
        />
      </v-col>
    </v-row>

    <!-- <v-card-text class="black--text">
      Choose if you would like a direct to MUA connection, or a Ti Base based on
      the Scan Body library.
    </v-card-text>
    <v-select
      label="Connection Type"
      outlined
      dense
      hide-details
      class="mx-4"
      :menu-props="{ rounded: '10' }"
    /> -->
    <v-card-actions class="my-4 mx-2 pb-5">
      <v-spacer />
      <v-btn class="rounded-10" elevation="0" @click="cancelDownload">
        CANCEL
      </v-btn>
      <v-btn
        class="btn-primary white--text"
        :disabled="!scanBodyLibrary"
        @click="requestDownload"
      >
        DOWNLOAD
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialog" width="30vw" content-class="rounded-20">
      <PurchaseCard
        v-model="dialog"
        :item="lineItem"
        :acquisitionMethod="acquisitionMethod"
        :loading="loading"
        @confirm="consumeExport"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import PurchaseCard from "@/components/shared/PurchaseCard.vue"
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  name: "ExportAbutment",

  props: ["order", "scanBodyLibraries"],

  components: { PurchaseCard, ModelViewer },

  data() {
    return {
      dialog: false,
      loading: false,
      scanBodyLibrary: null,
      acquisitionStrategy: null,
      lineItem: {
        description: "Abutment Position Export",
        amount: "100",
        currency: "AUD",
        quantity: 1,
        product_code: "EXPORT",
        quota_code: "exports"
      }
    }
  },

  computed: {
    ...mapGetters(["currentQuotas", "selectedOrder"]),

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "EXPORT")
        .method
    },

    exportsAvailable() {
      const exports = this.currentQuotas.find((q) => q.quota_code === "exports")
      return exports.value
    },

    filePath() {
      if (!this.scanBodyLibrary) {
        return
      }

      return `/exports/${this.libraryFile()}`
    }
  },

  watch: {
    filePath() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 100)
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas"]),

    async downloadExportTokens() {
      this.loading = true

      this.loading = false
    },

    async requestDownload() {
      this.loading = true
      const payload = {
        items: [
          {
            product_code: "EXPORT",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        payload
      )
      this.loading = false
      this.dialog = true
    },

    // TODO: downloadExport has not been implemented
    async downloadExport(token) {
      const form = {
        token: token,
        product: "EXPORT",
        library: this.scanBodyLibrary
      }
      await client.instance.orders.downloadExport(this.selectedOrder.uid, form)
      this.$emit("download")
    },

    checkTokenActivation(token) {
      if (!token) return false
      const activated = token.product_codes.find(
        (p) => p.product_code === "EXPORT"
      ).activated

      return activated > 0 ? true : false
    },

    async pollTokenActivation(token) {
      const polledToken = await client.gateway.billing.checkToken(token.uid)
      const activated = this.checkTokenActivation(polledToken)
      if (!activated)
        return setTimeout(this.pollTokenActivation, 500, polledToken)
      else await this.downloadExport(token)
    },

    async consumeExport() {
      this.loading = true

      const payload = {
        items: [
          {
            product_code: "EXPORT",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      const token = await client.gateway.billing.commitPurchase(payload)
      if (!this.checkTokenActivation(token))
        await this.pollTokenActivation(token)
      else await this.downloadExport(token)

      if (this.acquisitionMethod === "quota") {
        const quotasResponse = await client.gateway.billing.fetchQuotas()
        this.setCurrentQuotas(quotasResponse)
      }

      // Download Export

      this.dialog = false
      this.loading = false
    },

    cancelDownload() {
      this.$emit("cancelled")
    },

    showModel() {
      return ["IO 2C-A", "SG020103", "25.0081"].includes(this.scanBodyLibrary)
    },

    libraryFile() {
      if (this.scanBodyLibrary == "IO 2C-A") {
        return "IO 2C-A.stl"
      }
      if (this.scanBodyLibrary == "SG020103") {
        return "SG020103.STL"
      }
      if (this.scanBodyLibrary == "25.0081") {
        return "25.0081.STL"
      }
      return ""
    }
  }
}
</script>
