<template>
  <div>
    <div v-if="question === 1">
      <OrderOptions1 v-model="options" :order="order" />
    </div>
    <div v-if="question === 2">
      <OrderOptions2 v-model="options" :order="order" />
    </div>
    <div v-if="question === 3">
      <OrderOptions3 v-model="options" :order="order" />
    </div>
    <div v-if="question === 4">
      <OrderOptions4 v-model="options" :order="order" />
    </div>
    <div v-if="question === 5">
      <OrderSummary v-model="options" :order="order" />
    </div>
    <v-btn
      class="btn-primary white--text my-4 mx-4"
      @click="question < 5 ? question++ : handleContinue()"
    >
      {{
        question < 4
          ? "Next Question"
          : question === 4
          ? "Order Summary"
          : "Send Order"
      }}
    </v-btn>
    <v-btn
      class="rounded-10"
      elevation="0"
      @click="question > 1 ? question-- : $emit('back')"
    >
      Go Back
    </v-btn>
    <v-dialog v-model="dialog" width="30vw" content-class="rounded-20">
      <PurchaseCard
        v-model="dialog"
        :item="lineItem"
        :acquisitionMethod="acquisitionMethod"
        :loading="loading"
        @confirm="handleConfirm"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import OrderOptions1 from "./WorkflowStep1/OrderOptions1"
import OrderOptions2 from "./WorkflowStep1/OrderOptions2"
import OrderOptions3 from "./WorkflowStep1/OrderOptions3"
import OrderOptions4 from "./WorkflowStep1/OrderOptions4"
import OrderSummary from "./WorkflowStep1/OrderSummary"
import PurchaseCard from "@/components/shared/PurchaseCard.vue"

export default {
  name: "WorkflowStep1",

  props: ["order", "value"],

  components: {
    OrderOptions1,
    OrderOptions2,
    OrderOptions3,
    OrderOptions4,
    OrderSummary,
    PurchaseCard
  },

  data() {
    return {
      question: 1,
      dialog: false,
      loading: false,
      acquisitionStrategy: null,
      lineItem: {
        description: "Design Only",
        amount: "500",
        currency: "AUD",
        quantity: 1,
        product_code: "DESIGN",
        quota_code: "designs"
      }
    }
  },

  mounted() {
    this.$set(
      this.options,
      "muaConfirmation",
      this.order.responses.find((item) => item.widget === "tooth_picker").value
    )
    if (this.selectedForwardedOrder)
      this.question = Number(this.selectedForwardedOrder.step)
  },

  watch: {
    options: {
      handler(value) {
        if (!value || !this.selectedForwardedOrder) return
        const form = {
          product: "DESIGN",
          step: this.question,
          order_form: this.options
        }
        client.instance.orders.updateForwardedOrder(
          this.selectedOrder.uid,
          this.selectedForwardedOrder.uid,
          form
        )
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedForwardedOrder"]),

    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "DESIGN")
        .method
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas"]),
    async submitForwardedOrder(token) {
      const form = {
        token: token,
        product: "DESIGN",
        step: 5,
        order_form: this.options,
        submitted_at: new Date().toISOString()
      }
      await client.instance.orders.updateForwardedOrder(
        this.selectedOrder.uid,
        this.selectedForwardedOrder.uid,
        form
      )
      // client.instance.orders.changeOrderStatus(this.selectedOrder.uid, {
      //   status: "waiting"
      // })
    },

    async handleContinue() {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "DESIGN",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        purchaseForm
      )
      this.loading = false
      this.dialog = true
    },
    checkTokenActivation(token) {
      if (!token) return false
      const activated = token.product_codes.find(
        (p) => p.product_code === "DESIGN"
      ).activated

      return activated > 0 ? true : false
    },

    async pollTokenActivation(token) {
      const polledToken = await client.gateway.billing.checkToken(token.uid)
      const activated = this.checkTokenActivation(polledToken)
      if (!activated)
        return setTimeout(this.pollTokenActivation, 500, polledToken)
      else await this.submitForwardedOrder(token)
    },

    async handleConfirm() {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "DESIGN",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      const token = await client.gateway.billing.commitPurchase(purchaseForm)
      if (!this.checkTokenActivation(token))
        await this.pollTokenActivation(token)
      else await this.submitForwardedOrder(token)

      if (this.acquisitionMethod === "quota") {
        const quotasResponse = await client.gateway.billing.fetchQuotas()
        this.setCurrentQuotas(quotasResponse)
      }
      this.loading = false
      this.dialog = false
      this.$emit("next")
    }
  }
}
</script>
