<template>
  <div>
    <v-card-text
      v-for="item in quotasForOverview"
      :key="item.quota.title"
      class="pb-2"
    >
      <v-row class="my-1 px-3" align="center">
        <v-icon large>{{ item.quota.icon }}</v-icon>
        <span class="black--text font-weight-bold ml-2 title-font">
          {{ item.quota.title }}
        </span>
      </v-row>
      <v-row class="my-1 pl-14 pr-3 flex-column">
        <span class="mb-1">
          Credits Remaining - ( {{ item.credits }} of {{ item.starting }} )
        </span>
        <div
          class="flex-grow-1"
          :style="
            'display: grid; grid-template-columns: ' +
            `${item.incoming}fr ` +
            `${item.starting_rollover}fr;`
          "
        >
          <v-progress-linear
            class="rounded-lg"
            height="20"
            :value="
              Math.floor(
                (Math.min(item.incoming, item.credits) / item.incoming) * 100
              )
            "
          />
          <v-progress-linear
            class="rounded-lg"
            height="20"
            color="success"
            :value="
              Math.floor(
                (Math.max(
                  0,
                  item.starting_rollover - (item.starting - item.credits)
                ) /
                  item.starting_rollover) *
                  100
              )
            "
          />
        </div>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "QuotaOverview",

  props: ["profile"],

  computed: {
    ...mapGetters(["currentQuotas"]),

    quotasForOverview() {
      const startingAndCurrentQuotas = this.currentQuotas?.reduce((acc, q) => {
        acc[q.quota_code] = {
          credits: q.current,
          starting: q.starting,
          starting_rollover: q.starting_rollover,
          incoming: q.incoming
        }
        return acc
      }, {})

      if (!startingAndCurrentQuotas) return

      return [
        {
          quota: {
            icon: "$implantexport",
            title: "IMPLANT EXPORTS"
          },
          ...startingAndCurrentQuotas.exports
        },
        {
          quota: {
            icon: "$toothdesigns",
            title: "TOOTH DESIGNS"
          },
          ...startingAndCurrentQuotas.designs
        },
        {
          quota: {
            icon: "$milledbar",
            title: "MILLED BARS"
          },
          ...startingAndCurrentQuotas.bars
        }
      ]
    }
  }
}
</script>
