<template>
  <v-card flat>
    <!-- <v-card-title>
      <v-icon class="mr-2">mdi-palette</v-icon>
      Theme
    </v-card-title> -->
    <v-card-text>
      <v-card flat>
        <v-card-title>Primary</v-card-title>
        <v-color-picker
          v-model="settings.colours.primary"
          dot-size="25"
          swatches-max-height="200"
        ></v-color-picker>
      </v-card>
      <v-card flat>
        <v-card-title>Secondary</v-card-title>
        <v-color-picker
          v-model="settings.colours.secondary"
          dot-size="25"
          swatches-max-height="200"
        ></v-color-picker>
      </v-card>
      <v-card flat>
        <v-card-title>Accent</v-card-title>
        <v-color-picker
          v-model="settings.colours.accent"
          dot-size="25"
          swatches-max-height="200"
        ></v-color-picker>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-btn class="btn-primary" @click="apply">Apply</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ThemeSelector",

  props: ["value"],

  computed: {
    settings() {
      return this.value
    }
  },

  methods: {
    apply() {
      this.$vuetify.theme.themes.light = this.settings.colours
    }
  }
}
</script>
