<template>
  <div>
    <v-divider class="mx-4" />
    <DistalExtension
      :implantLocations="options.muaConfirmation"
      v-model="options.distalExtension"
    />
    <TissueRelationship v-model="options.tissueRelationship" />
  </div>
</template>

<script>
import DistalExtension from "@/components/shared/DistalExtension"
import TissueRelationship from "@/components/shared/TissueRelationship"

export default {
  name: "OrderOptions2",

  components: { DistalExtension, TissueRelationship },

  props: ["value", "order"],

  computed: {
    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
