<template>
  <v-card flat class="rounded-20">
    <v-card-title class="title-font"> SCAN GAUGE SCAN ALIGNMENT </v-card-title>
    <v-card-text class="text-center mt-5">
      <v-progress-circular v-if="loading" indeterminate />
      <div id="alignmentCanvas" style="width: 500px; height: 300px"></div>
    </v-card-text>
    <v-card-text class="pa-0">
      <v-card flat class="rounded-20">
        <v-card-title>RESULTS</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn small text class="btn-primary" @click="$emit('redo')">
                <v-icon class="mr-2">mdi-restore</v-icon>
                Redo Association
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                text
                small
                class="btn-primary"
                @click="$emit('toggleViewLibAssociation')"
              >
                <v-icon class="mr-2">$gauge</v-icon>
                START LIBRARY ASSOCIATION
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import * as NCLabDemo from "@/lib/nclab_demo_3d"

export default {
  name: "AlignmentScan",

  props: ["order"],

  data() {
    return {
      loading: false,
      instance: null
    }
  },

  methods: {
    loaded() {
      this.loading = false
    }
  },

  mounted() {
    NCLabDemo.register("alignmentCanvas").then(() => {
      NCLabDemo.showHeatmapColors("alignmentCanvas")
    })
  }
}
</script>
