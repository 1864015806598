<template>
  <div>
    <v-btn
      block
      outlined
      color="warning"
      class="rounded-10 mb-2"
      @click="dialog = true"
    >
      Approve with changes
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px" content-class="rounded-20">
      <v-card>
        <v-card-title>IMPORTANT: APPROVE WITH CHANGES</v-card-title>
        <v-card-text>
          <p>
            Your case will continue with the minor changes requested. <br />
            <strong>
              Do not continue if you wish to see another 3D design
            </strong>
            for review.
          </p>
          <v-textarea
            v-model="ApproveWithChanges"
            outlined
            hide-details
            placeholder="Enter changes to be made"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-primary">Approve and Send</v-btn>
          <v-btn elevation="0" class="rounded-10" @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      ApproveWithChanges: ""
    }
  }
}
</script>
