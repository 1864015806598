<template>
  <v-card class="card-bg pa-4 rounded-20">
    <v-row>
      <v-col cols="6">
        <v-card flat class="pa-4 rounded-10 d-flex flex-column" height="100%">
          <v-card-title class="title-font" style="word-break: break-word">
            Match the Gauge from the Kit with the Gauge in the Scan
          </v-card-title>
          <v-list>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="warning"> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span class="warning--text">
                  Only use the hollow-hemisphere markings on the top facet for
                  comparison reference.
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                It is essential to ensure each Gauge is matched with the correct
                library file from your kit.
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                Once inspected, confirm match, or choose another gauge to
                compare.
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-spacer />
          <v-card-actions class="justify-center">
            <v-btn small class="btn-primary" @click="$emit('match')"
              >Confirm Match</v-btn
            >
            <v-btn
              small
              elevation="0"
              class="rounded-10"
              @click="$emit('close')"
            >
              Choose Another Gauge from Kit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card flat class="my-3 px-3 rounded-10">
          <v-row>
            <v-col cols="6">
              <v-card flat outlined class="rounded-10">
                <v-card-subtitle class="title-font text-center">
                  SCAN GAUGE KIT: 12345 (AA)
                </v-card-subtitle>
                <v-card-text class="text-center">
                  The Scan Gauge you have selected has
                  <strong>
                    {{ selectedKitGauge?.hemisphereMarkings }} hemisphere
                    markings.
                  </strong>
                </v-card-text>
                <v-img
                  v-if="selectedKitGauge"
                  :src="selectedKitGauge.src"
                  max-height="400"
                  contain
                  class="mb-3"
                ></v-img>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card flat outlined class="rounded-10">
                <v-card-subtitle class="title-font text-center">
                  ALIGNED SCANS
                </v-card-subtitle>
                <v-card-text class="text-center">
                  Check if this Scan Gauge has
                  <strong
                    >{{ selectedKitGauge?.hemisphereMarkings }} matching
                    hemisphere markings.</strong
                  >
                </v-card-text>
                <v-img
                  v-if="selectedKitGauge"
                  :src="
                    require(`@/assets/gauge-${selectedScanGaugeIndex + 1}.png`)
                  "
                  max-height="400"
                  contain
                  class="mb-3"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "GaugeMatch",

  props: ["selectedKitGauge", "selectedScanGaugeIndex"]
}
</script>
