<template>
  <v-app fill-height>
    <!-- <AppOverlay v-if="hasTenant" :active="activeSubscription" /> -->
    <NavigationDrawer v-if="hasTenant && activeSubscription" />
    <SystemBar />
    <AppBar />
    <v-main class="gradient-bg">
      <ToolBar v-if="hasTenant && activeSubscription" />
      <v-container fluid v-if="loading">
        <v-row>
          <v-col class="text-center">
            <v-progress-circular
              indeterminate
              v-if="loading"
              size="128"
              class="my-10"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-if="!loading">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
html {
  overflow-y: auto;
}
.gradient-bg {
  background: linear-gradient(
    to right bottom,
    #cbd7df 0%,
    #eeefee 67%,
    rgba(255, 249, 234, 0.25)
  );
}
.card-bg {
  background: linear-gradient(to bottom, #eeefee 0%, #e3e3e3 100%);
}
.title-font {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600;
}

.rounded-10 {
  border-radius: 10px !important;
}

.rounded-20 {
  border-radius: 20px !important;
}

.btn-primary {
  background: linear-gradient(90deg, #147bd1, #003acd);
  color: white !important;
  border-radius: 10px !important;
  box-shadow: none;
}

.btn-success {
  background: linear-gradient(90deg, #67d114, #00ab11);
  color: white !important;
  border-radius: 10px !important;
  box-shadow: none;
}

.btn-error {
  background: linear-gradient(90deg, #e10610, #e10655);
  color: white !important;
  border-radius: 10px !important;
  box-shadow: none;
}

.btn-warning {
  background: linear-gradient(90deg, #ffe8bb, #ffc24d);
  color: black !important;
  border-radius: 10px !important;
  box-shadow: none;
}

.btn-primary-light {
  background: linear-gradient(90deg, #bbdfff, #9dc4ff);
  color: #333f48 !important;
  border-radius: 10px !important;
  box-shadow: none;
}

.v-input {
  border-radius: 10px;
}

.v-list-item,
.v-list-item::before {
  border-radius: 10px !important;
}

.v-card::before {
  border-radius: 10px !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex"
import { login } from "./lib/authentication"
import AppBar from "@/components/App/AppBar"
// import AppOverlay from "@/components/App/AppOverlay"
import SystemBar from "@/components/App/SystemBar"
import NavigationDrawer from "@/components/App/NavigationDrawer"
import ToolBar from "@/components/App/ToolBar"
import client from "@/lib/ApiClient"

export default {
  name: "App",
  components: {
    AppBar,
    // AppOverlay,
    SystemBar,
    NavigationDrawer,
    ToolBar
  },
  watch: {
    applicationInitialized(value) {
      if (
        value === true &&
        this.entryRoute !== null &&
        this.entryRoute !== this.$router.currentRoute
      ) {
        if (
          (this.entryRoute.meta.requiresLogin && !this.loggedIn) ||
          (this.entryRoute.meta.requiresRegistration && !this.registered) ||
          (this.entryRoute.meta.requiresTenant && !this.hasTenant) ||
          (this.entryRoute.meta.requiresSubscription && !this.subscription)
        )
          return
        this.$router.push(this.entryRoute)
      }
    }
  },
  computed: {
    ...mapGetters([
      "applicationInitialized",
      "entryRoute",
      "loggedIn",
      "registered",
      "country",
      "loading",
      "tenants",
      "features",
      "hasTenant",
      "currentTenant",
      "subscription",
      "activeSubscription"
    ])
  },
  methods: {
    ...mapActions([
      "setApplicationInitialized",
      "setIntrospection",
      "setPlans",
      "setInstances",
      "setCountry",
      "setToken",
      "startLoading",
      "stopLoading",
      "setMe",
      "setTenants",
      "setCurrentTenant",
      "setCurrentQuotas",
      "setQuotaRollover",
      "setFeatures",
      "setSubscription"
    ]),
    ...mapActions("nxc", ["setRoles"]),
    async detectCountry() {
      return new Promise((resolve, reject) => {
        this.$axiosAuth
          .get("https://ipapi.co/json")
          .then((response) => {
            this.setCountry(response.data.country)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  async beforeMount() {
    this.startLoading()
    await this.detectCountry()

    try {
      let authMeResponse = null
      try {
        authMeResponse = await this.$axiosAuth.get("/me")
      } catch {
        authMeResponse = null
        return login()
      }
      if (authMeResponse) {
        this.setToken(authMeResponse.data.access_token)
        this.$axios.defaults.headers.Authorization = `Bearer ${authMeResponse.data.access_token}`
      }

      try {
        this.setIntrospection(await client.gateway.users.fetchID())
      } catch (error) {
        console.error(error)
      }

      const plansResponse = await client.gateway.plans.fetchAllPlans(
        this.country
      )
      this.setPlans(plansResponse)

      const instancesResponse = await client.gateway.instances.fetchInstances()
      this.setInstances(instancesResponse)

      const meResponse = await client.gateway.users.fetchMe()
      if (meResponse) {
        this.setMe(meResponse)
        const tenantsResponse = await client.gateway.tenants.fetchUserTenants()
        this.setTenants(tenantsResponse)

        if (this.tenants.length === 1) {
          this.setCurrentTenant(this.tenants[0])
          const subscriptionResponse =
            await client.gateway.billing.subscriptionInformation()
          this.setSubscription(subscriptionResponse)

          if (subscriptionResponse?.subscription_status === "active") {
            const quotasResponse = await client.gateway.billing.fetchQuotas()
            this.setCurrentQuotas(quotasResponse)
            const quotaRolloverResponse =
              await client.gateway.billing.fetchQuotaRollover()
            this.setQuotaRollover(quotaRolloverResponse)
          }
        } else if (this.tenants.length > 1) {
          this.$router.push({
            name: "selectTenantPage",
            params: {
              tenants: this.tenants
            }
          })
        }
      }

      let callbackRoute = sessionStorage.getItem("callbackRoute")
      if (callbackRoute) {
        sessionStorage.removeItem("callbackRoute")
        callbackRoute = JSON.parse(callbackRoute)
        this.$router.push(callbackRoute)
      }

      this.setApplicationInitialized(true)
      this.stopLoading()
    } catch {
      this.setApplicationInitialized(true)
      this.stopLoading()
    }
  }
}
</script>

<style>
@media screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    background: white;
    width: 16px;
    border-radius: 0 10px 10px 0;
  }

  ::-webkit-scrollbar-track {
    border-radius: inherit;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 10px;
    border: 3px solid white;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: darkgray;
  }
}
</style>
