<template>
  <div>
    <v-divider class="mx-4" />
    <v-card-text class="pb-0">
      <v-card-title class="title-font"> ORDER SUMMARY</v-card-title>
      <v-card-text>
        Check all information in your order is correct before submitting case
        for Design.
      </v-card-text>
    </v-card-text>
    <v-card outlined class="mx-4 rounded-10">
      <v-list>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>PATIENT ID:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ order.patient_reference.toUpperCase() }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>ORDER NUMBER:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ order.order_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>DATE SUBMITTED:</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ order.created_at | luxon({ output: "date_short" }) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          v-for="(question, index) in order.responses"
          :key="index"
        >
          <v-list-item-content v-if="question.widget !== 'tooth_picker'">
            <v-list-item-title
              >{{ question.name.toUpperCase() }}:</v-list-item-title
            >
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ question.value }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-if="question.widget === 'tooth_picker'">
            <v-list-item-title> MULTI-UNIT ABUTMENT </v-list-item-title>
            <MultiUnitConfirmation
              v-model="options.muaConfirmation"
              :interactive="false"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> CONNECTION TYPE </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{
                options.connectionType === "direct"
                  ? "Direct to Multi-Unit Abutment"
                  : "Ti-Base to Multi-Unit Abutment"
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> DISTAL EXTENSION </v-list-item-title>
            <v-list-item-subtitle
              v-for="(tooth, index) in options.distalExtension"
              :key="index"
              class="font-weight-bold black--text"
            >
              <span v-if="tooth < 20">Upper Right: {{ tooth }}</span>
              <span v-if="tooth < 30 && tooth > 20"
                >Upper Left: {{ tooth }}</span
              >
              <span v-if="tooth < 40 && tooth > 30"
                >Lower Right: {{ tooth }}</span
              >
              <span v-if="tooth > 40">Lower Left: {{ tooth }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> TISSUE RELATIONSHIP </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ options.tissueRelationship?.toFixed(1) }}mm
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> UPLOADED FILES </v-list-item-title>
            <v-card-subtitle
              v-for="(file, index) in options.uploadedFiles"
              :key="index"
              class="py-0"
            >
              {{ file.name }}
            </v-card-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title> SPECIAL REQUESTS </v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold black--text">
              {{ options.specialRequests }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation"

export default {
  name: "OrderSummary",

  props: ["value", "order"],

  components: { MultiUnitConfirmation },

  data() {
    return {
      muaSet: []
    }
  },

  computed: {
    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  mounted() {
    this.muaSet = [
      ...new Set(this.options.muaConfirmation.map((item) => item.mua))
    ]
  }
}
</script>
