<template>
  <div>
    <v-btn
      block
      outlined
      color="success"
      class="rounded-10 mb-2"
      @click="dialog = true"
    >
      Approve
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px" content-class="rounded-20">
      <v-card>
        <v-card-title>IMPORTANT: APPROVAL</v-card-title>
        <v-card-text>
          Only continue is your case requires no further changes, it will
          continue to production
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-primary">Confirm</v-btn>
          <v-btn elevation="0" class="rounded-10" @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  }
}
</script>
