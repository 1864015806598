<template>
  <v-app-bar app clipped-left color="white" flat style="z-index: 99999">
    <v-spacer />
    <v-toolbar-title
      @click="navigateHome()"
      style="position: absolute; transform: translateX(-50%); left: 50%"
    >
      <v-img
        @click="navigateHome"
        contain
        max-width="400"
        style="cursor: pointer"
        :src="require('@/assets/nexus-connect-logo.svg')"
      />
    </v-toolbar-title>
    <v-spacer />
    <v-btn class="btn-primary" @click="logIn()" v-if="!loggedIn">
      <v-icon class="mr-2">mdi-login</v-icon>
      Log in
    </v-btn>
    <v-btn elevation="0" class="rounded-10" @click="logOut()" v-if="loggedIn">
      <v-icon class="mr-2">mdi-logout</v-icon>
      Log out
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex"
import { login, logout } from "@/lib/authentication"

export default {
  name: "AppBar",

  computed: {
    ...mapGetters(["loading", "loggedIn"])
  },

  methods: {
    navigateHome() {
      this.$router.push("/").catch(() => {})
    }
  },

  created() {
    this.logIn = login
    this.logOut = logout
  }
}
</script>
