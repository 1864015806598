<template>
  <v-card-text class="pb-0">
    <v-form ref="businessInfoForm" v-model="valid" lazy-validation>
      <v-text-field
        v-model="form.name"
        required
        :rules="rules"
        class="mb-n1"
        outlined
        dense
        label="Business Name*"
      />
      <vue-tel-input-vuetify
        @input="handlePhone"
        v-model="form.phone_number"
        outlined
        label="Phone Number*"
        dense
        :rules="[...rules, ...phoneRule]"
        class="mb-n1"
        :defaultCountry="country"
        :disabledFetchingCountry="true"
      />
      <v-text-field
        v-model="form.email_address"
        required
        :rules="[...rules, ...emailRule]"
        outlined
        dense
        type="email"
        label="Email*"
      />
      <BillingAddress v-model="form" />
      <ShippingAddress v-model="form" v-show="!sameShippingAddress" />
    </v-form>
  </v-card-text>
</template>

<style>
.vue-tel-input-vuetify .country-code {
  width: 45px;
  margin-right: 1em;
}
</style>

<script>
import { mapGetters } from "vuex"
import BillingAddress from "./BusinessInformation/BillingAddress.vue"
import ShippingAddress from "./BusinessInformation/ShippingAddress.vue"

const shippingDetails = [
  "shipping_address_1",
  "shipping_address_2",
  "shipping_suburb",
  "shipping_country",
  "shipping_state",
  "shipping_postal_code"
]

export default {
  name: "BusinessInformation",

  props: ["value"],

  components: {
    BillingAddress,
    ShippingAddress
  },

  data() {
    return {
      valid: true,
      rules: [(v) => !!v || "Required"],
      emailRule: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) ||
          "E-mail must be valid"
      ],
      phoneValid: false,
      phoneRule: [() => this.phoneValid || "This phone number is invalid"]
    }
  },

  watch: {
    sameShippingAddress(value) {
      if (value) {
        const copy = { ...this.value }
        shippingDetails.forEach((key) => {
          delete copy[key]
        })
        this.$emit("input", copy)
      }
      if (!value) {
        const copy = { ...this.value }
        shippingDetails.forEach((key) => {
          const billingKey = key.replace("shipping", "billing")
          copy[key] = copy[billingKey]
        })
        this.$emit("input", copy)
      }
    }
  },

  computed: {
    ...mapGetters(["country"]),
    form() {
      return this.value
    },

    sameShippingAddress() {
      return this.value.sameShippingAddress
    }
  },

  methods: {
    validateForm() {
      return this.$refs.businessInfoForm.validate()
    },

    handlePhone(input, { number, valid }) {
      this.form.phone_number = number.international || ""
      this.phoneValid = valid
    }
  }
}
</script>
