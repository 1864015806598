<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 1.5H4.5C3.675 1.5 3 2.175 3 3V15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15V6L10.5 1.5ZM13.5 15H4.5V3H10.5V6H13.5V15ZM9 12.75C8.175 12.75 7.5 12.075 7.5 11.25V7.125C7.5 6.915 7.665 6.75 7.875 6.75C8.085 6.75 8.25 6.915 8.25 7.125V11.25H9.75V7.125C9.75 6.09 8.91 5.25 7.875 5.25C6.84 5.25 6 6.09 6 7.125V11.25C6 12.9075 7.3425 14.25 9 14.25C10.6575 14.25 12 12.9075 12 11.25V8.25H10.5V11.25C10.5 12.075 9.825 12.75 9 12.75Z"
      fill="#333F48"
    />
  </svg>
</template>

<script>
export default {
  name: "FileUpload"
}
</script>
