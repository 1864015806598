<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="rounded-20">
    <v-card flat>
      <v-card-title class="off">
        <span class="text-h5">Confirm</span>
      </v-card-title>
      <v-card-text class="py-5">
        Are you sure you wish to set this as your default Payment Method?
      </v-card-text>
      <v-card-actions class="off">
        <v-spacer></v-spacer>
        <v-btn
          class="btn-error"
          text
          :disabled="loading"
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          class="btn-primary"
          :loading="loading"
          @click="$emit('setDefault')"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SetDefaultDialog",

  props: ["value", "loading"],

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(payload) {
        return this.$emit("input", payload)
      }
    }
  }
}
</script>
