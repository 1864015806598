import plans from "./mockData/plans"
import pricingList from "./mockData/pricing"
import userTenants from "./mockData/userTenants"
import tenantUsers from "./mockData/tenantUsers"

const randomInt = (max) => Math.floor(Math.random() * Math.floor(max))

const response = (payload, minDelay = 1000) => {
  const delay = randomInt(1000) + minDelay // one sec + random response time
  return new Promise((resolve) => setTimeout(() => resolve(payload), delay))
}

export default class MockClient {
  fetch_me() {
    const signUpSession = JSON.parse(sessionStorage.getItem("signUpForm"))
    const subscribeSession = JSON.parse(
      sessionStorage.getItem("subscribeResponse")
    )
    if (signUpSession && subscribeSession) {
      userTenants.push({
        name: signUpSession.business_name,
        tenant_uid: subscribeSession.tenant_uid,
        role: "owner",
        instance_url: "",
        instance_uid: signUpSession.instance_uid
      })
      return response({
        name: signUpSession.first_name.concat(" ", signUpSession.last_name),
        tenants: userTenants
      })
    } else {
      return response({
        name: "Dummy Name",
        tenants: userTenants
      })
    }
  }

  fetch_instances() {
    return response([
      {
        name: "Dummy Instance",
        url: "",
        instance_uid: "fb49c33a-b69f-4974-b72a-656303b24385"
      }
    ])
  }

  fetch_all_plans() {
    return response(plans)
  }

  fetch_pricing_for_plan(plan_code, country_code) {
    let pricing = pricingList.find(
      (item) =>
        item.plan_code === plan_code && item.country_code === country_code
    )
    return response(pricing)
  }

  async subscribe(form) {
    let pricing = await this.fetch_pricing_for_plan(
      form.plan_code,
      form.country_code
    )
    let now = new Date()
    let end =
      form.renewal_frequency === "monthly"
        ? new Date(new Date().setMonth(now.getMonth() + 1))
        : new Date(new Date().setMonth(now.getYear() + 1))
    let subscribeResponse = {
      tenant_uid: form.tenant_uid,
      plan_code: form.plan_code,
      country_code: form.country_code,
      currency_code: pricing[0].currency_code,
      price: pricing[0].price,
      subscription_status: "active",
      renewal_frequency: form.renewal_frequency,
      auto_renew: form.auto_renew,
      start_time: now,
      end_date: end,
      payments: [
        {
          payment_type: "credit_card",
          payment_status: "active",
          amount: pricing[0].price,
          currency_code: pricing[0].currency_code,
          processed_at: new Date()
        }
      ]
    }
    sessionStorage.setItem(
      "subscribeResponse",
      JSON.stringify(subscribeResponse)
    )
    return response(subscribeResponse)
  }

  sign_up(form) {
    sessionStorage.setItem("signUpForm", JSON.stringify(form))
    return response({ first_name: form.first_name, last_name: form.last_name })
  }

  fetch_users() {
    return response(tenantUsers)
  }
}
