<template>
  <div v-if="attachments.length > 0">
    <ul style="padding-left: 0; list-style-type: none">
      <li v-for="(attachment, index) in attachments" :key="index">
        <ImagePreview
          :image="attachment"
          v-if="isPreviewable(attachment) === 'image'"
          @clicked="viewFile"
        />
        <v-chip
          v-if="isPreviewable(attachment) === false"
          class="mb-1 rounded-10 primary--text"
          small
          @click="viewFile(attachment)"
        >
          <v-icon small>mdi-paperclip</v-icon>
          {{ cleanFilename(attachment) }}
        </v-chip>
      </li>
    </ul>
    <FileViewer v-model="file" />
  </div>
</template>

<script>
import ImagePreview from "./MessageAttachments/ImagePreview.vue"
import FileViewer from "@/components/shared/FileViewer.vue"

export default {
  props: ["message"],

  components: { ImagePreview, FileViewer },

  data() {
    return {
      file: null
    }
  },

  computed: {
    attachments() {
      if (!this.message?.attachments?.length) {
        return []
      }
      return this.message.attachments
    }
  },

  methods: {
    viewFile(file) {
      this.file = file
    },

    isPreviewable(attachment) {
      const fileExtension = attachment.filename.toLowerCase().split(".").pop()
      switch (fileExtension) {
        case "jpg":
        case "jpeg":
        case "png":
          return "image"
        default:
          return false
      }
    },

    cleanFilename(attachment) {
      return attachment.filename.slice(7)
    }
  }
}
</script>
