<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row>
      <v-col cols="6" class="py-0">
        <v-card flat class="card-bg rounded-20">
          <v-toolbar class="transparent" flat dense>
            <v-toolbar-title class="title-font">
              CLIENT MANAGEMENT
            </v-toolbar-title>
          </v-toolbar>
          <v-card flat class="mx-4 rounded-10">
            <v-data-table
              :headers="headers"
              :items="users"
              hide-default-footer
              class="rounded-10"
            >
              <template v-slot:no-data v-if="loading">
                <v-progress-circular indeterminate />
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-icon small class="mr-2" @click="editUser(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteUser(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
            <v-dialog
              v-model="dialog"
              max-width="550px"
              content-class="rounded-20"
            >
              <v-card>
                <v-card-title class="off">
                  <span class="title-font">{{ formTitle.toUpperCase() }}</span>
                </v-card-title>

                <v-card-text class="pb-0 pt-5">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedClient.name"
                        label="Clinic Name"
                        outlined
                        hide-details
                        class="mb-3"
                        dense
                      />
                      <v-text-field
                        v-model="editedClient.contact"
                        label="Contact Name"
                        outlined
                        hide-details
                        class="mb-3"
                        dense
                      />
                      <v-text-field
                        v-model="editedClient.email_address"
                        label="Email"
                        outlined
                        hide-details
                        class="mb-3"
                        dense
                      />
                      <v-text-field
                        v-model="editedClient.phone_number"
                        label="Phone Number"
                        outlined
                        hide-details
                        class="mb-3"
                        dense
                      />
                      <v-text-field
                        v-model="editedClient.address_line_1"
                        required
                        class="mb-3"
                        outlined
                        dense
                        hide-details
                        label="Address 1*"
                      />
                      <v-text-field
                        v-model="editedClient.address_line_2"
                        required
                        class="mb-3"
                        outlined
                        dense
                        hide-details
                        label="Address 2"
                      />

                      <v-row class="pb-0">
                        <v-col class="pr-1" cols="7">
                          <v-text-field
                            v-model="editedClient.suburb"
                            required
                            class="mb-3"
                            outlined
                            dense
                            hide-details
                            label="City / Suburb*"
                          />
                        </v-col>
                        <v-col class="pl-1">
                          <v-text-field
                            v-model="editedClient.post_code"
                            required
                            class="mb-3"
                            outlined
                            dense
                            hide-details
                            label="Post Code*"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pr-1 pt-0 pb-0" cols="7">
                          <v-autocomplete
                            outlined
                            dense
                            label="Country*"
                            v-model="editedClient.country"
                            :items="country"
                            :item-text="country.text"
                            :item-value="country.value"
                          />
                        </v-col>
                        <v-col class="pl-1 pt-0 pb-0">
                          <v-text-field
                            v-model="editedClient.state"
                            required
                            class="mb-3"
                            outlined
                            dense
                            hide-details
                            label="State"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions class="off">
                  <v-spacer></v-spacer>
                  <v-btn class="btn-error" @click="close"> Cancel </v-btn>
                  <v-btn class="btn-primary" @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog
              v-model="dialogDelete"
              max-width="500px"
              content-class="rounded-20"
            >
              <v-card>
                <v-card-title class="text-h5 off"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions class="off">
                  <v-spacer></v-spacer>
                  <v-btn class="btn-error" @click="closeDelete">Cancel</v-btn>
                  <v-btn class="btn-primary" @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-btn @click="dialog = true" class="btn-primary rounded-10" text>
              Add Client</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "ClientPage",

  data() {
    return {
      loading: false,
      dialog: false,
      editedIndex: -1,
      dialogDelete: false,
      countryList: [],
      editedClient: this.emptyClientForm(),
      headers: [
        {
          text: "Clinic",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Contact Name",
          align: "start",
          sortable: false,
          value: "contact"
        },
        {
          text: "Email",
          sortable: false,
          value: "email_address"
        },
        {
          text: "Phone Number",
          sortable: false,
          value: "phone_number"
        },
        {
          text: "Actions",
          sortable: false,
          value: "action"
        }
      ],
      users: []
    }
  },

  computed: {
    ...mapGetters(["currentTenant", "signUpSession"]),

    formTitle() {
      return this.editedIndex === -1 ? "New Client" : "Edit Client"
    },

    country() {
      return this.countryList.map((item) => {
        return {
          text: item.name,
          value: item.code
        }
      })
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },

    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  methods: {
    emptyClientForm() {
      return {
        name: "",
        contact: "",
        email_address: "",
        phone_number: "",
        address_line_1: "",
        address_line_2: "",
        suburb: "",
        state: "",
        post_code: "",
        country: ""
      }
    },

    editUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedClient = Object.assign({}, item)
      this.dialog = true
    },

    deleteUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedClient = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      if (
        this.editedClient.role === "Owner" &&
        this.users.filter((item) => item.role === "Owner").length === 1
      ) {
        this.dialogDelete = false
        return
      }
      this.users.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedClient = Object.assign({}, this.emptyClientForm())
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedClient = Object.assign({}, this.emptyClientForm())
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedClient)
      } else {
        const form = {
          ...this.editedClient,
          id_strings: []
        }

        if (this.editedClient.email_address)
          form.id_strings.push(this.editedClient.email_address)
        if (this.editedClient.phone_number)
          form.id_strings.push(this.editedClient.phone_number)

        client.instance.clients.createClient(form)
        this.users.push(this.editedClient)
      }
      this.close()
    }
  },

  async beforeMount() {
    this.loading = true
    const clients = await client.instance.clients.fetchClients()
    clients.forEach((client) => {
      const phone_number = client.id_strings.find((item) =>
        /\+[0-9]+/.test(item)
      )
      const email_address = client.id_strings.find((item) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(item)
      )

      this.users.push({
        ...client,
        email_address,
        phone_number
      })
    })
    this.countryList = await client.gateway.tenants.fetchCountries()

    this.loading = false
  }
}
</script>
