<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.71 6.97C6.04994 6.51675 6.58344 6.25 7.15 6.25H17.85C18.4166 6.25 18.9501 6.51675 19.29 6.97L21.515 9.93667C21.7487 10.2482 21.875 10.6272 21.875 11.0167V17.9917C21.875 18.9858 21.0691 19.7917 20.075 19.7917H4.925C3.93089 19.7917 3.125 18.9858 3.125 17.9917V11.0167C3.125 10.6272 3.25132 10.2482 3.485 9.93667L5.71 6.97Z"
      :stroke="color"
      stroke-width="2"
    />
    <path
      d="M21.875 11.4585H17.2847C16.9059 11.4585 16.5597 11.6725 16.3903 12.0113L15.9014 12.989C15.732 13.3278 15.3857 13.5418 15.007 13.5418H9.99303C9.61426 13.5418 9.268 13.3278 9.09861 12.989L8.60973 12.0113C8.44033 11.6725 8.09407 11.4585 7.7153 11.4585H3.125"
      :stroke="color"
      stroke-width="2"
    />
  </svg>
</template>
<script>
export default {
  name: "InboxIcon",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
