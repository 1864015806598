<template>
  <v-container fluid fill-height>
    <v-card flat height="100%" width="100%" color="transparent">
      <v-card-title>
        <v-icon class="mr-2">mdi-phone</v-icon>
        Contact Us
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-icon>mdi-phone</v-icon>
            Phone: +61 (3) 9000 0000
          </v-col>
          <v-col cols="3">
            <v-icon>mdi-email</v-icon>
            Email: test@osteonmedical.com
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "ContactPage"
}
</script>
