export default [
  {
    uid: "e3446e7c-7330-4742-860a-aab353e58202",
    id_string: "user1@email.com",
    is_staff: true,
    is_superuser: true,
    is_active: true,
    created_at: ""
  },
  {
    uid: "425fdc8f-d080-41ca-97b7-4ee47f88193d",
    id_string: "user2@email.com",
    is_staff: true,
    is_superuser: false,
    is_active: true,
    created_at: ""
  },
  {
    uid: "4171b39f-0001-476d-9797-e5742d121e20",
    id_string: "user3@email.com",
    is_staff: true,
    is_superuser: false,
    is_active: true,
    created_at: ""
  },
  {
    uid: "236930b6-cd72-4b03-bc53-9329a39c978c",
    id_string: "user4@email.com",
    is_staff: true,
    is_superuser: false,
    is_active: true,
    created_at: ""
  },
  {
    uid: "2e0278c6-5edc-4fc9-87ba-f35fef00e945",
    id_string: "user5@email.com",
    is_staff: true,
    is_superuser: false,
    is_active: true,
    created_at: ""
  }
]
