<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font">
        <v-icon class="mr-2">mdi-cog</v-icon>
        SETTINGS
      </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mx-4 rounded-10">
      <v-card-text class="black--text">
        <v-row>
          <v-col cols="12">
            <v-row align="center" class="text-subtitle-2 px-5">
              <v-icon class="mr-2">mdi-account-group</v-icon>
              Manage Clients
              <v-spacer />
              <v-switch v-model="settings.manage_clients" inset />
            </v-row>
          </v-col>
          <v-col cols="12" class="px-5">
            <v-expansion-panels flat accordion tile>
              <v-expansion-panel flat>
                <v-expansion-panel-header class="text-subtitle-2 px-0">
                  <v-icon class="mr-2" style="flex-grow: 0">mdi-palette</v-icon>
                  Theme
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ThemeSelector v-model="settings" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card-actions class="mx-2">
      <v-spacer />
      <v-btn class="btn-primary" @click="save"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ThemeSelector from "./Settings/ThemeSelector"
import client from "@/lib/ApiClient"

export default {
  name: "SettingsCard",

  components: {
    ThemeSelector
  },

  data() {
    return {
      loading: false,
      settings: {}
    }
  },

  methods: {
    async save() {
      this.loading = true
      await client.instance.tenants.updateSettings(this.settings)
      await this.fetch()
      this.loading = false
    },

    async fetch() {
      this.loading = true
      this.settings = await client.instance.tenants.fetchSettings()
      if (!this.settings.colours)
        this.settings.colours = this.$vuetify.theme.defaults.dark
      this.loading = false
    }
  },

  beforeMount() {
    this.fetch()
  }
}
</script>
