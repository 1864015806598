<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row class="mb-4 ml-4 title-font">
      <span class="font-weight-bold">
        &nbsp;{{ kitLimit - kitQuotasRemaining }}&nbsp;
      </span>
      of
      <span class="font-weight-bold"> &nbsp;{{ kitLimit }}&nbsp; </span>
      Scan Gauge Kits Registered
    </v-row>
    <v-row v-if="loading">
      <v-col class="text-center">
        <v-progress-circular size="128" indeterminate />
      </v-col>
    </v-row>
    <v-row v-if="!loading && scanGaugeKits.length === 0">
      <v-col class="text-center">
        You currently have no scan gauge kits associated to your account.
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="2" v-for="(kit, index) in scanGaugeKits" :key="index">
        <v-card flat class="mx-4 pt-2 rounded-10 text-center">
          <v-img height="150" contain :src="require('@/assets/sgk-gen2.png')" />
          <v-card-title>
            <span class="mx-auto"> {{ prettyKitName(kit.kit_box_id) }} </span>
          </v-card-title>
          <v-card-text> {{ kit.kit_type }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2" v-if="kitQuotasRemaining" align="center">
        <v-btn
          width="250px"
          min-height="250px"
          height="100%"
          class="rounded-10 elevation-0"
          style="text-transform: none"
          @click="handleOrder"
        >
          <template v-slot:default>
            <div class="d-flex flex-column" style="opacity: 0.6">
              <v-icon size="120" class="mb-2">mdi-plus</v-icon>
              <span class="text-body-2"> Purchase another Scan Gauge Kit </span>
            </div>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <PurchaseDialog v-model="dialog" @refresh="fetchGaugeKits" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import PurchaseDialog from "@/components/ScanGaugeKitsPage/PurchaseDialog.vue"
import client from "@/lib/ApiClient"

export default {
  name: "ScanGaugeKitsPage",
  components: { PurchaseDialog },

  data() {
    return {
      loading: false,
      dialog: false,
      scanGaugeKits: [],

      statusTranslations: [
        {
          status: "work_in_progress",
          description: "Manufacturing"
        },
        {
          status: "own_ready_for_dispatch",
          description: "Ready for Dispatch"
        },
        {
          status: "owned",
          description: "Dispatched"
        },
        {
          status: "faulty",
          description: "Faulty"
        }
      ]
    }
  },

  computed: {
    ...mapGetters(["currentQuotas"]),
    kitQuotasRemaining() {
      const remaining_kit_quotas = this.currentQuotas.find(
        (q) => q.quota_code === "registered_kits"
      )?.current
      return remaining_kit_quotas
    },
    kitLimit() {
      return this.currentQuotas.find((q) => q.quota_code === "registered_kits")
        ?.incoming
    }
  },

  methods: {
    async fetchGaugeKits() {
      this.loading = true
      this.scanGaugeKits = await client.gateway.gaugeKits.fetchScanGaugeKits()
      this.loading = false
    },

    prettyKitName(kit_box_id) {
      const parts = kit_box_id.split("_")
      return `${parts[parts.length - 1]} (${parts[parts.length - 2]})`
    },

    mapKitStatus(kitStatus) {
      const status = this.statusTranslations.find((x) => x.status === kitStatus)
      if (!status) {
        return "Unknown"
      }
      return status.description
    },

    handleOrder() {
      this.dialog = true
    }
  },

  mounted() {
    this.fetchGaugeKits()
  }
}
</script>
