var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-20 ma-4 d-flex flex-column",attrs:{"flat":"","height":"70vh"}},[_c('v-row',[_c('v-col',{staticClass:"my-10",attrs:{"cols":"3","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:['rounded-10', { 'btn-primary': hover }],staticStyle:{"border":"3px solid #147bd1"},attrs:{"flat":"","height":"175","width":"175"},on:{"click":function($event){return _vm.$emit('chooseOrder', 'design_only')}}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1",style:({
                color: hover ? 'white' : '#147BD1'
              })},[_c('strong',[_vm._v(" TOOTH DESIGN ONLY ")])]),_c('v-container',{attrs:{"fluid":""}},[(!hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis.png'),"position":"center center"}}):_vm._e(),(hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis-white.png'),"position":"center center"}}):_vm._e()],1)],1),(hover)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-left"},[_c('ul',[_c('li',[_vm._v("Full-arch bridge design.")]),_c('li',[_vm._v("Direct to MUA or Ti Base.")]),_c('li',[_vm._v("Milled or printed by you.")])])])],1):_vm._e()],1)]}}])})],1),_c('v-col',{staticClass:"my-10",attrs:{"cols":"3","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{directives:[{name:"ripple",rawName:"v-ripple"}],class:['rounded-10', { 'btn-primary': hover }],staticStyle:{"border":"3px solid #147bd1"},attrs:{"height":"175","width":"175"},on:{"click":function($event){return _vm.$emit('chooseOrder', 'milled_bar')}}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1 pb-0",style:({
                color: hover ? 'white' : '#147BD1'
              })},[_c('strong',[_vm._v(" MILLED BAR ")])]),_c('v-card-text',{staticClass:"pa-0 text-center font-weight-regular text-caption",style:({
                color: hover ? 'white' : '#147BD1'
              })},[_vm._v(" Your Tooth Design ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/milled-bar-only.png'),"position":"center center"}})],1)],1),(hover)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-left"},[_c('ul',[_c('li',[_vm._v(" Design and milling of a Nexus bar to your provided tooth-design. ")]),_c('li',[_vm._v(" Start with abutment exports to begin design on lab-side. ")]),_c('li',[_vm._v("Requires final tooth design to begin bar design.")]),_c('li',[_vm._v("Receive a milled bar and overlay file for milling.")])])])],1):_vm._e()],1)]}}])})],1),_c('v-col',{staticClass:"my-10",attrs:{"cols":"3","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:['rounded-10', { 'btn-primary': hover }],staticStyle:{"border":"3px solid #147bd1"},attrs:{"height":"175","width":"175"}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1 pb-0",style:({
                color: hover ? 'white' : '#147BD1'
              })},[_c('strong',[_vm._v(" TOOTH DESIGN & MILLED BAR ")])]),_c('v-card-text',{staticClass:"pa-0 text-center font-weight-regular text-caption",style:({
                color: hover ? 'white' : '#147BD1'
              })},[_vm._v(" Using Osteon Tooth Design ")]),_c('v-container',{attrs:{"fluid":""}},[(hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis-with-bar-white.png'),"position":"center center"}}):_vm._e(),(!hover)?_c('v-img',{attrs:{"contain":"","src":require('@/assets/design-only-prosthesis-with-bar.png'),"position":"center center"}}):_vm._e()],1)],1),(hover)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-left"},[_c('ul',[_c('li',[_vm._v(" Design and milling of a Nexus bar with Osteon tooth design. ")]),_c('li',[_vm._v(" Receive tooth design to either approve, or 3D print and use for Try-In. ")]),_c('li',[_vm._v("One design is approved, bar milled.")]),_c('li',[_vm._v(" Receive a milled bar and overlay file for lab milling/printing. ")])])])],1):_vm._e()],1)]}}])})],1),_c('v-col',{staticClass:"my-10",attrs:{"cols":"3","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',[_c('v-card',{class:['rounded-10', { 'btn-primary': hover }],staticStyle:{"border":"3px solid #147bd1"},attrs:{"height":"175","width":"175"}},[_c('v-card-text',{staticClass:"px-1 text-center text-subtitle-1",style:({
                color: hover ? 'white' : '#147BD1'
              })},[_c('strong',[_vm._v(" FINISHED CASE ")])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-img',{attrs:{"contain":"","src":require('@/assets/final-prosthesis-bar-retained.png'),"position":"center center"}})],1)],1),(hover)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"text-left"},[_c('ul',[_c('li',[_vm._v("Completely finished Nexus Hybrid prosthesis.")]),_c('li',[_vm._v("Try-In included in process.")]),_c('li',[_vm._v("Ready for patient insert.")])])])],1):_vm._e()],1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-10",attrs:{"elevation":"0"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }