<template>
  <v-list dense class="transparent pa-0">
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{ name: 'inboxPageWithFilter', params: { filter: 'inbox' } }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$inboxreceived</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> INBOX </span>
      </v-list-item-content>
      <v-list-item-action>
        <v-avatar size="20" color="primary">
          <span class="white--text" style="font-size: 8pt">
            {{ countInStatus("inbox") }}
          </span>
        </v-avatar>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{ name: 'inboxPageWithFilter', params: { filter: 'exported' } }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$implantexport</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> IMPLANTS EXPORTED </span>
      </v-list-item-content>
      <v-list-item-action>
        <v-avatar size="20" color="secondary">
          <span class="white--text" style="font-size: 8pt">
            {{ countInStatus("exported") }}
          </span>
        </v-avatar>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{
        name: 'inboxPageWithFilter',
        params: { filter: 'waiting' }
      }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$alarm</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> AWAITING RESPONSE </span>
      </v-list-item-content>
      <v-list-item-action>
        <v-avatar size="20" color="warning">
          <span class="white--text" style="font-size: 8pt">
            {{ countInStatus("waiting") }}
          </span>
        </v-avatar>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{ name: 'inboxPageWithFilter', params: { filter: 'all' } }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$inbox</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> ALL CASES </span>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{ name: 'inboxPageWithFilter', params: { filter: 'archived' } }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$inboxarchive</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> ARCHIVED CASES </span>
      </v-list-item-content>
    </v-list-item>
    <v-card-text class="pb-0 text--secondary title-font font-weight-light">
      SUBMITTED ORDERS
    </v-card-text>
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{ name: 'inboxPageWithFilter', params: { filter: 'design' } }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$toothdesigns</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> DESIGN ONLY </span>
      </v-list-item-content>
      <v-list-item-action>
        <v-avatar size="20" color="primary">
          <span class="white--text" style="font-size: 8pt">
            {{ countInStatus("design") }}
          </span>
        </v-avatar>
      </v-list-item-action>
    </v-list-item>
    <v-list-item
      class="mb-2"
      style="background-color: #c7c9c720"
      :to="{ name: 'inboxPageWithFilter', params: { filter: 'bar' } }"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>$milledbar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <span class="title-font font-weight-medium"> MILLED BAR </span>
      </v-list-item-content>
      <v-list-item-action>
        <v-avatar size="20" color="primary">
          <span class="white--text" style="font-size: 8pt">
            {{ countInStatus("bar") }}
          </span>
        </v-avatar>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "InboxActions",

  props: ["inboxItems"],

  methods: {
    countInStatus(status) {
      return this.inboxItems.filter((item) => item.statuses.includes(status))
        .length
    }
  }
}
</script>
