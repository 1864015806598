<template>
  <v-card flat class="transparent">
    <v-overlay v-if="order.associated" absolute :opacity="0.8">
      <v-card flat class="rounded-20" color="transparent">
        <v-card-title>
          <div class="mx-auto">
            <div class="text-center">
              <v-icon size="64" color="success">mdi-check-circle</v-icon>
            </div>
            <div>Scan Gauge Association Completed</div>
          </div>
        </v-card-title>
        <v-card-text>
          If you want to redo the scan gauge library assoication, click on the
          redo association button below
        </v-card-text>
        <v-card-actions>
          <v-btn small text class="btn-primary mx-auto" @click="$emit('redo')">
            <v-icon class="mr-2">mdi-restore</v-icon>
            Redo Association
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font">
        SCAN GAUGE SCAN LIBRARY ASSOCIATION
      </v-card-title>
      <v-card-subtitle>
        Carefully select each Scan Gauge used in the kit with the corresponding
        gauge in the scan. Use the round-dimples to check that they match.
      </v-card-subtitle>
    </v-card>

    <v-row>
      <v-col cols="5">
        <GaugeSelection
          :gauges="gauges"
          :libraryAssociation="libraryAssociation"
          v-model="selectedKitGauge"
          @delete="deleteAssociatedGauge"
        />
      </v-col>
      <v-col cols="2">
        <v-card
          flat
          class="pb-2 d-flex flex-column align-center rounded-20"
          height="100%"
        >
          <v-card-subtitle class="title-font black--text text-center">
            SELECTED GAUGE
          </v-card-subtitle>
          <v-img
            v-if="selectedKitGauge"
            :src="selectedKitGauge.src"
            max-height="450"
            width="100"
            contain
          ></v-img>
          <v-spacer />
          <v-card-text class="py-0">
            <v-list dense>
              <v-list-item
                :class="[
                  {
                    'btn-primary':
                      selectedKitGauge && selectedScanGaugeIndex !== -1
                  }
                ]"
                @click="onContinue"
                :disabled="!selectedKitGauge || selectedScanGaugeIndex === -1"
                style="min-height: 28px"
              >
                <v-list-item-content
                  class="text-center py-0 title-font"
                  :class="{
                    'white--text':
                      selectedKitGauge && selectedScanGaugeIndex !== -1
                  }"
                >
                  COMPARE SIDE-BY-SIDE
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card flat class="mr-4 rounded-20 d-flex flex-column" height="100%">
          <v-card-title class="title-font">
            SCAN GAUGE SCAN ALIGNMENT
          </v-card-title>
          <v-spacer />
          <div id="associationCanvas" style="width: 665px; height: 425px"></div>
          <v-card-text class="py-1">
            <v-row no-gutters>
              <v-col
                v-for="(gaugeId, index) in libraryAssociation"
                :key="index"
                align="center"
                class="my-1"
              >
                <v-btn
                  small
                  elevation="0"
                  :class="[
                    { 'btn-primary': index === selectedScanGaugeIndex },
                    {
                      'btn-success ':
                        libraryAssociation[index] !== -1 &&
                        index !== selectedScanGaugeIndex
                    },
                    'title-font',
                    'rounded-10'
                  ]"
                  @click="scanGaugeSelect(index)"
                >
                  {{ `Scan Gauge - ${index + 1}` }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="py-1">
            <v-row no-gutters>
              <v-col align="center">
                <v-btn
                  elevation="0"
                  :class="[
                    { 'rounded-10': true },
                    { 'mb-2': true },
                    { 'btn-success': readyToComplete }
                  ]"
                  @click="$emit('associated')"
                  :disabled="!readyToComplete"
                >
                  <v-icon>mdi-check</v-icon>
                  Confirm Association
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" width="70vw" content-class="rounded-20">
      <GaugeMatch
        :selectedKitGauge="selectedKitGauge"
        :selectedScanGaugeIndex="selectedScanGaugeIndex"
        @match="matchConfirm"
        @close="closeDialog"
      />
    </v-dialog>
  </v-card>
</template>

<style>
.selected-gauge {
  border: 2px solid #ffb81c;
  border-radius: 15px;
  background-color: #ffb81c40;
}
.hover-gauge:hover {
  cursor: pointer;
  border-radius: 15px;
  background-color: #ffb81c40;
}
.associated-gauge {
  border: 2px solid #53be40;
  border-radius: 15px;
  background-color: #52be2057;
}
</style>

<script>
import * as NCLabDemo from "@/lib/nclab_demo_3d"
import GaugeSelection from "./LibraryAssociation/GaugeSelection"
import GaugeMatch from "./LibraryAssociation/GaugeMatch"

export default {
  name: "LibraryAssociation",

  props: ["order"],

  components: {
    GaugeSelection,
    GaugeMatch
  },

  data() {
    return {
      selectedKitGauge: null,
      selectedScanGaugeIndex: -1,
      dialog: false,
      // Once hooked up with nxc, this needs align with the scan gauge scan.
      libraryAssociation: [-1, -1, -1, -1],
      gauges: [
        [
          {
            kitGaugeId: 1,
            src: require("@/assets/gauges/0000_3-mm-short-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 2,
            src: require("@/assets/gauges/0001_3-mm-short-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 3,
            src: require("@/assets/gauges/0002_3-mm-short-3.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 4,
            src: require("@/assets/gauges/0003_3-mm-medium-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 5,
            src: require("@/assets/gauges/0004_3-mm-medium-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 6,
            src: require("@/assets/gauges/0005_3-mm-medium-3.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 7,
            src: require("@/assets/gauges/0006_3-mm-long-1.png"),
            hemisphereMarkings: 0
          },
          {
            kitGaugeId: 8,
            src: require("@/assets/gauges/0007_3-mm-long-2.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 9,
            src: require("@/assets/gauges/0008_3-mm-long-3.png"),
            hemisphereMarkings: 2
          }
        ],
        [
          {
            kitGaugeId: 10,
            src: require("@/assets/gauges/0009_5-mm-short-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 11,
            src: require("@/assets/gauges/0010_5-mm-short-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 12,
            src: require("@/assets/gauges/0011_5-mm-short-3.png"),
            hemisphereMarkings: 3
          },
          {
            kitGaugeId: 13,
            src: require("@/assets/gauges/0012_5-mm-medium-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 14,
            src: require("@/assets/gauges/0013_5-mm-medium-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 15,
            src: require("@/assets/gauges/0014_5-mm-medium-3.png"),
            hemisphereMarkings: 3
          },
          {
            kitGaugeId: 16,
            src: require("@/assets/gauges/0015_5-mm-long-1.png"),
            hemisphereMarkings: 1
          },
          {
            kitGaugeId: 17,
            src: require("@/assets/gauges/0016_5-mm-long-2.png"),
            hemisphereMarkings: 2
          },
          {
            kitGaugeId: 18,
            src: require("@/assets/gauges/0017_5-mm-long-3.png"),
            hemisphereMarkings: 3
          }
        ]
      ],
      gaugeMap: [2, 1, 0, 3]
    }
  },

  methods: {
    onContinue() {
      this.dialog = true
    },

    matchConfirm() {
      this.$set(
        this.libraryAssociation,
        this.selectedScanGaugeIndex,
        this.selectedKitGauge.kitGaugeId
      )
      this.dialog = false
      NCLabDemo.setGaugeColor(
        "associationCanvas",
        this.gaugeMap[this.selectedScanGaugeIndex],
        NCLabDemo.colors.success
      )
      this.selectedKitGauge = null
      this.selectedScanGaugeIndex = -1
    },

    closeDialog() {
      this.selectedKitGauge = null
      this.dialog = false
    },

    resetGaugeColorState() {
      this.libraryAssociation.forEach((value, index) => {
        if (value === -1) {
          NCLabDemo.setGaugeColor(
            "associationCanvas",
            this.gaugeMap[index],
            NCLabDemo.colors.accent
          )
        } else {
          NCLabDemo.setGaugeColor(
            "associationCanvas",
            this.gaugeMap[index],
            NCLabDemo.colors.success
          )
        }
      })
    },

    scanGaugeSelect(index) {
      this.resetGaugeColorState()
      if (index === this.selectedScanGaugeIndex) {
        this.selectedScanGaugeIndex = -1
      } else {
        NCLabDemo.setGaugeColor(
          "associationCanvas",
          this.gaugeMap[index],
          NCLabDemo.colors.primary
        )
        this.selectedScanGaugeIndex = index
      }
    },

    deleteAssociatedGauge(gaugeId) {
      const idx = this.libraryAssociation.indexOf(gaugeId)
      this.$set(this.libraryAssociation, idx, -1)
    }
  },

  computed: {
    readyToComplete() {
      return this.libraryAssociation.filter((x) => x === -1).length === 0
    }
  },

  mounted() {
    NCLabDemo.register("associationCanvas")
    NCLabDemo.setGaugeColor("associationCanvas", 0, NCLabDemo.colors.accent)
    NCLabDemo.setGaugeColor("associationCanvas", 1, NCLabDemo.colors.accent)
    NCLabDemo.setGaugeColor("associationCanvas", 2, NCLabDemo.colors.accent)
    NCLabDemo.setGaugeColor("associationCanvas", 3, NCLabDemo.colors.accent)
  }
}
</script>
