<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.53054 8.15745C9.49582 8.04175 9.47847 7.98389 9.46647 7.93885C8.96899 6.07164 10.3407 4.22799 12.2721 4.16795C12.3187 4.1665 12.3791 4.1665 12.4999 4.1665V4.1665C12.6207 4.1665 12.6811 4.1665 12.7277 4.16795C14.6591 4.22799 16.0308 6.07164 15.5334 7.93885C15.5214 7.98389 15.504 8.04174 15.4693 8.15745L13.8409 13.5854C13.7592 13.8576 13.7184 13.9937 13.6515 14.1036C13.5167 14.3248 13.3013 14.485 13.0507 14.5506C12.9262 14.5832 12.7841 14.5832 12.4999 14.5832V14.5832C12.2157 14.5832 12.0736 14.5832 11.9492 14.5506C11.6986 14.485 11.4832 14.3248 11.3484 14.1036C11.2814 13.9937 11.2406 13.8576 11.1589 13.5854L9.53054 8.15745Z"
      :stroke="color"
      stroke-width="1.5"
    />
    <circle
      cx="12.5001"
      cy="19.7918"
      r="2.08333"
      :stroke="color"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "AlarmIcon",
  props: {
    color: {
      type: String,
      default: "#000"
    }
  }
}
</script>
