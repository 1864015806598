<template>
  <div>
    <v-card v-if="workflowStep < 3" class="rounded-20 ma-4" flat>
      <v-row class="ma-0">
        <v-col cols="8" class="pa-0">
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
            <v-progress-circular
              indeterminate
              v-if="loading"
              size="32"
              class="ml-5"
            />
          </v-card-title>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-card-text class="fill-width pa-0 d-flex justify-center">
            <v-img
              :src="require('@/assets/milled-bar-only.png')"
              class="ma-4"
              contain
              max-width="200"
            ></v-img>
          </v-card-text>
        </v-col>
      </v-row>
      <div v-if="workflowStep === 1">
        <v-card-text class="black--text py-0">
          <v-stepper vertical class="elevation-0 py-0" v-model="currentStep">
            <v-stepper-step
              step="1"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Export Abutment Position
            </v-stepper-step>
            <v-stepper-content step="1">
              <p>
                In order to order a milled bar you will need to supply us with a
                tooth design.
              </p>
              <p>
                You have yet to export abutment positions for this case. Please
                continue to export abutment positions.
              </p>
              <v-btn class="btn-primary" @click="$emit('toExport')">
                Export Abutment Position
              </v-btn>
            </v-stepper-content>
            <v-stepper-step
              step="2"
              :complete="exportComplete"
              :color="exportComplete ? 'success' : 'primary'"
            >
              Complete Tooth Design
            </v-stepper-step>
            <v-stepper-content step="2" />
            <v-stepper-step step="3"> Order Milled Bar </v-stepper-step>
            <v-stepper-content step="3">
              <v-btn
                class="btn-success white--text my-4 mx-4"
                @click="handleContinue"
              >
                Order Bar Now
                <v-icon class="ml-3" size="40"> $milledbarDark </v-icon>
              </v-btn>
            </v-stepper-content>
            <v-stepper-step step="4"> Approve Bar Design </v-stepper-step>
            <v-stepper-content step="4" />
            <v-stepper-step step="5"> Bar Production </v-stepper-step>
            <v-stepper-content step="5" />
            <v-stepper-step step="6"> Bar Delivery </v-stepper-step>
            <v-stepper-content step="6" />
          </v-stepper>
          <v-btn
            class="elevation-0 rounded-10"
            style="position: absolute; right: 30px; bottom: 20px"
            @click="$emit('back')"
          >
            Back
          </v-btn>
        </v-card-text>

        <!-- <v-btn class="rounded-10" elevation="0" @click="$emit('back')">
          Go Back
        </v-btn> -->
      </div>
    </v-card>
    <v-row v-if="workflowStep == 2">
      <v-col cols="6">
        <v-card class="rounded-20 fill-height ml-4 my-n2" flat>
          <MultiUnitConfirmation
            v-model="options.muaConfirmation"
            :interactive="true"
          />
        </v-card>
      </v-col>

      <v-col cols="6">
        <UploadProjectFiles
          v-if="workflowStep === 2"
          @step="handleStepChange"
          v-model="files"
          :options="options"
        />
      </v-col>
    </v-row>
    <v-row v-if="workflowStep == 3">
      <v-col cols="6">
        <v-card class="rounded-20 fill-height ml-4" flat>
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$milledbar</v-icon> MILLED BAR ORDER
          </v-card-title>
          <v-card-text class="d-flex justify-center fill-width">
            <v-img
              :src="require('@/assets/milled-bar-only.png')"
              class="ma-4"
              contain
              max-width="200"
              position="center right"
            />
          </v-card-text>
          <v-card-text class="black--text pt-0">
            Case Status:
            <strong v-if="workflowStep === 3" class="warning--text py-1">
              Under Review
            </strong>
          </v-card-text>
          <v-card-text class="black--text pt-0">
            Osteon Case Number: <strong> #18902 </strong>
          </v-card-text>
          <v-card-text v-if="workflowStep === 3" class="black--text py-1">
            Our design team will notify you if your uploaded files are missing
            information or incorrect.
          </v-card-text>
          <v-card-text
            v-if="workflowStep === 3 && Object.values(files).some((f) => !!f)"
          >
            <v-card-subtitle class="px-0">
              <v-icon>$fileupload</v-icon>
              Uploaded Files:
            </v-card-subtitle>
            <v-card outlined class="px-0 py-2 rounded-lg">
              <v-chip
                v-for="(file, index) in Object.values(files).filter((f) => !!f)"
                :key="index"
                class="py-0 mx-1"
              >
                {{ file?.filename || `${file}.ply` }}
              </v-chip>
            </v-card>
          </v-card-text>
          <v-card-text>
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    CONFIRMED MULTI UNIT ABUTMENTS:
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-for="(mua, index) in muaSet"
                    :key="index"
                    class="font-weight-bold black--text"
                  >
                    {{ mua }}:
                    <span
                      v-for="(item, index) in options.muaConfirmation.filter(
                        (item) => item.mua === mua
                      )"
                      :key="index"
                    >
                      <span v-if="index !== 0">, </span>
                      {{ item.tooth }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    CHOSEN SCAN BODY LIBRARY:
                  </v-list-item-title>
                  <v-list-item-subtitle class="font-weight-bold black--text">
                    Straumann SRA
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <CaseChat v-if="workflowStep == 3" :order="order" class="fill-height" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation"
import UploadProjectFiles from "./MilledBarOrder/UploadProjectFiles"
import CaseChat from "@/components/shared/CaseChat/CaseChat"

export default {
  name: "MilledBarOrder",

  components: {
    MultiUnitConfirmation,
    UploadProjectFiles,
    CaseChat
  },

  props: ["order", "value"],

  data() {
    return {
      workflowStep: 1,
      files: {
        toothDesign: null,
        tissueOfRestoringArch: null,
        antagonistDentition: null,
        abutmentAnalogPositions: null
      },
      loading: false,
      acquisitionStrategy: null
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"]),

    toothPickerResponse() {
      return this.order.responses.find((item) => item.widget === "tooth_picker")
        .value
    },

    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    muaSet() {
      return [...new Set(this.options.muaConfirmation.map((item) => item.mua))]
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "BAR")
        .method
    },

    currentStep() {
      return this.exportComplete ? 3 : 1
    },

    exportComplete() {
      return true
    }
  },

  watch: {
    files: {
      handler(obj) {
        this.options.uploadedFiles = Object.values(obj)
      },
      deep: true
    }
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder"]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    handleUploadedFiles(files) {
      this.uploadedFiles = files
    },

    async createForwardedOrder() {
      const form = {
        product: "BAR",
        step: 1
      }
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        form
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },
    async handleContinue() {
      this.loading = true
      await this.createForwardedOrder()
      this.loading = false
      return this.workflowStep++
    },
    async fetchOrderFiles() {
      // Fetch from forwarded order order_form IF continuing from partially completed order
      this.loading = true
      const orderFiles = await client.instance.orders.fetchOrderFiles(
        this.selectedOrder.uid
      )
      this.files.toothDesign = orderFiles.filter(
        (file) => file.file_category === "tooth_design"
      )
      this.files.tissueOfRestoringArch = orderFiles.filter(
        (file) => file.file_category === "tissue_of_restoring_arch"
      )
      this.files.antagonistDentition = orderFiles.filter(
        (file) => file.file_category === "antagonist_dentition"
      )
      this.files.abutmentAnalogPositions = orderFiles.filter(
        (file) => file.file_category === "abutment_analog_positions"
      )

      this.loading = false
    }
  },

  async mounted() {
    // this.fetchOrderFiles()
    this.$set(
      this.options,
      "muaConfirmation",
      this.order.responses.find((item) => item.widget === "tooth_picker").value
    )
  }
}
</script>
