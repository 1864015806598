<template>
  <v-container fluid class="pa-0 card-bg">
    <v-container fluid v-if="loading" class="pa-4 card-bg">
      <v-skeleton-loader type="heading" class="my-5" />
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-skeleton-loader type="card" />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid v-if="!loading" class="pa-0 card-bg">
      <v-row class="mt-1">
        <v-col cols="12">
          <v-tabs v-model="tab">
            <v-tab>Overview</v-tab>
            <v-tab>
              <v-icon
                v-if="!order.associated"
                color="warning"
                small
                class="mr-2"
                >mdi-alert-circle</v-icon
              >
              <v-icon
                v-if="order.associated"
                color="success"
                small
                class="mr-2"
              >
                mdi-check-circle
              </v-icon>
              Library Association
            </v-tab>
            <v-tab>
              <v-icon v-if="!order.associated" color="error" small class="mr-2">
                mdi-alert
              </v-icon>
              Export &amp; Order
            </v-tab>
            <v-tab>Chat &amp; Notes</v-tab>
            <v-tab>Scans &amp; Files</v-tab>
            <v-tab>Order Form</v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-card
        v-if="tab === 0"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <v-row>
          <v-col cols="4">
            <PreviewComponent :order="order" />
          </v-col>
          <v-col cols="4">
            <AlignmentScan
              @toggleViewLibAssociation="tab = 1"
              @redo="redoAnalysis"
            />
          </v-col>
          <v-col cols="4">
            <CaseChat :order="order" @fullmode="tab = 3" />
          </v-col>
        </v-row>
      </v-card>

      <v-card
        v-if="tab === 1"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <LibraryAssociation
          :order="order"
          @associated="markAsAssociated"
          @redo="redoAnalysis"
        />
      </v-card>

      <v-card
        v-if="tab === 2"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ExportOrOrder v-if="order.associated" :order="order" />
        <div v-if="!order.associated">
          <v-card flat class="ma-4 rounded-20">
            <v-card-title class="title-font"> EXPORT &amp; ORDER </v-card-title>
          </v-card>
          <v-card flat class="rounded-20 ma-4" height="70vh">
            <v-card-text>
              <div class="text-center black--text">
                <div class="mt-10 mb-5">
                  <v-icon size="32" color="error">mdi-alert</v-icon>
                </div>
                <div>
                  You must perform a library association before exporting
                  abutment positions or ordering products from Osteon.
                </div>
                <div class="mt-10">
                  <v-btn text class="btn-primary" @click="tab = 1">
                    <v-icon class="mr-2">$gauge</v-icon>
                    START LIBRARY ASSOCIATION
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card>

      <v-card
        v-if="tab === 3"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <CaseChat :order="order" class="ma-4" :fullmode="true" />
      </v-card>

      <v-card
        v-if="tab === 4"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        <ScansAndFiles :order="value" />
      </v-card>

      <v-card
        v-if="tab === 5"
        flat
        height="100%"
        width="100%"
        class="transparent"
      >
        Order forms
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import client from "@/lib/ApiClient"

import PreviewComponent from "./OrderView/PreviewComponent"
import AlignmentScan from "./OrderView/AlignmentScan"
import ExportOrOrder from "./OrderView/ExportOrOrder"
import CaseChat from "@/components/shared/CaseChat/CaseChat"
import LibraryAssociation from "./OrderView/LibraryAssociation"
import ScansAndFiles from "./OrderView/ScansAndFiles"

export default {
  name: "OrderView",

  props: ["value"],

  watch: {
    value(order) {
      if (!order) return
      this.fetchOrder()
    }
  },

  components: {
    PreviewComponent,
    AlignmentScan,
    ExportOrOrder,
    CaseChat,
    LibraryAssociation,
    ScansAndFiles
  },

  data() {
    return {
      loading: true,
      order: null,
      tab: 0,
      exportOrOrder: ""
    }
  },

  methods: {
    continueCase(value) {
      this.exportOrOrder = value
    },

    markAsAssociated() {
      client.instance.orders.associateOrder(this.value.uid).then(() => {
        this.fetchOrder()
        this.tab = 2
      })
    },

    redoAnalysis() {
      client.instance.orders.redoAnalysis(this.value.uid).then(() => {
        this.fetchOrder()
        this.tab = 1
      })
    },

    async fetchOrder() {
      this.loading = true
      this.order = await client.instance.orders.getOrder(this.value.uid)
      this.loading = false
    }
  },

  mounted() {
    if (this.value) this.fetchOrder()
  }
}
</script>
