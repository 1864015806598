<template>
  <v-container fluid>
    <v-row fill-height>
      <v-col cols="8" md="10" sm="12" offset="2" offset-md="1" offset-sm="0">
        <v-row>
          <v-col class="pa-3">
            <div
              class="title-font px-0 pt-0 pb-8"
              style="
                font-size: 35px;
                line-height: 1.3;
                word-break: keep-all;
                max-width: 600px;
              "
            >
              YOUR GATEWAY TO DIGITAL FULL-ARCH RESTORATIONS
            </div>
            <v-stepper
              v-model="step"
              style="box-shadow: none"
              flat
              class="rounded-20"
            >
              <v-stepper-items>
                <v-card flat class="card-bg pa-4">
                  <v-card-title
                    class="title-font primary--text font-weight-bold px-1 py-0"
                    style="font-size: 22px"
                  >
                    {{ step === 4 ? "CONFIRM PURCHASE" : "SIGN UP" }}
                  </v-card-title>
                  <v-card-text
                    class="primary--text px-1"
                    style="font-size: 18px"
                  >
                    {{
                      step === 3
                        ? "Choose your required starter options"
                        : "Create a Nexus Connect account to get started."
                    }}
                  </v-card-text>
                  <v-stepper-content step="1" class="pa-0">
                    <v-card flat class="rounded-10">
                      <v-card-title class="title-font">
                        <v-icon class="mr-2">mdi-information</v-icon>
                        Business Information
                      </v-card-title>
                      <BusinessInformation
                        v-if="!loading"
                        v-model="tenantInformation"
                      />
                      <v-card-text class="d-flex">
                        <v-spacer />
                        <v-btn
                          class="btn-primary rounded-10"
                          @click="
                            () => {
                              step = 2
                              if (!selectedDomain && tenantInformation.name)
                                selectedDomain = toSnakeCase(
                                  tenantInformation.name
                                )
                            }
                          "
                          text
                          tile
                        >
                          Continue
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="2" class="pa-0">
                    <v-card flat class="rounded-10">
                      <DataStorageSelector
                        v-if="!loading"
                        v-model="tenantInformation.instance_uid"
                      />
                      <DomainSelector
                        v-if="!loading"
                        v-model="selectedDomain"
                      />
                      <v-card-text class="d-flex">
                        <v-btn
                          class="btn-primary-light rounded-10"
                          @click="step = 1"
                          text
                        >
                          Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn-primary rounded-10"
                          @click="step = 3"
                          text
                        >
                          Continue
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="3" class="pa-0">
                    <v-card flat class="rounded-10">
                      <v-card-title class="pb-0">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Add optional products
                      </v-card-title>
                      <v-card-text>
                        <v-list dense>
                          <v-list-item-group
                            v-model="additionalProducts"
                            active-class="primary white--text rounded-10"
                            class="d-flex"
                            multiple
                            v-for="(product, index) in [
                              scanGaugeKit,
                              labValidationKit
                            ]"
                            :key="index"
                          >
                            <v-icon
                              class="mr-2"
                              v-if="additionalProducts.includes(product.code)"
                            >
                              mdi-checkbox-outline
                            </v-icon>
                            <v-icon
                              class="mr-2"
                              v-if="!additionalProducts.includes(product.code)"
                            >
                              mdi-checkbox-blank-outline
                            </v-icon>

                            <v-list-item :value="product.code" class="my-1">
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ product.name }} - ${{ product.price }}
                                  {{ product.currency }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card-text>
                      <v-card-text class="d-flex">
                        <v-btn
                          class="btn-primary-light rounded-10"
                          @click="step = 2"
                          text
                        >
                          Back
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="btn-primary rounded-10"
                          @click="createTenant"
                          text
                        >
                          Continue
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-stepper-content>
                  <v-stepper-content step="4" class="pa-0">
                    <v-card flat class="rounded-10">
                      <v-card-text>
                        <v-row v-if="!stripeReady" class="stripe-loader">
                          <v-col cols="12">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="6">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="6">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="12">
                            <v-skeleton-loader type="text" />
                          </v-col>
                          <v-col cols="12" class="text-center"
                            >Loading Stripe Payment...</v-col
                          >
                        </v-row>
                        <StripeElementPayment
                          v-if="stripeReady"
                          ref="paymentRef"
                          :pk="pk"
                          :elements-options="elementsOptions"
                          :confirm-params="confirmParams"
                        />
                      </v-card-text>
                    </v-card>

                    <v-card-text class="d-flex px-0 pb-0">
                      <v-btn
                        class="btn-primary-light rounded-10"
                        @click="abandonConfirmation"
                        :disabled="loading"
                        text
                      >
                        Cancel
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        v-if="stripeReady"
                        :loading="loading"
                        class="btn-primary rounded-10"
                        text
                        @click="pay"
                        >Pay Now &amp; Subscribe</v-btn
                      >
                    </v-card-text>
                  </v-stepper-content>
                </v-card>
              </v-stepper-items>
            </v-stepper>
          </v-col>
          <v-col class="pa-3">
            <v-card flat class="card-bg rounded-20 px-5 pb-5" v-if="step !== 4">
              <div>
                <v-card-title
                  class="title-font primary--text font-weight-bold px-1"
                  style="font-size: 22px"
                >
                  YOUR CHOSEN PLAN
                </v-card-title>
                <PricingCard
                  :pricing="planPricing"
                  :returnButton="true"
                  :step="step"
                />
              </div>
              <div
                v-if="
                  step === 3 && additionalProducts.includes('GAUGEKIT-GEN2')
                "
              >
                <v-card-title
                  class="title-font primary--text font-weight-bold px-1 mt-5"
                  style="font-size: 22px"
                >
                  SCAN GAUGE KIT
                </v-card-title>
                <ScanGaugeKit :scan-gauge-kit="scanGaugeKit" />
              </div>
              <div
                v-if="step === 3 && additionalProducts.includes('VALIDATION')"
              >
                <v-card-title
                  class="title-font primary--text font-weight-bold px-1 mt-5"
                  style="font-size: 22px"
                >
                  LAB VALIDATION KIT (optional)
                </v-card-title>
                <LabValidationKit :lab-validation-kit="labValidationKit" />
              </div>
            </v-card>
            <OrderSummary v-if="step === 4" :pricings="orderSummaryPricings" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessInformation from "./RegistrationPage/BusinessInformation.vue"
import DataStorageSelector from "./RegistrationPage/DataStorageSelector.vue"
import DomainSelector from "./RegistrationPage/DomainSelector.vue"
import OrderSummary from "./RegistrationPage/OrderSummary.vue"
import ScanGaugeKit from "./RegistrationPage/ScanGaugeKit.vue"
import LabValidationKit from "./RegistrationPage/LabValidationKit.vue"
import PricingCard from "./PricingPage/PricingCard.vue"

import client from "@/lib/ApiClient"

import { mapGetters, mapActions } from "vuex"
import { StripeElementPayment } from "@vue-stripe/vue-stripe"
import { deepValid, toSnakeCase } from "@/utils"

export default {
  name: "RegistrationPage",

  props: ["planCode"],

  components: {
    BusinessInformation,
    DataStorageSelector,
    DomainSelector,
    PricingCard,
    StripeElementPayment,
    OrderSummary,
    ScanGaugeKit,
    LabValidationKit
  },

  data() {
    return {
      loading: false,
      submitted: false,
      step: 1,
      selectedDataStorage: null,
      selectedDomain: null,
      selectedKit: null,
      tenantInformation: {
        instance_uid: null,
        sameShippingAddress: true,
        name: null,
        phone_number: null,
        email_address: null,
        billing_address_1: null,
        billing_address_2: null,
        billing_suburb: null,
        billing_state: null,
        billing_postal_code: null,
        billing_country: null
      },
      subscribeForm: {
        tenant_uid: null,
        plan_code: this.planCode,
        country_code: null,
        auto_renew: true,
        payment_type: "stripe",
        extras: []
      },
      pk: process.env.VUE_APP_STRIPE_PK,
      elementsOptions: {
        clientSecret: null,
        appearance: {
          variables: { borderRadius: "10px" }
        }
      },
      confirmParams: {
        return_url: process.env.VUE_APP_STRIPE_POSTPAY_REDIRECT
      },
      additionalProducts: ["GAUGEKIT-GEN2"],
      scanGaugeKit: {
        code: "GAUGEKIT-GEN2",
        name: "SCAN GAUGE KIT",
        description: "Generation 2.0",
        currency: "AUD",
        price: 3995,
        features: [
          "18 Gen 2.0 Scan Gauges",
          "3 Gen 2.0 Narrow Scan Bodies",
          "4 Gen 2.0 Scan Analogs",
          "8 Screws",
          "Driver & Handle",
          "Autoclavable Gen 2.0 Casette"
        ]
      },
      labValidationKit: {
        code: "VALIDATION",
        name: "LAB VALIDATION KIT",
        description: "Design & Overlay Checking Pack",
        currency: "AUD",
        price: 500,
        features: [
          "95mm x 20mm Milling Disk",
          "Placeholder feature",
          "Placeholder feature",
          "Placeholder feature"
        ]
      },
      toSnakeCase
    }
  },
  computed: {
    ...mapGetters(["currentTenant", "plans", "subscription"]),

    stripeReady() {
      return !!this.elementsOptions.clientSecret
    },

    planPricing() {
      return this.plans.find((plan) => plan.code === this.planCode)
    },

    orderSummaryPricings() {
      let pricingItems = []
      if (this.additionalProducts.includes("VALIDATION")) {
        pricingItems.push({
          name: this.labValidationKit.name,
          currency: this.labValidationKit.currency,
          price: this.labValidationKit.price
        })
      }
      if (this.additionalProducts.includes("GAUGEKIT-GEN2")) {
        pricingItems.push({
          name: this.scanGaugeKit.name,
          currency: this.scanGaugeKit.currency,
          price: this.scanGaugeKit.price
        })
      }
      pricingItems.push({
        name: this.planPricing.name,
        currency: this.planPricing.subscription_currency,
        price: this.planPricing.subscription_price,
        renewal_frequency: this.planPricing.renewal_frequency
      })

      return pricingItems
    }
  },

  methods: {
    ...mapActions(["setSubscription", "setCurrentTenant"]),

    unloadGuard() {
      if (this.elementsOptions.clientSecret && !this.submitted) {
        this.abandonConfirmation()
      }
    },

    async pay() {
      this.loading = true
      this.submitted = true
      this.$refs.paymentRef.submit()
    },

    async createTenant() {
      if (
        !deepValid(this.tenantInformation, [
          "billing_address_2",
          "shipping_address_2"
        ])
      )
        return console.error("Invalid Form")
      this.subscribe()
      this.step = 4
    },

    async subscribe() {
      this.loading = true
      try {
        if (this.tenantInformation.sameShippingAddress) {
          const addr = this.tenantInformation

          addr.shipping_address_1 = addr.billing_address_1
          addr.shipping_address_2 = addr.billing_address_2
          addr.shipping_suburb = addr.billing_suburb
          addr.shipping_state = addr.billing_state
          addr.shipping_postal_code = addr.billing_postal_code
          addr.shipping_country = addr.billing_country
        }

        const createTenantResponse = await client.gateway.tenants.addTenants(
          this.tenantInformation
        )
        this.subscribeForm.tenant_uid = createTenantResponse.uid
        this.subscribeForm.country_code = this.tenantInformation.billing_country

        // Temporary: May need to allow variable quantity.
        this.subscribeForm.extras = this.additionalProducts.map((code) => {
          return { product_code: code, quantity: 1 }
        })

        const result = await client.gateway.billing.subscribe(
          this.subscribeForm
        )
        this.elementsOptions.clientSecret =
          result.provider_response.client_secret

        this.setCurrentTenant(createTenantResponse)

        this.setSubscription(result)
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },

    abandonConfirmation() {
      client.gateway.billing.cancelSubscription()
      client.gateway.tenants.deleteTenant()
      this.subscribeForm.tenant_uid = null
      this.setSubscription(null)
      this.setCurrentTenant(null)
      this.elementsOptions.clientSecret = null
      this.step = 1
    }
  },

  created() {
    window.addEventListener("beforeunload", this.unloadGuard)
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.unloadGuard)
  }
}
</script>

<style>
.stripe-loader .v-skeleton-loader__text {
  height: 30px;
}
</style>
