<template>
  <div>
    <MessageList
      :messages="messages"
      :approvals="approvals"
      :loading-message="loadingMessage"
    />
    <MessageCompose @compose="sendMessage" :sending-message="sendingMessage" />
  </div>
</template>
<script>
import MessageList from "./MessageList.vue"
import MessageCompose from "./MessageCompose.vue"

export default {
  props: ["messages", "approvals", "loadingMessage", "sendingMessage"],

  components: { MessageList, MessageCompose },

  methods: {
    sendMessage(formData) {
      this.$emit("compose", formData)
    }
  }
}
</script>
