<template>
  <v-container fluid fill-height class="d-flex align-start pt-0">
    <v-row>
      <v-col class="py-0">
        <AuthorizedUsers class="mb-5" />
        <SubscriptionInfo class="mb-5" />
        <QuotaOverviewCard />
      </v-col>
      <v-col class="py-0">
        <Settings class="mb-5" />
        <PaymentMethods class="mb-5" />
        <InvoiceHistory />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuthorizedUsers from "./ProfilePage/AuthorizedUsers"
import SubscriptionInfo from "./ProfilePage/SubscriptionInfo"
import QuotaOverviewCard from "./ProfilePage/QuotaOverviewCard"
import PaymentMethods from "./ProfilePage/PaymentMethods"
import InvoiceHistory from "./ProfilePage/InvoiceHistory"
import Settings from "./ProfilePage/Settings.vue"

export default {
  name: "ProfilePage",
  components: {
    AuthorizedUsers,
    SubscriptionInfo,
    QuotaOverviewCard,
    PaymentMethods,
    InvoiceHistory,
    Settings
  }
}
</script>
