<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar dense flat class="transparent">
      <v-toolbar-title class="title-font"> ORDER FORM </v-toolbar-title>
      <v-spacer />
      <v-switch v-model="draggable" inset hide-details label="Drag to Order" />
    </v-toolbar>

    <QuestionList
      v-if="questions"
      v-model="questions"
      :loading="loading"
      :validate="validate"
      :draggable="draggable"
    />

    <v-card-actions class="mx-2">
      <v-btn
        :class="{ 'btn-primary': unsavedChanges }"
        @click="saveProduct"
        text
        :disabled="loading || !unsavedChanges"
      >
        <v-progress-circular
          v-if="saving"
          indeterminate
          size="20"
          class="mr-2"
        />
        <v-icon v-if="!saving" class="mr-2"> mdi-content-save </v-icon>
        Save Changes
      </v-btn>
      <v-btn
        class="rounded-10"
        :color="unsavedChanges ? 'error' : ''"
        @click="revert"
        text
        :disabled="loading || !unsavedChanges"
      >
        <v-icon class="mr-2"> mdi-refresh </v-icon>
        Revert Changes
      </v-btn>
      <v-spacer />
      <v-btn class="btn-primary" @click="addQuestion" :disabled="loading" text>
        Add Question
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import QuestionList from "./ProductForm/QuestionList.vue"
import { deepEqual, deepCopy, deepValid } from "@/utils"
import client from "@/lib/ApiClient"

export default {
  props: ["value", "originals", "initialising"],

  components: { QuestionList },

  data() {
    return {
      saving: false,
      deleting: false,
      previewDialog: false,
      validate: false,
      draggable: false
      // deepEqual
    }
  },

  computed: {
    questions: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    loading() {
      return this.saving || this.deleting || this.initialising
    },

    unsavedChanges() {
      return !deepEqual(this.questions, this.originals, ["uid"])
    }
  },

  methods: {
    addQuestion() {
      this.questions.push({
        name: "",
        description: "",
        widget: "",
        metadata: {}
      })
    },

    revert() {
      this.$emit("input", deepCopy(this.originals))
    },

    async saveProduct() {
      if (!deepValid(this.questions, ["description"]))
        return (this.validate = true)
      this.validate = false

      this.saving = true
      const response = await client.instance.orders.createForm(this.questions)

      this.$emit("input", response)
      this.$emit("save", response)
      this.saving = false
    }
  }
}
</script>
