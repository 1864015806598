<template>
  <v-card
    v-if="
      question.widget === 'drop_down_picker' ||
      question.widget === 'tooth_picker'
    "
    flat
  >
    <v-card-title class="px-0 pt-1 pb-5">
      <div class="d-flex flex-column">
        <small>Available Responses</small>
        <small v-if="question.widget === 'tooth_picker'" class="text-caption"
          >Add implant platforms that can be selected by a tooth picker.</small
        >
      </div>
      <v-spacer />
      <v-btn class="btn-primary" @click="addResponse(question)">
        <v-icon>mdi-plus</v-icon>
        Add Response
      </v-btn>
    </v-card-title>

    <v-container v-if="!responses.length" class="pb-5">
      No Available Responses
    </v-container>

    <v-card-text class="pa-0" v-if="responses.length">
      <v-card
        v-for="(response, index) in responses"
        :key="`response-${index}`"
        flat
      >
        <v-card-text class="d-flex align-start pa-0">
          <v-chip class="mr-5 mt-1">{{ index + 1 }}</v-chip>
          <v-text-field
            v-model="response.name"
            dense
            outlined
            label="Response"
            :value="response"
            :error-messages="
              !response.name && validate ? 'Cannot be Empty' : null
            "
          />
          <v-btn small icon @click="removeResponse(index)" class="mx-5 mt-1">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["value", "validate"],

  computed: {
    question() {
      return this.value
    },

    responses() {
      return this.question.metadata.responses
    }
  },

  methods: {
    addResponse(question) {
      question.metadata.responses = [
        ...question.metadata.responses,
        { name: "" }
      ]
    },

    removeResponse(index) {
      this.question.metadata.responses.splice(index, 1)
    }
  }
}
</script>
