<template>
  <v-card flat class="card-bg rounded-20 pb-4">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font"> QUOTA OVERVIEW </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mx-4 pb-4 rounded-10">
      <QuotaOverview />
    </v-card>
  </v-card>
</template>

<script>
import QuotaOverview from "@/components/shared/QuotaOverview"

export default {
  name: "QuotaOverviewCard",

  components: { QuotaOverview }
}
</script>
