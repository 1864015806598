import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

import ImplantExport from "@/components/shared/icons/ImplantExport"
import ToothDesigns from "@/components/shared/icons/ToothDesigns"
import MilledBar from "@/components/shared/icons/MilledBar"
import FinishedProsthetic from "@/components/shared/icons/FinishedProsthetic"
import InboxIcon from "@/components/shared/icons/InboxIcon"
import InboxReceived from "@/components/shared/icons/InboxReceived"
import InboxArchive from "@/components/shared/icons/InboxArchive"
import AlarmIcon from "@/components/shared/icons/AlarmIcon"
import GaugeIcon from "@/components/shared/icons/GaugeIcon"
import ScanGaugeKit from "@/components/shared/icons/ScanGaugeKit"
import VisaIcon from "@/components/shared/icons/VisaIcon"
import MastercardIcon from "@/components/shared/icons/MastercardIcon"
import AmexIcon from "@/components/shared/icons/AmexIcon"
import OrderIcon from "@/components/shared/icons/OrderIcon"
import UploadIcon from "@/components/shared/icons/UploadIcon"
import OrderForm from "@/components/shared/icons/OrderForm"
import FileUpload from "@/components/shared/icons/FileUpload"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: "#147BD1",
        secondary: "#333F48",
        accent: "#C7C9C7",
        off: "#f5f6f6"
      },
      light: {
        primary: "#147BD1",
        secondary: "#333F48",
        accent: "#EEEFEE",
        off: "#f5f6f6",
        card: "#E3E3E3"
      }
    },
    icons: {
      iconfont: "mdi"
    }
  },
  icons: {
    values: {
      implantexport: {
        component: ImplantExport
      },
      toothdesigns: {
        component: ToothDesigns
      },
      milledbar: {
        component: MilledBar
      },
      milledbarDark: {
        component: MilledBar,
        props: {
          color: "#FFF"
        }
      },
      finishedprosthetic: {
        component: FinishedProsthetic
      },
      inbox: {
        component: InboxIcon
      },
      inboxDark: {
        component: InboxIcon,
        props: {
          color: "#FFFFFF"
        }
      },
      inboxreceived: {
        component: InboxReceived
      },
      inboxarchive: {
        component: InboxArchive
      },
      alarm: {
        component: AlarmIcon
      },
      gauge: {
        component: GaugeIcon
      },
      scanGaugeKit: {
        component: ScanGaugeKit
      },
      scanGaugeKitDark: {
        component: ScanGaugeKit,
        props: {
          color: "#FFFFFF"
        }
      },
      visa: {
        component: VisaIcon
      },
      mastercard: {
        component: MastercardIcon
      },
      amex: {
        component: AmexIcon
      },
      order: {
        component: OrderIcon
      },
      upload: {
        component: UploadIcon
      },
      orderform: {
        component: OrderForm
      },
      fileupload: {
        component: FileUpload
      },
      orderformDark: {
        component: OrderForm,
        props: {
          color: "#FFFFFF"
        }
      }
    }
  }
})
