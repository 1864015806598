function login(callbackRoute = null) {
  if (callbackRoute) {
    sessionStorage.setItem("callbackRoute", JSON.stringify(callbackRoute))
  }
  window.location = "/authorize"
}

function logout() {
  window.location = "/logout"
}

export { login, logout }
