<template>
  <v-card-text>
    <v-card-title class="title-font"> DISTAL EXTENSIONS </v-card-title>
    <v-card-text>
      How far back past the implants do you intend the prosthesis to extend?
    </v-card-text>
    <v-row>
      <v-col cols="4" class="d-flex flex-column align-center">
        <ToothPicker
          v-model="teeth"
          :convention="internationalToothConvention"
          style="max-height: 350px"
          :interactive="true"
          :distal-extension="true"
          :prosthetic-coverage="prostheticCoverage"
          :distal-extension-coverage="distalExtensionCoverage"
          @distalExtensionClick="distalExtensionClick"
          :custom-colors="customColors"
          :disabled-teeth="disabledTeeth"
        />
        <v-btn-toggle v-model="internationalToothConvention" class="rounded-10">
          <v-btn x-small value="fdi">FDI</v-btn>
          <v-btn x-small value="palmer">Pal.</v-btn>
          <v-btn x-small value="universal">Uni.</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="8">
        <v-card-text>
          <strong
            >Click the tooth on either side of the patient arch you wish the
            prosthesis to extend to.
          </strong>
        </v-card-text>
        <v-card-text class="fill-width text-center"> OR </v-card-text>
        <v-row>
          <v-col cols="6">
            <v-card-text>
              <v-switch
                v-if="!upperDisabled"
                v-model="distalExtension.upperLeft"
                :disabled="distalExtension.upperLeft === 0"
                :true-value="0"
                :false-value="null"
                inset
                hide-details
                label="Follow scan Patient Left: Upper"
              />
              <v-switch
                v-if="!lowerDisabled"
                v-model="distalExtension.lowerLeft"
                :disabled="distalExtension.lowerLeft === 0"
                :true-value="0"
                :false-value="null"
                inset
                hide-details
                label="Follow scan Patient Left: Lower"
              />
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-card-text>
              <v-switch
                v-if="!upperDisabled"
                v-model="distalExtension.upperRight"
                :disabled="distalExtension.upperRight === 0"
                :true-value="0"
                :false-value="null"
                value="0"
                inset
                hide-details
                label="Follow scan Patient Right: Upper"
              />
              <v-switch
                v-if="!lowerDisabled"
                v-model="distalExtension.lowerRight"
                :disabled="distalExtension.lowerRight === 0"
                :true-value="0"
                :false-value="null"
                value="0"
                inset
                hide-details
                label="Follow scan Patient Right: Lower"
              />
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import ToothPicker from "./ToothPicker"
import internationalToothNumbers from "./international_tooth_numbers"

const UPPER_RIGHT = ["11", "12", "13", "14", "15", "16", "17"]
const UPPER_LEFT = ["21", "22", "23", "24", "25", "26", "27"]
const LOWER_RIGHT = ["41", "42", "43", "44", "45", "46", "47"]
const LOWER_LEFT = ["31", "32", "33", "34", "35", "36", "37"]

export default {
  name: "DistalExtension",

  props: ["implantLocations", "value"],

  components: { ToothPicker },

  data() {
    return {
      teeth: [],
      internationalToothConvention: "fdi",
      posteriorImplant: {
        upperRight: 0,
        upperLeft: 0,
        lowerRight: 0,
        lowerLeft: 0
      },
      distalExtension: {
        upperRight: 0,
        upperLeft: 0,
        lowerRight: 0,
        lowerLeft: 0
      },
      customColors: [],
      disabledTeeth: [],
      upperDisabled: true,
      lowerDisabled: true
    }
  },

  mounted() {
    this.teeth = this.implantLocations.map((item) => item.tooth)
    this.teeth.forEach((tooth) => {
      if (tooth > 30) {
        this.lowerDisabled = false
      } else {
        this.upperDisabled = false
      }
    })
    if (this.upperDisabled) {
      this.disabledTeeth = [...UPPER_RIGHT, ...UPPER_LEFT]
    }
    if (this.lowerDisabled) {
      this.disabledTeeth = [...LOWER_RIGHT, ...LOWER_LEFT]
    }
    this.selected?.forEach((num) => {
      if (num > 40) this.distalExtension.lowerRight = num
      else if (num > 30) this.distalExtension.lowerLeft = num
      else if (num > 20) this.distalExtension.upperLeft = num
      else if (num > 10) this.distalExtension.upperRight = num
    })
  },

  computed: {
    selected: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    allTeeth() {
      return Object.keys(internationalToothNumbers)
    },

    prostheticCoverage() {
      const prostheticCoverage = []

      this.teeth.forEach((tooth) => {
        if (tooth < 20) {
          this.posteriorImplant.upperRight = Math.max(
            this.posteriorImplant.upperRight,
            tooth
          )
        } else if (tooth < 30 && tooth > 20) {
          this.posteriorImplant.upperLeft = Math.max(
            this.posteriorImplant.upperLeft,
            tooth
          )
        } else if (tooth < 40 && tooth > 30) {
          this.posteriorImplant.lowerLeft = Math.max(
            this.posteriorImplant.lowerLeft,
            tooth
          )
        } else if (tooth < 50 && tooth > 40) {
          this.posteriorImplant.lowerRight = Math.max(
            this.posteriorImplant.lowerRight,
            tooth
          )
        }

        if (
          this.posteriorImplant.upperLeft !== 0 &&
          this.posteriorImplant.upperRight !== 0
        ) {
          UPPER_RIGHT.forEach((tooth) => {
            if (this.posteriorImplant.upperRight >= tooth) {
              prostheticCoverage.push(tooth)
            }
          })
          UPPER_LEFT.forEach((tooth) => {
            if (this.posteriorImplant.upperLeft >= tooth) {
              prostheticCoverage.push(tooth)
            }
          })
        }
        if (
          this.posteriorImplant.lowerLeft !== 0 &&
          this.posteriorImplant.lowerRight !== 0
        ) {
          LOWER_RIGHT.forEach((tooth) => {
            if (this.posteriorImplant.lowerRight >= tooth) {
              prostheticCoverage.push(tooth)
            }
          })
          LOWER_LEFT.forEach((tooth) => {
            if (this.posteriorImplant.lowerLeft >= tooth) {
              prostheticCoverage.push(tooth)
            }
          })
        }
      })

      if (
        (this.posteriorImplant.upperLeft === 0 &&
          this.posteriorImplant.upperRight !== 0) ||
        (this.posteriorImplant.upperLeft !== 0 &&
          this.posteriorImplant.upperRight === 0)
      ) {
        const arch = [...UPPER_LEFT, ...UPPER_RIGHT]
        let min = 100,
          max = 0
        this.teeth.forEach((tooth) => {
          if (tooth < 30) {
            min = Math.min(min, tooth)
            max = Math.max(max, tooth)
          }
        })
        arch.forEach((tooth) => {
          if (tooth >= min && tooth <= max) {
            prostheticCoverage.push(tooth)
          }
        })
      }
      if (
        (this.posteriorImplant.lowerLeft === 0 &&
          this.posteriorImplant.lowerRight !== 0) ||
        (this.posteriorImplant.lowerLeft !== 0 &&
          this.posteriorImplant.lowerRight === 0)
      ) {
        const arch = [...LOWER_LEFT, ...LOWER_RIGHT]
        let min = 100,
          max = 0
        this.teeth.forEach((tooth) => {
          if (tooth > 30) {
            min = Math.min(min, tooth)
            max = Math.max(max, tooth)
          }
        })
        arch.forEach((tooth) => {
          if (tooth >= min && tooth <= max) {
            prostheticCoverage.push(tooth)
          }
        })
      }

      if (prostheticCoverage.length) {
        this.allTeeth.forEach((tooth) => {
          if (!prostheticCoverage.includes(tooth)) {
            this.customColors[tooth] = { start: "#FFCC80", end: "#FF9800" }
          }
        })
      }
      return prostheticCoverage
    },

    distalExtensionCoverage() {
      let coverage = []

      if (this.distalExtension.upperRight !== 0) {
        UPPER_RIGHT.forEach((item) => {
          if (
            item > this.posteriorImplant.upperRight &&
            item <= this.distalExtension.upperRight
          ) {
            coverage.push(item)
          }
        })
      }

      if (this.distalExtension.upperLeft !== 0) {
        UPPER_LEFT.forEach((item) => {
          if (
            item > this.posteriorImplant.upperLeft &&
            item <= this.distalExtension.upperLeft
          ) {
            coverage.push(item)
          }
        })
      }

      if (this.distalExtension.lowerLeft !== 0) {
        LOWER_LEFT.forEach((item) => {
          if (
            item > this.posteriorImplant.lowerLeft &&
            item <= this.distalExtension.lowerLeft
          ) {
            coverage.push(item)
          }
        })
      }

      if (this.distalExtension.lowerRight !== 0) {
        LOWER_RIGHT.forEach((item) => {
          if (
            item > this.posteriorImplant.lowerRight &&
            item <= this.distalExtension.lowerRight
          ) {
            coverage.push(item)
          }
        })
      }

      return coverage
    }
  },

  watch: {
    distalExtension: {
      handler(object) {
        this.selected = Object.values(object).filter((item) => item !== 0)
      },
      deep: true
    }
  },

  methods: {
    distalExtensionClick(tooth) {
      if (tooth < 20 && this.posteriorImplant.upperRight !== 0) {
        // this.distalExtensionCoverage.upperRight = []
        if (this.distalExtension.upperRight === tooth) {
          this.distalExtension.upperRight = 0
          return
        }
        this.distalExtension.upperRight = tooth

        // UPPER_RIGHT.forEach((item) => {
        //   if (item > this.posteriorImplant.upperRight && item <= tooth) {
        //     this.distalExtensionCoverage.upperRight.push(item)
        //   }
        // })
      } else if (
        tooth < 30 &&
        tooth > 20 &&
        this.posteriorImplant.upperLeft !== 0
      ) {
        // this.distalExtensionCoverage.upperLeft = []
        if (this.distalExtension.upperLeft === tooth) {
          this.distalExtension.upperLeft = 0
          return
        }
        this.distalExtension.upperLeft = tooth
        // UPPER_LEFT.forEach((item) => {
        //   if (item > this.posteriorImplant.upperLeft && item <= tooth) {
        //     this.distalExtensionCoverage.upperLeft.push(item)
        //   }
        // })
      } else if (
        tooth < 40 &&
        tooth > 30 &&
        this.posteriorImplant.lowerLeft !== 0
      ) {
        // this.distalExtensionCoverage.lowerLeft = []
        if (this.distalExtension.lowerLeft === tooth) {
          this.distalExtension.lowerLeft = 0
          return
        }
        this.distalExtension.lowerLeft = tooth
        // LOWER_LEFT.forEach((item) => {
        //   if (item > this.posteriorImplant.lowerLeft && item <= tooth) {
        //     this.distalExtensionCoverage.lowerLeft.push(item)
        //   }
        // })
      } else if (tooth > 40 && this.posteriorImplant.lowerRight !== 0) {
        // this.distalExtensionCoverage.lowerRight = []
        if (this.distalExtension.lowerRight === tooth) {
          this.distalExtension.lowerRight = 0
          return
        }
        this.distalExtension.lowerRight = tooth
        // LOWER_RIGHT.forEach((item) => {
        //   if (item > this.posteriorImplant.lowerRight && item <= tooth) {
        //     this.distalExtensionCoverage.lowerRight.push(item)
        //   }
        // })
      }
    }
  }
}
</script>
