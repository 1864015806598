<template>
  <div>
    <v-divider class="mx-4" />
    <v-card-text>
      <v-card-title class="title-font"> SPECIAL REQUESTS </v-card-title>
      <v-card-text>
        Include any important information pertaining to the case design.
        Important changes from the existing prosthesis etc.
      </v-card-text>
      <v-textarea
        v-model="text"
        label="Special Requests"
        full-width
        outlined
        class="mx-4"
        hide-details
      ></v-textarea>
    </v-card-text>
  </div>
</template>

<script>
import { debounce } from "@/utils"
export default {
  name: "OrderOptions4",

  props: ["value", "order"],

  data() {
    return { text: "" }
  },

  created() {
    this.debouncedTextChange = debounce(this.handleTextChange, 500)
  },

  mounted() {
    this.text = this.options.specialRequests || ""
  },

  watch: {
    text(value) {
      this.debouncedTextChange(value)
    }
  },

  computed: {
    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    handleTextChange(text) {
      this.options = { ...this.options, specialRequests: text }
    }
  }
}
</script>
