<template>
  <div>
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font" v-if="!exportOrOrder">
        EXPORT &amp; ORDER
      </v-card-title>
      <v-card-title class="title-font" v-if="exportOrOrder === 'order'">
        <v-icon large class="mr-2">$order</v-icon>
        ORDER FROM OSTEON
      </v-card-title>
      <v-card-title class="title-font" v-if="exportOrOrder === 'export'">
        <v-icon large class="mr-2">$implantexport</v-icon>
        EXPORT ABUTMENT POSITIONS
      </v-card-title>
    </v-card>
    <v-card flat class="rounded-20 ma-4" v-if="!exportOrOrder" height="70vh">
      <v-card-text>
        <v-row class="my-10">
          <v-col cols="3"></v-col>
          <v-col cols="3" class="d-flex justify-center">
            <v-hover v-slot="{ hover }">
              <div>
                <v-card
                  flat
                  height="225"
                  width="225"
                  :class="['rounded-10', { 'btn-primary': hover }]"
                  style="border: 3px solid #147bd1"
                  @click="exportOrOrder = 'export'"
                >
                  <v-card-text class="px-1">
                    <v-row
                      no-gutters
                      class="d-flex justify-center align-center"
                    >
                      <v-col
                        cols="auto"
                        class="d-flex align-center justify-center"
                      >
                        <ImplantExport
                          :color="hover ? 'white' : iconColor"
                          style="height: 40px; width: 40px"
                          class="ma-1"
                        />
                      </v-col>
                      <v-col>
                        <span
                          class="title-font d-flex justify-center align-center"
                          :style="{
                            wordBreak: 'break-word',
                            color: hover ? 'white' : '#147BD1'
                          }"
                        >
                          EXPORT ABUTMENT POSITIONS
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-img
                    v-if="!hover"
                    contain
                    max-height="100"
                    :src="require('@/assets/abutment-export.svg')"
                    position="center center"
                  />
                  <v-img
                    v-if="hover"
                    contain
                    max-height="100"
                    :src="require('@/assets/abutment-export-light.svg')"
                    position="center center"
                  />
                  <v-card-actions> </v-card-actions>
                </v-card>
                <v-card flat max-width="225" v-if="hover">
                  <v-card-text>
                    Export implant positions:
                    <ul>
                      <li>Choose an OEM Scan Body and connection geometry.</li>
                      <li>Your choice of direct to MUA or Ti Base.</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </div>
            </v-hover>
          </v-col>
          <v-col cols="3" class="d-flex justify-center">
            <v-hover v-slot="{ hover }">
              <div>
                <v-card
                  flat
                  height="225"
                  width="225"
                  :class="['rounded-10', { 'btn-primary': hover }]"
                  style="border: 3px solid #147bd1"
                  @click="exportOrOrder = 'order'"
                >
                  <v-card-text class="px-1">
                    <v-row
                      no-gutters
                      class="d-flex justify-center align-center"
                    >
                      <v-col
                        cols="auto"
                        class="d-flex justify-center align-center"
                      >
                        <OrderIcon
                          :color="hover ? 'white' : iconColor"
                          style="height: 40px; width: 40px"
                          class="ma-1"
                        />
                      </v-col>
                      <v-col>
                        <span
                          class="title-font d-flex justify-center align-center"
                          :style="{
                            wordBreak: 'break-word',
                            color: hover ? 'white' : '#147BD1'
                          }"
                        >
                          ORDER FROM OSTEON
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-img
                    v-if="hover"
                    contain
                    max-height="100"
                    :src="require('@/assets/order-light.svg')"
                    position="center center"
                  />
                  <v-img
                    v-if="!hover"
                    contain
                    max-height="100"
                    :src="require('@/assets/order.svg')"
                    position="center center"
                  />
                </v-card>
                <v-card flat max-width="225" v-if="hover">
                  <v-card-text>
                    Choose your design option:
                    <ul>
                      <li>Design Only File (Per Arch) Milled Bar</li>
                      <li>Fully Finished Prosthesis</li>
                    </ul>
                  </v-card-text>
                </v-card>
              </div>
            </v-hover>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="exportOrOrder === 'export'" flat class="rounded-20 ma-4">
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <ExportComponent
              :order="order"
              :scan-body-libraries="scanBodyLibraries"
              @cancelled="cancel"
              @download="downloadFile"
            />
          </v-col>
          <v-col cols="6">
            <v-card flat class="ma-4 rounded-20">
              <v-card-title> Existing Export Files </v-card-title>
              <v-card-text v-if="!historyExports.length">
                There are no Existing Export Files.
              </v-card-text>
              <v-simple-table v-if="historyExports.length">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Library</th>
                      <th>Exported At</th>
                      <th class="text-right">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="historyExport in historyExports"
                      :key="historyExport.uid"
                      style="cursor: pointer"
                    >
                      <td>{{ librayName(historyExport.library) }}</td>
                      <td>{{ historyExport.created_at | luxon }}</td>
                      <td class="text-right">
                        <v-btn icon @click="downloadFile()">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <OrderComponent
          v-if="exportOrOrder === 'order'"
          :order="order"
          @cancelled="cancel"
          @forwardedOrder=";(v) => (forwardedOrder = v)"
          @toExport="exportOrOrder = 'export'"
        />
      </v-col>
      <v-col
        cols="3"
        v-if="exportOrOrder === 'order' && !selectedForwardedOrder"
      >
        <v-card flat class="ma-4 ml-0 rounded-20">
          <v-card-title class="title-font">
            EXISTING ORDERS
            <v-progress-circular class="ml-5" v-if="loading" indeterminate />
          </v-card-title>
          <v-card-text v-if="!existingOrders.length">
            There are no Existing Orders.
          </v-card-text>
          <v-simple-table v-if="existingOrders.length">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(order, index) in existingOrders"
                  :key="order.uid"
                  style="cursor: pointer"
                >
                  <td :class="determineBorderRadius(index, true, true)">
                    <v-icon size="40">
                      {{ determineIconFromProduct(order.product) }}
                    </v-icon>
                  </td>
                  <td :class="determineBorderRadius(index, true, false)">
                    {{ order.created_at | luxonRelative }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <template v-if="partialOrders.length">
            <v-card-title class="title-font">
              PARTIALLY COMPLETE ORDERS
              <v-progress-circular class="ml-5" v-if="loading" indeterminate />
            </v-card-title>
            <v-card-text> Click on an order to continue. </v-card-text>
            <v-simple-table v-if="partialOrders.length">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(order, index) in partialOrders"
                    :key="order.uid"
                    style="cursor: pointer"
                    @click="handlePartialOrderClick(order)"
                  >
                    <td :class="determineBorderRadius(index, false, true)">
                      <v-icon size="40">
                        {{ determineIconFromProduct(order.product) }}
                      </v-icon>
                    </td>
                    <td :class="determineBorderRadius(index, false, false)">
                      {{ order.created_at | luxon({ output: "short" }) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.hover:hover {
  cursor: pointer;
  background-color: #bbdfff80;
}

.rounded-bl-20 {
  border-bottom-left-radius: 20px !important;
}

.rounded-br-20 {
  border-bottom-right-radius: 20px !important;
}
</style>

<script>
import ImplantExport from "@/components/shared/icons/ImplantExport"
import OrderIcon from "@/components/shared/icons/OrderIcon"
import ExportComponent from "./ExportComponent"
import OrderComponent from "./OrderComponent"
import client from "@/lib/ApiClient"
import { mapGetters, mapActions } from "vuex"
import { scanBodyLibraries } from "@/utils"

export default {
  name: "ExportOrOrder",

  props: ["order"],

  components: {
    ImplantExport,
    OrderIcon,
    ExportComponent,
    OrderComponent
  },

  data() {
    return {
      exportOrOrder: "",
      iconColor: "#147BD1",
      partialOrders: [],
      existingOrders: [],
      historyExports: [],
      scanBodyLibraries: scanBodyLibraries,
      loading: false
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedForwardedOrder"])
  },

  methods: {
    ...mapActions(["setSelectedForwardedOrder"]),
    cancel() {
      this.exportOrOrder = ""
    },

    determineBorderRadius(index, isExisting, isLeft) {
      if (isExisting) {
        if (
          !this.partialOrders.length ||
          index !== this.existingOrders.length - 1
        )
          return null
      } else if (index !== this.partialOrders.length - 1) return null

      if (isLeft) return "rounded-bl-20"
      if (!isLeft) return "rounded-br-20"
    },

    determineIconFromProduct(product_code) {
      switch (product_code) {
        case "BAR":
          return "$milledbar"
        case "DESIGN":
          return "$toothdesigns"
      }
    },

    async fetchHistoryExports() {
      this.loading = true
      this.historyExports = await client.instance.orders.fetchHistoryExports(
        this.selectedOrder.uid
      )
      this.historyExports.sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      )
      this.loading = false
    },

    downloadFile() {
      window.open("/exports/2MA.stl", "_blank")
      this.fetchHistoryExports()
    },

    librayName(libraryName) {
      return this.scanBodyLibraries.find(
        (library) => library.value === libraryName
      )?.text
    }
  },

  async mounted() {
    this.loading = true
    this.setSelectedForwardedOrder(null)
    const result = await client.instance.orders.getForwardedOrders(
      this.selectedOrder.uid
    )
    this.partialOrders = result
      .filter((o) => !o.submitted_at)
      .sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )
    this.existingOrders = result
      .filter((o) => !!o.submitted_at)
      .sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )
    this.fetchHistoryExports()
    this.loading = false
  }
}
</script>
