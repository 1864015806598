import VueConfetti from "vue-confetti"
import Vue from "vue"
import VueTelInputVuetify from "vue-tel-input-vuetify/lib"
import VueLuxon from "vue-luxon"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import router from "./router"
import store from "./store"
import { install as axiosInstall } from "./plugins/axios"
import { StripePlugin } from "@vue-stripe/vue-stripe"

const options = {
  pk: process.env.VUE_APP_STRIPE_PK,
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.API_VERSION,
  locale: process.env.LOCALE
}

Vue.use(StripePlugin, options)
Vue.use(VueConfetti)

Vue.config.productionTip = false

Vue.use(axiosInstall)
Vue.use(VueLuxon)

Vue.use(VueTelInputVuetify, {
  vuetify
})

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")
