export default [
  {
    code: "FREE",
    name: "Free",
    features: {
      scan_analyser_tool: true,
      custom_branding: false,
      inbox: false,
      ordering_platform: false,
      communication_tool: false,
      email_support: true,
      screen_share_support: false,
      api_access: false
    },
    quotas: {
      implant_position_export: 0,
      case_design: 0,
      scan_storage: 0
    },
    discounts: {
      milling_service: 0,
      finished_prosthetic: 0
    }
  },
  {
    code: "BASIC",
    name: "Basic",
    features: {
      scan_analyser_tool: true,
      custom_branding: false,
      inbox: true,
      ordering_platform: true,
      communication_tool: false,
      email_support: true,
      screen_share_support: false,
      api_access: false
    },
    quotas: {
      implant_position_export: 5,
      case_design: 2,
      scan_storage: 1
    },
    discounts: {
      milling_service: 0.05,
      finished_prosthetic: 0.05
    }
  },
  {
    code: "PREMIUM",
    name: "Premium",
    features: {
      scan_analyser_tool: true,
      custom_branding: true,
      inbox: true,
      ordering_platform: true,
      communication_tool: true,
      email_support: true,
      screen_share_support: true,
      api_access: false
    },
    quotas: {
      implant_position_export: 10,
      case_design: 4,
      scan_storage: "unlimited"
    },
    discounts: {
      milling_service: 0.1,
      finished_prosthetic: 0.1
    }
  },
  {
    code: "PRO",
    name: "Pro",
    features: {
      scan_analyser_tool: true,
      custom_branding: true,
      inbox: true,
      ordering_platform: true,
      communication_tool: true,
      email_support: true,
      screen_share_support: true,
      api_access: true
    },
    quotas: {
      implant_position_export: "unlimited",
      case_design: 10,
      scan_storage: "unlimited"
    },
    discounts: {
      milling_service: 0.15,
      finished_prosthetic: 0.15
    }
  }
]
