<template>
  <v-card flat class="ml-4 rounded-20">
    <v-card-title class="title-font"> SCAN GAUGE KIT: </v-card-title>
    <v-card-text>
      <v-row>
        <v-col v-for="(gaugeCuff, group) in gauges" :key="group" cols="6">
          <v-card class="rounded-10" flat outlined>
            <v-row>
              <v-col v-for="(gauge, index) in gaugeCuff" :key="index" cols="4">
                <div class="pa-2 d-flex justify-center">
                  <v-img :src="gauge.src" contain max-width="37.5">
                    <div
                      v-ripple
                      :class="[gaugeClass(gauge), 'fill-height']"
                      @click="handleGaugeSelection(gauge)"
                    ></div>
                  </v-img>
                  <v-badge
                    v-if="libraryAssociation.includes(gauge.kitGaugeId)"
                    bordered
                    color="secondary"
                    icon="mdi-close"
                    overlap
                    @click.native="$emit('delete', gauge.kitGaugeId)"
                  >
                  </v-badge>
                </div>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer />
              <v-card-subtitle class="py-0 title-font">
                {{ `${group === 0 ? "3mm Cuff Height" : "5mm Cuff Height"}` }}
              </v-card-subtitle>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "GaugeSelection",

  props: ["value", "gauges", "libraryAssociation"],

  computed: {
    selectedGauge: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    handleGaugeSelection(gauge) {
      if (this.libraryAssociation.includes(gauge.kitGaugeId)) return
      if (!this.selectedGauge) {
        this.selectedGauge = gauge
      } else {
        if (gauge.kitGaugeId === this.selectedGauge.kitGaugeId) {
          this.selectedGauge = null
        } else {
          this.selectedGauge = gauge
        }
      }
    },

    gaugeClass(gauge) {
      if (this.selectedGauge) {
        if (gauge.kitGaugeId === this.selectedGauge.kitGaugeId) {
          return "selected-gauge hover-gauge"
        } else if (this.libraryAssociation.includes(gauge.kitGaugeId)) {
          return "associated-gauge"
        }
      } else {
        if (this.libraryAssociation.includes(gauge.kitGaugeId)) {
          return "associated-gauge"
        }
      }
      return "hover-gauge"
    }
  }
}
</script>
