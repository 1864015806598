<template>
  <v-card v-if="pricings" flat class="card-bg rounded-20">
    <v-card-title
      class="title-font primary--text font-weight-bold"
      style="font-size: 22px"
    >
      ORDER SUMMARY
    </v-card-title>
    <v-card-text>
      <v-card class="rounded-10" flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div
                class="d-flex justify-space-between title-font"
                style="font-size: 12px"
              >
                <span>Description</span>
                <span>Amount</span>
              </div>
              <v-divider class="mb-3" />
              <div
                v-for="(pricing, index) in pricings"
                :key="index"
                class="title-font mb-2"
              >
                <div class="d-flex justify-space-between">
                  <h4 class="black--text">{{ productName(pricing.name) }}</h4>
                  <p class="mb-0">
                    {{ pricing.currency }}
                    ${{ pricing.price.toLocaleString() }}
                    <span style="font-size: 9px">
                      {{
                        pricing.name.includes("Subscription")
                          ? `/ ${pricing.renewal_frequency.slice(0, -2)}`
                          : null
                      }}
                    </span>
                  </p>
                </div>
                <p
                  v-if="pricing.name.includes('Subscription')"
                  style="font-size: 9px"
                  class="mb-0 mt-n1"
                >
                  This is a recurring cost charged at the beginning of each
                  billing period after the activation of the subscription.
                </p>

                <p
                  v-if="pricing.name.includes('Subscription')"
                  style="font-size: 9px"
                  class="mb-0 mt-n2"
                >
                  New subscriptions are not activated until you make your first
                  transaction within the system, or until the 120 day grace
                  period elapses.
                </p>
                <v-divider class="my-3" />
              </div>
            </v-col>
            <v-col cols="12" class="title-font mt-n6 d-flex justify-end">
              <div>
                <h3>Total Due:</h3>
                <p
                  class="black--text font-weight-bold mt-2"
                  style="font-size: 32px"
                >
                  ${{ totalPrice.toLocaleString() }}
                </p>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { toPascalCase } from "@/utils"
export default {
  name: "OrderSummary",

  props: ["pricings"],

  computed: {
    totalPrice() {
      return this.pricings.reduce((sum, item) => {
        return sum + item.price
      }, 0)
    }
  },

  methods: {
    productName(productName) {
      return toPascalCase(productName)
    }
  }
}
</script>
