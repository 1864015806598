<template>
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.125 11.4585V11.4585C3.125 12.5079 3.125 13.0326 3.31702 13.4386C3.51486 13.8569 3.8516 14.1936 4.26989 14.3915C4.67588 14.5835 5.20059 14.5835 6.25 14.5835H6.95359C7.58859 14.5835 7.90609 14.5835 8.15305 14.7421C8.18308 14.7614 8.21205 14.7823 8.23985 14.8047C8.46844 14.9888 8.56884 15.29 8.76965 15.8924L8.91915 16.341C9.13916 17.001 9.24916 17.331 9.51105 17.5197C9.77294 17.7085 10.1208 17.7085 10.8165 17.7085H14.1835C14.8792 17.7085 15.2271 17.7085 15.489 17.5197C15.7508 17.331 15.8608 17.001 16.0808 16.341L16.2304 15.8924C16.4312 15.29 16.5316 14.9888 16.7602 14.8047C16.7879 14.7823 16.8169 14.7614 16.847 14.7421C17.0939 14.5835 17.4114 14.5835 18.0464 14.5835H18.75C19.7994 14.5835 20.3241 14.5835 20.7301 14.3915C21.1484 14.1936 21.4851 13.8569 21.683 13.4386C21.875 13.0326 21.875 12.5079 21.875 11.4585V11.4585"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.33325 9.375L12.4999 12.5M12.4999 12.5L16.6666 9.375M12.4999 12.5L12.4999 2.08333"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.6667 5.2085H17.875C19.7606 5.2085 20.7034 5.2085 21.2892 5.79428C21.875 6.38007 21.875 7.32288 21.875 9.2085V17.8752C21.875 19.7608 21.875 20.7036 21.2892 21.2894C20.7034 21.8752 19.7606 21.8752 17.875 21.8752H7.125C5.23938 21.8752 4.29657 21.8752 3.71079 21.2894C3.125 20.7036 3.125 19.7608 3.125 17.8752V9.2085C3.125 7.32288 3.125 6.38007 3.71079 5.79428C4.29657 5.2085 5.23938 5.2085 7.125 5.2085H8.33333"
      stroke="#333F48"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  name: "InboxReceived"
}
</script>
