<template>
  <v-card flat>
    <v-card-title>
      <v-icon class="mr-2">mdi-web</v-icon>
      Choose Domain
    </v-card-title>
    <!-- <v-card-text class="d-flex align-center">
      <span class="text-body-1 ml-8">
        https://cloud-staging/nexusconnectlab.com/
      </span>
      <v-text-field
        v-model="selectedDomain"
        label="Domain"
        outlined
        hide-details
        dense
        class="ml-1"
      />
    </v-card-text> -->

    <v-card-text>
      <v-text-field
        v-model="selectedDomain"
        label="Domain"
        prefix="https://cloud-staging/nexusconnectlab.com/"
        outlined
        dense
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DomainSelector",

  props: ["value"],

  computed: {
    selectedDomain: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
