<template>
  <v-card flat class="rounded-20 ma-4 d-flex flex-column" height="70vh">
    <v-row>
      <!-- <v-col cols="1"></v-col> -->
      <v-col cols="3" align="center" class="my-10">
        <v-hover v-slot="{ hover }">
          <div>
            <v-card
              flat
              height="175"
              width="175"
              :class="['rounded-10', { 'btn-primary': hover }]"
              style="border: 3px solid #147bd1"
              @click="$emit('chooseOrder', 'design_only')"
            >
              <v-card-text
                class="px-1 text-center text-subtitle-1"
                :style="{
                  color: hover ? 'white' : '#147BD1'
                }"
              >
                <strong> TOOTH DESIGN ONLY </strong>
              </v-card-text>
              <v-container fluid>
                <v-img
                  v-if="!hover"
                  contain
                  :src="require('@/assets/design-only-prosthesis.png')"
                  position="center center"
                />
                <v-img
                  v-if="hover"
                  contain
                  :src="require('@/assets/design-only-prosthesis-white.png')"
                  position="center center"
                />
              </v-container>
            </v-card>
            <v-card flat v-if="hover">
              <v-card-text class="text-left">
                <ul>
                  <li>Full-arch bridge design.</li>
                  <li>Direct to MUA or Ti Base.</li>
                  <li>Milled or printed by you.</li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
        </v-hover>
      </v-col>

      <v-col cols="3" align="center" class="my-10">
        <v-hover v-slot="{ hover }">
          <div>
            <v-card
              height="175"
              width="175"
              :class="['rounded-10', { 'btn-primary': hover }]"
              style="border: 3px solid #147bd1"
              v-ripple
              @click="$emit('chooseOrder', 'milled_bar')"
            >
              <v-card-text
                class="px-1 text-center text-subtitle-1 pb-0"
                :style="{
                  color: hover ? 'white' : '#147BD1'
                }"
              >
                <strong> MILLED BAR </strong>
              </v-card-text>
              <v-card-text
                class="pa-0 text-center font-weight-regular text-caption"
                :style="{
                  color: hover ? 'white' : '#147BD1'
                }"
              >
                Your Tooth Design
              </v-card-text>
              <v-container fluid>
                <v-img
                  contain
                  :src="require('@/assets/milled-bar-only.png')"
                  position="center center"
                />
              </v-container>
            </v-card>
            <v-card flat v-if="hover">
              <v-card-text class="text-left">
                <ul>
                  <li>
                    Design and milling of a Nexus bar to your provided
                    tooth-design.
                  </li>
                  <li>
                    Start with abutment exports to begin design on lab-side.
                  </li>
                  <li>Requires final tooth design to begin bar design.</li>
                  <li>Receive a milled bar and overlay file for milling.</li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
        </v-hover>
      </v-col>

      <v-col cols="3" align="center" class="my-10">
        <v-hover v-slot="{ hover }">
          <div>
            <v-card
              height="175"
              width="175"
              :class="['rounded-10', { 'btn-primary': hover }]"
              style="border: 3px solid #147bd1"
            >
              <v-card-text
                class="px-1 text-center text-subtitle-1 pb-0"
                :style="{
                  color: hover ? 'white' : '#147BD1'
                }"
              >
                <strong> TOOTH DESIGN & MILLED BAR </strong>
              </v-card-text>
              <v-card-text
                class="pa-0 text-center font-weight-regular text-caption"
                :style="{
                  color: hover ? 'white' : '#147BD1'
                }"
              >
                Using Osteon Tooth Design
              </v-card-text>
              <v-container fluid>
                <v-img
                  v-if="hover"
                  contain
                  :src="
                    require('@/assets/design-only-prosthesis-with-bar-white.png')
                  "
                  position="center center"
                />
                <v-img
                  v-if="!hover"
                  contain
                  :src="require('@/assets/design-only-prosthesis-with-bar.png')"
                  position="center center"
                />
              </v-container>
            </v-card>
            <v-card flat v-if="hover">
              <v-card-text class="text-left">
                <ul>
                  <li>
                    Design and milling of a Nexus bar with Osteon tooth design.
                  </li>
                  <li>
                    Receive tooth design to either approve, or 3D print and use
                    for Try-In.
                  </li>
                  <li>One design is approved, bar milled.</li>
                  <li>
                    Receive a milled bar and overlay file for lab
                    milling/printing.
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
        </v-hover>
      </v-col>

      <v-col cols="3" align="center" class="my-10">
        <v-hover v-slot="{ hover }">
          <div>
            <v-card
              height="175"
              width="175"
              :class="['rounded-10', { 'btn-primary': hover }]"
              style="border: 3px solid #147bd1"
            >
              <v-card-text
                class="px-1 text-center text-subtitle-1"
                :style="{
                  color: hover ? 'white' : '#147BD1'
                }"
              >
                <strong> FINISHED CASE </strong>
              </v-card-text>
              <v-container fluid>
                <v-img
                  contain
                  :src="require('@/assets/final-prosthesis-bar-retained.png')"
                  position="center center"
                ></v-img>
              </v-container>
            </v-card>
            <v-card flat v-if="hover">
              <v-card-text class="text-left">
                <ul>
                  <li>Completely finished Nexus Hybrid prosthesis.</li>
                  <li>Try-In included in process.</li>
                  <li>Ready for patient insert.</li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
        </v-hover>
      </v-col>
      <!-- <v-col cols="1"></v-col> -->
    </v-row>
    <v-card-actions>
      <v-spacer />
      <v-btn class="rounded-10" elevation="0" @click="cancel"> Cancel </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style>
.hover:hover {
  cursor: pointer;
  background-color: #bbdfff80;
}
</style>

<script>
export default {
  name: "ChooseOrder",

  methods: {
    cancel() {
      this.$emit("cancelled")
    }
  }
}
</script>
