export default [
  {
    name: "Dummy Business 1",
    tenant_uid: "2e946941-6e63-4d83-a654-8132855b91c7",
    role: "owner",
    instance_url: "",
    instance_uid: "fb49c33a-b69f-4974-b72a-656303b24385"
  },
  {
    name: "Dummy Business 2",
    tenant_uid: "4b57bc43-3550-42ef-a127-02b9fb310687",
    role: "member",
    instance_url: "",
    instance_uid: "679eba05-b734-4b6c-8178-434bb53d7ffe"
  },
  {
    name: "Dummy Business 3",
    tenant_uid: "e2c33964-f025-40d2-8ea2-9de7d67d14d7",
    role: "owner",
    instance_url: "",
    instance_uid: "3fef91a9-0a29-42d6-aa50-3c335189a3d9"
  }
]
