<template>
  <v-row>
    <v-col cols="12" :align="align">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-chip
            v-if="message.note"
            class="rounded-10 pa-2 text-left"
            flat
            label
            dark
            :color="color"
            style="height: auto"
          >
            <span style="white-space: pre-wrap">
              <span>{{ message.note }}</span>
            </span>
          </v-chip>
        </v-card-text>
        <div class="my-2" v-if="message.attachments?.length > 0">
          <MessageAttachments :message="message" />
        </div>
      </v-card>
      <small>
        <span v-if="message.author_reference">
          <v-icon small> mdi-account </v-icon>
          {{ message.author_reference }}
          -
        </span>
        {{ message.created_at | luxon({ output: "short" }) }}
      </small>
    </v-col>
  </v-row>
</template>

<script>
import MessageAttachments from "./MessageAttachments.vue"

export default {
  props: ["message"],

  components: { MessageAttachments },

  computed: {
    align() {
      if (this.message.direction === "OUTBOUND") {
        return "right"
      }
      return "left"
    },

    color() {
      switch (this.message.message_type) {
        case "OSTEON_MESSAGE":
          return "primary"
        case "CLIENT_MESSAGE":
          return "#333F48"
        case "PRACTICE_MESSAGE":
          return "warning"
        default:
          return ""
      }
    }
  }
}
</script>
