import axios from "axios"
import clientOptions from "./client_options"

const axiosClient = axios.create(clientOptions)
const axiosAuth = axios.create()

axiosClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

function install(Vue) {
  Vue.axios = axiosClient
  Vue.axiosAuth = axiosAuth
  window.axios = axiosClient
  window.axiosAuth = axiosAuth
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axiosClient
      }
    },
    axiosAuth: {
      get() {
        return axiosAuth
      }
    },
    $axios: {
      get() {
        return axiosClient
      }
    },
    $axiosAuth: {
      get() {
        return axiosAuth
      }
    }
  })
}

export { install, axiosClient, axiosAuth }
