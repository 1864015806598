<template>
  <v-card flat v-if="labValidationKit" class="rounded-10">
    <v-card-title class="pb-0 text-center primary--text">
      <v-row>
        <v-col class="text-center primary--text">
          <h3>
            {{ labValidationKit.name }}
            <v-btn
              style="position: absolute; right: 16px"
              fab
              elevation="0"
              x-small
              dark
              color="grey"
              @click="collapsed = !collapsed"
            >
              <v-icon>
                {{ collapsed ? "mdi-chevron-down" : "mdi-chevron-up" }}
              </v-icon>
            </v-btn>
          </h3>
          <span style="font-size: 20px">{{
            labValidationKit.description
          }}</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="text-center mt-5 title-font">
        <span class="black--text font-weight-bold" style="font-size: 32px">
          ${{ labValidationKit.price.toLocaleString() }}
        </span>
        (One Off Payment)
      </div>
      <v-expand-transition>
        <v-row v-if="!collapsed" class="mt-5" justify="center">
          <v-col cols="auto">
            <v-img
              :src="require('@/assets/sgk-gen2.png')"
              contain
              class="mb-3"
              width="180px"
            ></v-img>
          </v-col>
          <v-col cols="auto">
            <div><strong>Validation Pack</strong></div>
            <ul>
              <li v-for="(feature, index) in features" :key="index">
                <span>
                  {{ feature }}
                </span>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "LabValidationKit",

  props: ["labValidationKit"],

  data() {
    return {
      collapsed: false
    }
  },

  computed: {
    features() {
      if (!this.labValidationKit.features.length) {
        return []
      }
      return this.labValidationKit.features
    }
  }
}
</script>
