<template>
  <v-row>
    <v-col cols="6">
      <v-card-subtitle class="primary--text pb-2 px-2">
        <strong>Intraoral Scans </strong>
      </v-card-subtitle>
      <v-card outlined class="mx-2">
        <v-expansion-panels accordion tile flat>
          <v-expansion-panel
            v-for="[key, value] in Object.entries(intraoralScans)"
            :key="key"
          >
            <v-expansion-panel-header
              class="black--text title-font"
              style="font-size: 16px"
            >
              <v-icon
                v-if="!value"
                small
                color="error"
                class="mr-5 flex-grow-0"
              >
                mdi-close-circle
              </v-icon>
              <v-icon
                v-if="value"
                small
                color="success"
                class="mr-5 flex-grow-0"
              >
                mdi-check-circle
              </v-icon>
              {{ categories[key].title }}
              <small class="ml-2">{{ categories[key].subtitle }}</small>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="elevation-0">
                <div v-if="!value">
                  <v-card-text>
                    <v-btn
                      class="elevation-0 rounded-10"
                      width="100%"
                      height="86"
                      style="border: 2px rgb(187, 187, 187) solid"
                      @click="categories[key].dialog = true"
                    >
                      Choose Existing
                    </v-btn>
                    <v-dialog v-model="categories[key].dialog" width="90vw">
                      <v-card>
                        <ExistingFileSelector
                          :category="categories[key]"
                          @selection="handleSelection"
                        />
                      </v-card>
                    </v-dialog>
                  </v-card-text>
                  <div class="d-flex align-center px-5">
                    <v-divider /> <span class="text-caption mx-5"> OR </span>
                    <v-divider />
                  </div>
                  <v-card-text>
                    <FileUploader
                      v-if="!value"
                      :text="categories[key].title"
                      :fileExtension="['stl']"
                      :category="categories[key].value"
                      @uploaded="handleUploaded"
                    />
                  </v-card-text>
                </div>
                <ModelViewer
                  v-if="value"
                  :filename="value.filename"
                  :url="value.url"
                  :height="150"
                />
                <v-btn
                  v-if="value"
                  small
                  text
                  class="elevation-0 flex-grow-0 ml-4 mt-n10"
                  @click.stop="handleClear(key)"
                  style="position: absolute; right: 0"
                >
                  Clear
                </v-btn>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card-subtitle class="primary--text pb-2 px-2">
        <strong>Patient Photos</strong>
      </v-card-subtitle>
      <v-card outlined class="mx-2">
        <v-expansion-panels accordion tile flat>
          <v-expansion-panel
            v-for="[key, value] in Object.entries(patientPhotos)"
            :key="key"
          >
            <v-expansion-panel-header
              class="black--text title-font"
              style="font-size: 16px"
            >
              <v-icon
                v-if="!value"
                small
                color="error"
                class="mr-5 flex-grow-0"
              >
                mdi-close-circle
              </v-icon>
              <v-icon
                v-if="value"
                small
                color="success"
                class="mr-5 flex-grow-0"
              >
                mdi-check-circle
              </v-icon>
              {{ categories[key].title }}
              <small class="ml-2">{{ categories[key].subtitle }}</small>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="elevation-0">
                <div v-if="!value">
                  <v-card-text>
                    <v-btn
                      class="elevation-0 rounded-10"
                      width="100%"
                      height="86"
                      style="border: 2px rgb(187, 187, 187) solid"
                      @click="categories[key].dialog = true"
                    >
                      Choose Existing
                    </v-btn>
                    <v-dialog v-model="categories[key].dialog" width="90vw">
                      <v-card>
                        <ExistingFileSelector
                          :category="categories[key]"
                          @selection="handleSelection"
                        />
                      </v-card>
                    </v-dialog>
                  </v-card-text>
                  <div class="d-flex align-center px-5">
                    <v-divider /> <span class="text-caption mx-5"> OR </span>
                    <v-divider />
                  </div>
                  <v-card-text>
                    <FileUploader
                      v-if="!value"
                      :text="categories[key].title"
                      :fileExtension="['stl', 'ply']"
                      :category="categories[key].value"
                      @uploaded="handleUploaded"
                    />
                  </v-card-text>
                </div>
                <ModelViewer
                  v-if="value"
                  :filename="value.filename"
                  :url="value.url"
                  :height="150"
                />
                <v-btn
                  v-if="value"
                  small
                  text
                  class="elevation-0 flex-grow-0 ml-4 mt-n10"
                  @click.stop="handleClear(key)"
                  style="position: absolute; right: 0"
                >
                  Clear
                </v-btn>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <!-- <v-row class="mb-2">
      <v-col cols="4">
        <FileUploader
          :text="'Existing Prosthesis In-Situ Scan'"
          :fileExtension="['stl']"
          v-model="files[0]"
        />
      </v-col>
      <v-col cols="4">
        <FileUploader
          :text="'Tissue of Restoring Arch using Narrow Scan Bodies'"
          :fileExtension="['stl']"
          v-model="files[1]"
        />
      </v-col>
      <v-col cols="4">
        <FileUploader
          :text="'Existing Prosthesis Extr-Orally 360 Using Scan Analogs'"
          :fileExtension="['stl']"
          v-model="files[2]"
        />
      </v-col>
      <v-col cols="4">
        <FileUploader
          :text="'Antagonist Dentition'"
          :fileExtension="['stl']"
          v-model="files[3]"
        />
      </v-col>
      <v-col cols="4">
        <FileUploader
          :text="'Bite Scan'"
          :fileExtension="['stl']"
          v-model="files[4]"
        />
      </v-col>
    </v-row>

    <v-card-subtitle class="primary--text pb-2 px-0">
      <strong>Patient Photos</strong>
    </v-card-subtitle>
    <v-row class="mb-2">
      <v-col cols="4">
        <FileUploader
          :text="'Full-Face Smiling with Relaxed Bite'"
          :fileExtension="['jpg', 'jpeg', 'png']"
          v-model="files[5]"
        />
      </v-col>
      <v-col cols="4">
        <FileUploader
          :text="'Full-Face Smiling with Relaxed Bite using Retractors'"
          :fileExtension="['jpg', 'jpeg', 'png']"
          v-model="files[6]"
        />
      </v-col>
    </v-row> -->
  </v-row>
</template>

<script>
import { toCamelCase } from "@/utils"
import FileUploader from "@/components/shared/FileUploader"
import ModelViewer from "@/components/shared/ModelViewer.vue"
import ExistingFileSelector from "@/components/shared/ExistingFileSelector.vue"

export default {
  name: "UploadProjectFiles",

  components: { FileUploader, ModelViewer, ExistingFileSelector },

  props: ["value"],

  data() {
    return {
      loading: false,
      categories: {
        existingProsthesisInSituScan: {
          title: "Existing Prosthesis",
          subtitle: "In-Situ Scan",
          value: "existing_prosthesis_in_situ_scan",
          dialog: false,
          intraoral: true
        },
        tissueOfRestoringArchUsingNarrowScanBodies: {
          title: "Tissue of Restoring Arch",
          subtitle: "Using Narrow Scan Bodies",
          value: "tissue_of_restoring_arch_using_narrow_scan_bodies",
          dialog: false,
          intraoral: true
        },
        existingProsthesisExtraOrally360UsingScanAnalogs: {
          title: "Existing Prosthesis Extra-Orally 360",
          subtitle: "Using Scan Analogs",
          value: "existing_prosthesis_extra_orally_360_using_scan_analogs",
          dialog: false,
          intraoral: true
        },
        antagonistDentition: {
          title: "Antagonist Dentition",
          subtitle: "",
          value: "antagonist_dentition",
          dialog: false,
          intraoral: true
        },
        biteScan: {
          title: "Bite Scan",
          subtitle: "",
          value: "bite_scan",
          dialog: false,
          intraoral: true
        },
        fullFaceSmilingWithRelaxedBite: {
          title: "Full-Face Smiling",
          subtitle: "With Relaxed Bite",
          value: "full_face_smiling_with_relaxed_bite",
          dialog: false
        },
        fullFaceSmilingWithRelaxedBiteUsingRetractors: {
          title: "Full-Face Smiling",
          subtitle: "With Relaxed Bite using Retractors",
          value: "full_face_smiling_with_relaxed_bite_using_retractors",
          dialog: false
        }
      }
    }
  },

  computed: {
    files: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },
    intraoralScans() {
      return Object.keys(this.files)
        .filter((k) => this.categories[k].intraoral)
        .reduce((arr, val) => {
          arr[val] = this.files[val]
          return arr
        }, {})
    },
    patientPhotos() {
      return Object.keys(this.files)
        .filter((k) => !this.categories[k].intraoral)
        .reduce((arr, val) => {
          arr[val] = this.files[val]
          return arr
        }, {})
    }
  },

  methods: {
    handleUploaded(uploaded) {
      uploaded.forEach((upload) => {
        this.files[toCamelCase(upload.file_category)] = upload
      })
    },
    handleSelection(file, file_category) {
      const category = toCamelCase(file_category)
      this.files[category] = file
      this.categories[category].dialog = false
    },
    handleClear(category) {
      this.files[category] = null
    }
  }
}
</script>
