<template>
  <v-card-text>
    <v-card-title class="title-font"> TISSUE RELATIONSHIP </v-card-title>
    <v-card-text>
      How much pressure in <code>mm</code> do you want to put on the tissue?
    </v-card-text>
    <v-card outlined class="rounded-10">
      <v-row class="justify-center my-2">
        <v-card flat class="d-flex flex-row" outlined>
          <div v-ripple>
            <v-btn
              elevation="0"
              class="secondary"
              @click="relationship > min ? (relationship -= step) : ''"
              :disabled="relationship <= min"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </div>
          <v-card-text
            class="px-4 py-0 fill-height d-flex flex-column justify-center text-h5"
          >
            {{ relationship?.toFixed(1) }}mm
          </v-card-text>
          <v-btn
            elevation="0"
            class="secondary"
            @click="relationship < max ? (relationship += step) : ''"
            :disabled="relationship >= max"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-card>
      </v-row>
      <v-row>
        <v-col cols="4" class="pa-2">
          <v-hover v-slot="{ hover }">
            <v-card
              v-ripple
              flat
              :class="['ma-2 pa-2', { 'btn-hover': hover }]"
              @click="relationship > min ? (relationship -= step) : ''"
            >
              <v-img
                contain
                max-height="60"
                :src="require('@/assets/tissue-relationship-compression.png')"
              />
              <v-card-text class="text-center font-weight-bold pt-2">
                Compression
              </v-card-text>
              <v-card-text class="text-center py-0"> </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="4" class="pa-2">
          <v-hover v-slot="{ hover }">
            <v-card
              v-ripple
              flat
              :class="['ma-2 pa-2', { 'btn-hover': hover }]"
              @click="relationship = 0"
            >
              <v-img
                contain
                max-height="60"
                :src="require('@/assets/tissue-relationship-flush.png')"
              />
              <v-card-text class="text-center font-weight-bold pt-2 pb-0">
                As Close As Possible
              </v-card-text>
              <v-card-text class="text-center py-0"> Leave As 0mm </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="4" class="pa-2">
          <v-hover v-slot="{ hover }">
            <v-card
              v-ripple
              flat
              :class="['ma-2 pa-2', { 'btn-hover': hover }]"
              @click="relationship < max ? (relationship += step) : ''"
            >
              <v-img
                contain
                max-height="60"
                :src="require('@/assets/tissue-relationship-offset.png')"
              />
              <v-card-text class="text-center font-weight-bold pt-2">
                Offset
              </v-card-text>
              <v-card-text class="text-center py-0"> </v-card-text>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>
  </v-card-text>
</template>

<style>
.btn-hover:hover {
  cursor: pointer;
  background-color: #bbdfff80;
}
</style>

<script>
export default {
  name: "TissueRelationship",
  props: ["value"],
  data() {
    return {
      step: 0.5,
      min: -1,
      max: 3
    }
  },

  mounted() {
    if (!this.relationship) this.relationship = 0
  },

  computed: {
    relationship: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
