<template>
  <v-navigation-drawer
    app
    permanent
    expand-on-hover
    clipped
    dark
    color="secondary"
    style="z-index: 999999"
  >
    <v-list nav dense>
      <v-list-item class="primary--text" v-if="currentTenant">
        <v-list-item-icon>
          <v-icon color="primary">mdi-hospital-box</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <strong>
            {{ currentTenant.name }}
          </strong>
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="features.inbox" :to="{ name: 'inboxPage' }">
        <v-list-item-title>
          <v-icon class="mr-2">$inboxDark</v-icon>
          Case Inbox
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="features.ordering_platform"
        :to="{ name: 'formGeneratorPage' }"
      >
        <v-list-item-title>
          <v-icon class="mr-2">$orderformDark</v-icon>
          Your Order Form
        </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'scanGaugeKitsPage' }">
        <v-list-item-title>
          <v-icon class="mr-2">$scanGaugeKitDark</v-icon>
          Scan Gauge Kits
        </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'clientPage' }">
        <v-list-item-title>
          <v-icon class="mr-2">mdi-book-account-outline</v-icon>
          Client Directory
        </v-list-item-title>
      </v-list-item>
      <v-list-item :to="{ name: 'profilePage' }">
        <v-list-item-title>
          <v-icon class="mr-2">mdi-cog-outline</v-icon>
          Account Settings
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "NavigationDrawer",

  props: ["value"],

  computed: {
    ...mapGetters(["currentTenant", "features"]),

    drawer: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
