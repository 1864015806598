<template>
  <v-container fluid fill-height class="d-flex align-start py-0">
    <v-row class="fill-height">
      <v-col cols="6" class="py-0">
        <ProductForm
          v-model="questions"
          :originals="originalQuestions"
          :initialising="initialising"
          @save="handleSave"
        />
      </v-col>
      <v-col cols="6" class="py-0">
        <FormPreview :questions="questions" :initialising="initialising" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductForm from "./FormGenerator/ProductForm"
import FormPreview from "./FormGenerator/FormPreview"
import client from "@/lib/ApiClient"
import { deepCopy } from "@/utils"

export default {
  name: "FormGeneratorPage",
  components: {
    ProductForm,
    FormPreview
  },

  data() {
    return {
      initialising: false,
      loading: false,
      questions: [],
      originalQuestions: []
    }
  },

  watch: {
    questions: {
      handler(value) {
        value.forEach((q, index) => {
          q.order = index + 1
        })
      },
      deep: true
    }
  },

  methods: {
    handleSave(saveResponse) {
      this.originalQuestions = deepCopy(saveResponse)
    }
  },

  async mounted() {
    this.initialising = true
    this.questions = await client.instance.orders.getQuestions()
    this.originalQuestions = deepCopy(this.questions)
    this.initialising = false
  }
}
</script>

<!-- 
  TODO:

  Leaving the page with unsaved changes is too easy.
  Display confirmation modal to confirm that unsaved changes will be lost

  Draggable screws up the questions through v-model
  Implement something without the third party
 -->
