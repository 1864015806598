import Vue from "vue"
import Vuex from "vuex"

import client from "@/lib/ApiClient"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    applicationInitialized: false,
    entryRoute: null,
    introspection: {},
    subscription: null,
    country: null,
    token: null,
    loading: true,
    me: null,
    tenants: [],
    currentTenant: null,
    currentQuotas: null,
    quotaRollover: null,
    selectedOrder: null,
    selectedForwardedOrder: null,
    plans: [],
    instances: [],
    paymentMethods: [],
    features: {
      scan_analyser_tool: true,
      custom_branding: true,
      inbox: true,
      ordering_platform: true,
      communication_tool: true,
      email_support: true,
      screen_share_support: true,
      api_access: true
    }
  },

  getters: {
    applicationInitialized: (state) => {
      return state.applicationInitialized
    },
    entryRoute: (state) => {
      return state.entryRoute
    },
    introspection: (state) => {
      return state.introspection
    },
    subscription: (state) => {
      return state.subscription
    },
    loggedIn: (state) => {
      return !!state.token
    },
    registered: (state, getters) => {
      return getters.loggedIn && !!state.me
    },
    hasTenant: (state, getters) => {
      return getters.registered && !!state.currentTenant
    },
    activeSubscription: (state, getters) => {
      return getters.subscription?.subscription_status === "active"
    },
    plans: (state) => state.plans,
    instances: (state) => state.instances,
    paymentMethods: (state) => state.paymentMethods,
    country: (state) => state.country,
    token: (state) => state.token,
    loading: (state) => state.loading,
    me: (state) => state.me,
    tenants: (state) => state.tenants,
    currentTenant: (state) => state.currentTenant,
    currentQuotas: (state) => state.currentQuotas,
    quotaRollover: (state) => state.quotaRollover,
    selectedOrder: (state) => state.selectedOrder,
    selectedForwardedOrder: (state) => state.selectedForwardedOrder,
    features: (state) => state.features
  },

  mutations: {
    SET_APPLICATION_INITIALIZED(state, payload) {
      state.applicationInitialized = payload
    },
    SET_ENTRY_ROUTE(state, payload) {
      state.entryRoute = payload
    },
    SET_INTROSPECTION(state, payload) {
      state.introspection = payload
    },
    SET_SUBSCRIPTION(state, payload) {
      state.subscription = payload
    },
    SET_PLANS(state, payload) {
      state.plans = payload
    },
    SET_INSTANCES(state, payload) {
      state.instances = payload
    },
    SET_PAYMENT_METHODS(state, payload) {
      state.paymentMethods = payload
    },
    SET_COUNTRY(state, payload) {
      state.country = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_ME(state, payload) {
      state.me = payload
    },
    SET_TENANTS(state, payload) {
      state.tenants = payload
    },
    SET_CURRENT_TENANT(state, payload) {
      if (!payload) return
      state.currentTenant = payload
      client.gateway.setTenantUid(payload.uid)
      client.instance.setTenantUid(payload.uid)
      const instance = state.instances.find(
        (instance) => instance.uid === payload.instance_uid
      )
      if (!instance) return
      client.instance.setInstanceUrl(instance.url)
    },
    SET_CURRENT_QUOTAS(state, payload) {
      state.currentQuotas = payload
    },
    SET_QUOTA_ROLLOVER(state, payload) {
      state.quotaRollover = payload
    },
    SET_SELECTED_ORDER(state, payload) {
      state.selectedOrder = payload
    },
    SET_SELECTED_FORWARDED_ORDER(state, payload) {
      state.selectedForwardedOrder = payload
    },
    SET_FEATURES(state, payload) {
      state.features = payload
    }
  },

  actions: {
    setApplicationInitialized({ commit }, payload) {
      commit("SET_APPLICATION_INITIALIZED", payload)
    },
    setEntryRoute({ commit }, payload) {
      commit("SET_ENTRY_ROUTE", payload)
    },
    setIntrospection({ commit }, payload) {
      commit("SET_INTROSPECTION", payload)
    },
    setSubscription({ commit }, payload) {
      commit("SET_SUBSCRIPTION", payload)
    },
    setPlans({ commit }, payload) {
      commit("SET_PLANS", payload)
    },
    setInstances({ commit }, payload) {
      commit("SET_INSTANCES", payload)
    },
    setPaymentMethods({ commit }, payload) {
      commit("SET_PAYMENT_METHODS", payload)
    },
    setCountry({ commit }, payload) {
      commit("SET_COUNTRY", payload)
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload)
    },
    startLoading({ commit }) {
      commit("SET_LOADING", true)
    },
    setMe({ commit }, payload) {
      commit("SET_ME", payload)
    },
    stopLoading({ commit }) {
      commit("SET_LOADING", false)
    },
    setTenants({ commit }, payload) {
      commit("SET_TENANTS", payload)
    },
    setCurrentTenant({ commit }, payload) {
      commit("SET_CURRENT_TENANT", payload)
    },
    setCurrentQuotas({ commit }, payload) {
      commit("SET_CURRENT_QUOTAS", payload)
    },
    setQuotaRollover({ commit }, payload) {
      commit("SET_QUOTA_ROLLOVER", payload)
    },
    setSelectedOrder({ commit }, payload) {
      commit("SET_SELECTED_ORDER", payload)
    },
    setSelectedForwardedOrder({ commit }, payload) {
      commit("SET_SELECTED_FORWARDED_ORDER", payload)
    },
    setFeatures({ commit }, payload) {
      commit("SET_FEATURES", payload)
    }
  },

  modules: {}
})
