<template>
  <v-card flat class="card-bg rounded-20">
    <v-toolbar class="transparent" flat dense>
      <v-toolbar-title class="title-font">
        {{ currentTenant.name.toUpperCase() }} USERS</v-toolbar-title
      >
    </v-toolbar>
    <AuthorizedTenantUsers />
  </v-card>
</template>

<script>
import AuthorizedTenantUsers from "./AuthorizedUsers/AuthorizedTenantUsers.vue"

import { mapGetters } from "vuex"

export default {
  name: "AuthorizedUsers",

  components: {
    AuthorizedTenantUsers
  },

  computed: {
    ...mapGetters(["currentTenant"])
  }
}
</script>
