<template>
  <div class="text-center">
    <v-overlay :opacity="0.46" style="z-index: 999999999">
      <v-card
        @click="welcome = false"
        class="pa-3 rounded-20"
        color="white"
        width="600"
      >
        <v-card-title
          style="font-size: 24px"
          class="title-font primary--text font-weight-bold"
        >
          PROCESSING...
        </v-card-title>
        <v-card-text
          class="black--text font-weight-medium text-left"
          style="font-size: 16px"
        >
          Preparing your account, please wait a few moments...
        </v-card-text>
        <v-responsive
          class="mx-5 mb-3 d-flex align-center"
          style="height: 310px"
        >
          <v-progress-circular size="128" indeterminate color="black" />
        </v-responsive>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import client from "@/lib/ApiClient"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ActivationPage",

  computed: {
    ...mapGetters(["subscription"])
  },

  methods: {
    ...mapActions(["setSubscription", "setCurrentQuotas"]),
    async poll() {
      const subscriptionResponse =
        await client.gateway.billing.subscriptionInformation()
      if (
        subscriptionResponse?.subscription_status === "active" &&
        subscriptionResponse?.end_time
      ) {
        this.setSubscription(subscriptionResponse)
        const quotasResponse = await client.gateway.billing.fetchQuotas()
        this.setCurrentQuotas(quotasResponse)
        this.$router.push({ name: "inboxPage", params: { welcome: true } })
      } else this.poll()
    }
  },

  mounted() {
    if (this.subscription.subscription_status === "active")
      this.$router.push({ name: "inboxPage", params: { welcome: true } })
    else this.poll()
  }
}
</script>
