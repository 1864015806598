<template>
  <v-container fluid fill-height class="pt-0">
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      absolute
      :width="drawerWidth"
      class="card-bg"
    >
      <OrderView v-model="selectedCase" v-if="selectedCase" />
      <NewOrder @submission="handleNewOrder" v-if="newOrder" />
    </v-navigation-drawer>
    <v-row class="fill-height">
      <v-col cols="2" class="py-0">
        <v-card flat class="card-bg rounded-20">
          <v-card-text>
            <v-list dark dense class="transparent pt-0">
              <v-list-item
                style="background-color: #333f48"
                @click="
                  drawer = !drawer
                  newOrder = true
                "
              >
                <v-list-item-icon class="mr-4">
                  <v-icon> mdi-plus </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="'CREATE NEW ORDER'"
                    class="title-font font-weight-medium"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <InboxActions :inbox-items="inboxItems" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="10" class="py-0 pl-0">
        <InboxItems
          v-model="selectedCase"
          :inboxItems="actionedFilter"
          :loading="loading"
          :filter="filter"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="welcome" width="600px" content-class="rounded-20">
      <v-card @click="welcome = false" class="pa-3">
        <v-card-title
          style="font-size: 24px"
          class="title-font primary--text font-weight-bold"
        >
          CONGRATULATIONS!
        </v-card-title>
        <v-card-text
          class="black--text font-weight-medium"
          style="font-size: 16px"
        >
          Welcome to Nexus Connect Lab, Your Subscription is Now Ready!
        </v-card-text>
        <v-responsive class="mx-5 mb-3">
          <iframe
            width="100%"
            height="310"
            src="https://www.youtube.com/embed/yWKhXTYWVYY"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </v-responsive>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import InboxActions from "./InboxPage/InboxActions"
import InboxItems from "./InboxPage/InboxItems"
import OrderView from "./InboxPage/OrderView"
import NewOrder from "./InboxPage/NewOrder"
import client from "@/lib/ApiClient"
import { mapActions } from "vuex"

export default {
  name: "InboxPage",

  props: ["filter"],

  components: {
    InboxActions,
    InboxItems,
    OrderView,
    NewOrder
  },

  data() {
    return {
      welcome: this.$route.params.welcome || false,
      drawer: false,
      loading: false,
      inboxItems: [],
      newOrder: false,
      selectedCase: null
    }
  },

  watch: {
    selectedCase(value) {
      this.setSelectedOrder(value)
      if (!value) {
        this.fetchOrders()
        return
      }
      this.drawer = true
    },

    drawer(value) {
      if (!value) {
        this.selectedCase = null
        this.newOrder = false
      } else {
        this.$vuetify.goTo(0, { duration: 0 })
      }
    }
  },

  async mounted() {
    if (this.$route.params.welcome) this.sendConfetti()
    this.fetchOrders()
  },

  computed: {
    effectiveFilter() {
      return this.filter || "inbox"
    },

    actionedFilter() {
      if (this.effectiveFilter === "all") return this.inboxItems
      return this.inboxItems.filter((item) =>
        item.statuses.includes(this.effectiveFilter)
      )
    },

    drawerWidth() {
      if (this.selectedCase) {
        return "90%"
      } else {
        return "50%"
      }
    }
  },

  methods: {
    ...mapActions(["setSelectedOrder"]),

    async fetchOrders() {
      this.loading = true
      this.inboxItems = await client.instance.orders.getOrders()
      this.loading = false
    },

    async handleNewOrder() {
      this.inboxItems = await client.instance.orders.getOrders()
      this.drawer = false
    },

    sendConfetti() {
      this.$confetti.start()
      setTimeout(() => this.$confetti.stop(), 2000)
    }
  }
}
</script>
