<template>
  <v-btn
    width="100%"
    height="86"
    v-ripple
    outlined
    :color="dragOver ? 'primary' : ''"
    class="rounded-10 fill-height py-1"
    style="
      cursor: pointer;
      user-select: none;
      background-color: #f5f5f5;
      border-color: #bbbbbb;
      border-width: 2px;
    "
    @drop.prevent="onDrop($event)"
    @dragover.prevent="dragOver = true"
    @dragenter.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
    @click="addFile()"
    :disabled="disabled"
  >
    <div class="d-flex flex-column">
      <span>
        <v-icon class="mr-1">$upload</v-icon>
        UPLOAD
        <v-progress-circular
          indeterminate
          v-if="loading"
          class="ml-4 mt-n2"
          style="position: absolute"
        />
      </span>
      <v-card-text
        v-if="invalidFile || filesToUpload.length > 1"
        class="fill-width text-center pa-1 pt-2"
      >
        <v-chip class="error" small>
          <AlarmIcon :color="'#FFFFFF'" small />

          <span v-if="filesToUpload.length > 1"
            >Only one file can be uploaded.</span
          >
          <span v-if="invalidFile && fileExtension.length === 1"
            >File type must be .{{ fileExtension[0] }}</span
          >
          <span v-if="invalidFile && fileExtension.length > 1"
            >File type must be one of:
            <span v-for="(ext, index) in fileExtension" :key="index"
              ><span v-if="index !== 0">, </span>.{{ ext }}</span
            >
          </span>
        </v-chip>
      </v-card-text>
    </div>
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import AlarmIcon from "@/components/shared/icons/AlarmIcon"

export default {
  name: "FileUploader",

  props: ["text", "fileExtension", "value", "category", "disabled"],

  components: { AlarmIcon },

  data() {
    return {
      loading: false,
      dragOver: false,
      filesToUpload: [],
      filesUploaded: [],
      uploadProgress: 0,
      inputDOMElement: null,
      invalidFile: false
    }
  },

  computed: {
    ...mapGetters(["selectedOrder"]),
    uploaded: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    fileExtensionCheck(file) {
      let ext = file.name.toLowerCase().split(".").pop()
      return this.fileExtension.includes(ext)
    },

    async addFile() {
      this.filesToUpload = []
      this.invalidFile = false
      if (this.inputDOMElement) {
        this.inputDOMElement.value = ""
      } else {
        this.inputDOMElement = document.createElement("input")
        this.inputDOMElement.type = "file"
        this.inputDOMElement.multiple = false
        this.inputDOMElement.addEventListener("change", async (event) => {
          let files = [...event.target.files]
          files.forEach((entry) => {
            if (this.fileExtensionCheck(entry)) this.filesToUpload.push(entry)
            else this.invalidFile = true
          })
          const formData = new FormData()
          formData.append("file_type", "scan")
          formData.append("file_category", this.category)
          this.filesToUpload.forEach((file) => {
            formData.append("files", file, file.name)
          })
          if (this.filesToUpload.length > 1) return
          this.loading = true
          const response = await client.instance.orders.uploadFiles(
            this.selectedOrder.uid,
            formData
          )
          this.uploaded = response
          this.$emit("uploaded", response.data)
          this.loading = false
        })
      }
      this.inputDOMElement.click()
    },

    async onDrop(event) {
      this.dragOver = false
      this.filesToUpload = []
      this.invalidFile = false
      ;[...event.dataTransfer.files].forEach((element) => {
        if (this.fileExtensionCheck(element)) this.filesToUpload.push(element)
        else this.invalidFile = true
      })
      const formData = new FormData()
      formData.append("file_type", "scan")
      formData.append("file_category", this.category)
      this.filesToUpload.forEach((file) => {
        formData.append("files", file, file.name)
      })
      if (this.filesToUpload.length > 1) return
      this.loading = true
      const response = await client.instance.orders.uploadFiles(
        this.selectedOrder.uid,
        formData
      )
      this.uploaded = response
      this.$emit("uploaded", response.data)
      this.loading = false
    }
  }
}
</script>
