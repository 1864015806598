<template>
  <ExportAbutment
    :order="order"
    :scan-body-libraries="scanBodyLibraries"
    @cancelled="cancel"
    @download="downloadFile"
  />
</template>

<script>
import ExportAbutment from "./ExportComponent/ExportAbutment"

export default {
  name: "ExportComponent",

  props: ["order", "scanBodyLibraries"],

  components: { ExportAbutment },

  methods: {
    cancel() {
      this.$emit("cancelled")
    },

    downloadFile() {
      this.$emit("download")
    }
  }
}
</script>
