<template>
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6429 5.94482L19.8265 15.7532L19.1225 16.9769H2.64449L2.17369 15.7532L3.57729 5.94482H18.6385M18.6385 4.56982H3.57729C2.92169 4.56982 2.36729 5.06941 2.27049 5.74316L0.866892 15.5515C0.831692 15.7944 0.858092 16.0373 0.946092 16.2665L1.41689 17.4902C1.61929 18.0127 2.10329 18.3565 2.64449 18.3565H19.1181C19.5801 18.3565 20.0113 18.1044 20.2489 17.6873L20.9529 16.4636C21.1069 16.1977 21.1685 15.8907 21.1289 15.5836L19.9453 5.77524C19.8617 5.08774 19.3029 4.56982 18.6341 4.56982H18.6385Z"
      :fill="color"
    />
    <path d="M19.8264 15.29H2.17358V16.2067H19.8264V15.29Z" :fill="color" />
    <path
      d="M4.58484 8.86412C4.58484 8.86412 4.55844 8.86412 4.54524 8.86412C4.36484 8.84121 4.23284 8.67162 4.25484 8.48371L4.36924 7.45246C4.39124 7.26454 4.54964 7.12704 4.73444 7.14996C4.91484 7.17287 5.04684 7.34246 5.02484 7.53037L4.91044 8.56162C4.89284 8.73579 4.74764 8.86412 4.58484 8.86412Z"
      :fill="color"
    />
    <path
      d="M4.3385 11.3345C4.3385 11.3345 4.3121 11.3345 4.2989 11.3345C4.1185 11.3116 3.9865 11.142 4.0085 10.9541L4.1625 9.57453C4.1845 9.38661 4.3429 9.24911 4.5277 9.27203C4.7081 9.29494 4.8401 9.46453 4.8181 9.65244L4.6641 11.032C4.6465 11.2062 4.5013 11.3345 4.3385 11.3345Z"
      :fill="color"
    />
    <path
      d="M4.05676 14.3779C4.05676 14.3779 4.03036 14.3779 4.01716 14.3779C3.83676 14.355 3.70476 14.1854 3.72676 13.9975L3.94236 12.0633C3.96436 11.8754 4.12276 11.7379 4.30756 11.7608C4.48796 11.7837 4.61996 11.9533 4.59796 12.1412L4.38236 14.0754C4.36476 14.2495 4.21956 14.3779 4.05676 14.3779Z"
      :fill="color"
    />
    <path
      d="M5.99719 8.86412C5.99719 8.86412 5.97079 8.86412 5.95759 8.86412C5.77719 8.84121 5.64519 8.67162 5.66719 8.48371L5.78159 7.45246C5.80359 7.26454 5.96199 7.12704 6.14679 7.14996C6.32719 7.17287 6.45919 7.34246 6.43719 7.53037L6.32279 8.56162C6.30519 8.73579 6.15999 8.86412 5.99719 8.86412Z"
      :fill="color"
    />
    <path
      d="M5.75085 11.3345C5.75085 11.3345 5.72445 11.3345 5.71125 11.3345C5.53085 11.3116 5.39885 11.142 5.42085 10.9541L5.57485 9.57453C5.59685 9.38661 5.75525 9.24911 5.94005 9.27203C6.12045 9.29494 6.25245 9.46453 6.23045 9.65244L6.07645 11.032C6.05885 11.2062 5.91365 11.3345 5.75085 11.3345Z"
      :fill="color"
    />
    <path
      d="M5.46912 14.3779C5.46912 14.3779 5.44272 14.3779 5.42952 14.3779C5.24912 14.355 5.11712 14.1854 5.13912 13.9975L5.35472 12.0633C5.37672 11.8754 5.53512 11.7379 5.71992 11.7608C5.90032 11.7837 6.03232 11.9533 6.01032 12.1412L5.79472 14.0754C5.77712 14.2495 5.63192 14.3779 5.46912 14.3779Z"
      :fill="color"
    />
    <path
      d="M7.40955 8.86412C7.40955 8.86412 7.38315 8.86412 7.36995 8.86412C7.18955 8.84121 7.05755 8.67162 7.07955 8.48371L7.19395 7.45246C7.21595 7.26454 7.37435 7.12704 7.55915 7.14996C7.73955 7.17287 7.87155 7.34246 7.84955 7.53037L7.73515 8.56162C7.71755 8.73579 7.57235 8.86412 7.40955 8.86412Z"
      :fill="color"
    />
    <path
      d="M7.16321 11.3345C7.16321 11.3345 7.13681 11.3345 7.12361 11.3345C6.94321 11.3116 6.81121 11.142 6.83321 10.9541L6.98721 9.57453C7.00921 9.38661 7.16761 9.24911 7.35241 9.27203C7.53281 9.29494 7.66481 9.46453 7.64281 9.65244L7.48881 11.032C7.47121 11.2062 7.32601 11.3345 7.16321 11.3345Z"
      :fill="color"
    />
    <path
      d="M6.88171 14.3779C6.88171 14.3779 6.85531 14.3779 6.84211 14.3779C6.66171 14.355 6.52971 14.1854 6.55171 13.9975L6.76731 12.0633C6.78931 11.8754 6.94771 11.7379 7.13251 11.7608C7.31291 11.7837 7.44491 11.9533 7.42291 12.1412L7.20731 14.0754C7.18971 14.2495 7.04451 14.3779 6.88171 14.3779Z"
      :fill="color"
    />
    <path
      d="M17.3491 8.86412C17.1819 8.86412 17.0411 8.73579 17.0235 8.56162L16.9091 7.53037C16.8871 7.34246 17.0191 7.17287 17.1995 7.14996C17.3843 7.12704 17.5427 7.26454 17.5647 7.45246L17.6791 8.48371C17.7011 8.67162 17.5691 8.84121 17.3887 8.86412C17.3755 8.86412 17.3623 8.86412 17.3491 8.86412Z"
      :fill="color"
    />
    <path
      d="M17.5955 11.3345C17.4283 11.3345 17.2875 11.2062 17.2699 11.032L17.1159 9.65244C17.0939 9.46453 17.2259 9.29494 17.4063 9.27203C17.5911 9.24911 17.7495 9.38661 17.7715 9.57453L17.9255 10.9541C17.9475 11.142 17.8155 11.3116 17.6351 11.3345C17.6219 11.3345 17.6087 11.3345 17.5955 11.3345Z"
      :fill="color"
    />
    <path
      d="M17.8815 14.3779C17.7143 14.3779 17.5735 14.2495 17.5559 14.0754L17.3403 12.1412C17.3183 11.9533 17.4503 11.7837 17.6307 11.7608C17.8155 11.7379 17.9739 11.8754 17.9959 12.0633L18.2115 13.9975C18.2335 14.1854 18.1015 14.355 17.9211 14.3779C17.9079 14.3779 17.8947 14.3779 17.8815 14.3779Z"
      :fill="color"
    />
    <path
      d="M15.9368 8.86412C15.7696 8.86412 15.6288 8.73579 15.6112 8.56162L15.4968 7.53037C15.4748 7.34246 15.6068 7.17287 15.7872 7.14996C15.972 7.12704 16.1304 7.26454 16.1524 7.45246L16.2668 8.48371C16.2888 8.67162 16.1568 8.84121 15.9764 8.86412C15.9632 8.86412 15.95 8.86412 15.9368 8.86412Z"
      :fill="color"
    />
    <path
      d="M16.1832 11.3345C16.016 11.3345 15.8752 11.2062 15.8576 11.032L15.7036 9.65244C15.6816 9.46453 15.8136 9.29494 15.994 9.27203C16.1788 9.24911 16.3372 9.38661 16.3592 9.57453L16.5132 10.9541C16.5352 11.142 16.4032 11.3116 16.2228 11.3345C16.2096 11.3345 16.1964 11.3345 16.1832 11.3345Z"
      :fill="color"
    />
    <path
      d="M16.4691 14.3779C16.3019 14.3779 16.1611 14.2495 16.1435 14.0754L15.9279 12.1412C15.9059 11.9533 16.0379 11.7837 16.2183 11.7608C16.4031 11.7379 16.5615 11.8754 16.5835 12.0633L16.7991 13.9975C16.8211 14.1854 16.6891 14.355 16.5087 14.3779C16.4955 14.3779 16.4823 14.3779 16.4691 14.3779Z"
      :fill="color"
    />
    <path
      d="M14.5244 8.86412C14.3572 8.86412 14.2164 8.73579 14.1988 8.56162L14.0844 7.53037C14.0624 7.34246 14.1944 7.17287 14.3748 7.14996C14.5596 7.12704 14.718 7.26454 14.74 7.45246L14.8544 8.48371C14.8764 8.67162 14.7444 8.84121 14.564 8.86412C14.5508 8.86412 14.5376 8.86412 14.5244 8.86412Z"
      :fill="color"
    />
    <path
      d="M14.7752 11.3345C14.608 11.3345 14.4672 11.2062 14.4496 11.032L14.2956 9.65244C14.2736 9.46453 14.4056 9.29494 14.586 9.27203C14.7708 9.24911 14.9292 9.38661 14.9512 9.57453L15.1052 10.9541C15.1272 11.142 14.9952 11.3116 14.8148 11.3345C14.8016 11.3345 14.7884 11.3345 14.7752 11.3345Z"
      :fill="color"
    />
    <path
      d="M15.0568 14.3779C14.8896 14.3779 14.7488 14.2495 14.7312 14.0754L14.5156 12.1412C14.4936 11.9533 14.6256 11.7837 14.806 11.7608C14.9908 11.7379 15.1492 11.8754 15.1712 12.0633L15.3868 13.9975C15.4088 14.1854 15.2768 14.355 15.0964 14.3779C15.0832 14.3779 15.07 14.3779 15.0568 14.3779Z"
      :fill="color"
    />
    <path
      d="M9.72396 13.5161H9.39396C9.21356 13.5161 9.06396 13.3603 9.06396 13.1724C9.06396 12.9844 9.21356 12.8286 9.39396 12.8286H9.72396C9.90436 12.8286 10.054 12.9844 10.054 13.1724C10.054 13.3603 9.90436 13.5161 9.72396 13.5161Z"
      :fill="color"
    />
    <path
      d="M11.4796 13.5161H11.4004C11.22 13.5161 11.0704 13.3603 11.0704 13.1724C11.0704 12.9844 11.22 12.8286 11.4004 12.8286H11.4796C11.66 12.8286 11.8096 12.9844 11.8096 13.1724C11.8096 13.3603 11.66 13.5161 11.4796 13.5161ZM10.604 13.5161H10.5248C10.3444 13.5161 10.1948 13.3603 10.1948 13.1724C10.1948 12.9844 10.3444 12.8286 10.5248 12.8286H10.604C10.7844 12.8286 10.934 12.9844 10.934 13.1724C10.934 13.3603 10.7844 13.5161 10.604 13.5161Z"
      :fill="color"
    />
    <path
      d="M12.606 13.5161H12.276C12.0956 13.5161 11.946 13.3603 11.946 13.1724C11.946 12.9844 12.0956 12.8286 12.276 12.8286H12.606C12.7864 12.8286 12.936 12.9844 12.936 13.1724C12.936 13.3603 12.7864 13.5161 12.606 13.5161Z"
      :fill="color"
    />
    <path
      d="M10.0275 11.8022H9.80754C9.62714 11.8022 9.47754 11.6464 9.47754 11.4585C9.47754 11.2706 9.62714 11.1147 9.80754 11.1147H10.0275C10.2079 11.1147 10.3575 11.2706 10.3575 11.4585C10.3575 11.6464 10.2079 11.8022 10.0275 11.8022Z"
      :fill="color"
    />
    <path
      d="M11.0572 11.8022H10.9428C10.7624 11.8022 10.6128 11.6464 10.6128 11.4585C10.6128 11.2706 10.7624 11.1147 10.9428 11.1147H11.0572C11.2376 11.1147 11.3872 11.2706 11.3872 11.4585C11.3872 11.6464 11.2376 11.8022 11.0572 11.8022Z"
      :fill="color"
    />
    <path
      d="M12.1923 11.8022H11.9723C11.7919 11.8022 11.6423 11.6464 11.6423 11.4585C11.6423 11.2706 11.7919 11.1147 11.9723 11.1147H12.1923C12.3727 11.1147 12.5223 11.2706 12.5223 11.4585C12.5223 11.6464 12.3727 11.8022 12.1923 11.8022Z"
      :fill="color"
    />
    <path
      d="M13.0416 10.0786H8.95837C8.83957 10.0786 8.72077 10.0282 8.64157 9.9365C8.55797 9.84484 8.51397 9.72109 8.51837 9.59734L8.59757 7.88317C8.61077 7.64025 8.80437 7.44775 9.03757 7.44775H12.958C13.1912 7.44775 13.3848 7.64025 13.398 7.88317L13.4772 9.59734C13.4816 9.72109 13.4376 9.84484 13.354 9.9365C13.2704 10.0282 13.156 10.0786 13.0372 10.0786H13.0416ZM9.42037 9.16192H12.5796L12.5444 8.36442H9.45997L9.42477 9.16192H9.42037Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "ScanGaugeKit",
  props: {
    color: {
      type: String,
      default: "#333F48"
    }
  }
}
</script>
