<template>
  <v-card flat class="card-bg rounded-20" v-if="subscription">
    <v-toolbar class="transparent mb-3" flat dense>
      <v-toolbar-title class="title-font"> PLAN & BILLING </v-toolbar-title>
    </v-toolbar>
    <v-card flat class="mx-4 rounded-10">
      <v-row>
        <v-col>
          <v-list>
            <v-list-item v-for="(item, index) in details" :key="index" two-line>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.key.toUpperCase() }}:
                </v-list-item-title>
                <v-list-item-subtitle class="font-weight-bold black--text">
                  {{ item.value.toUpperCase() }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <v-card-actions class="ma-2">
      <v-spacer></v-spacer>
      <v-btn @click="dialog = true" class="btn-secondary" text>
        Cancel Subscription
      </v-btn>
    </v-card-actions>
    <CancelDialog v-model="dialog" />
  </v-card>
</template>

<script>
import { capitalise } from "@/utils"
import { mapGetters } from "vuex"
import CancelDialog from "./SubscriptionInfo/CancelDialog"

export default {
  name: "SubscriptionInfo",
  components: {
    CancelDialog
  },

  data() {
    return {
      dialog: false
    }
  },

  computed: {
    ...mapGetters(["subscription"]),

    details() {
      return [
        {
          key: "Current Plan",
          value: this.subscription.plan_code
        },
        {
          key: "Subscription Status",
          value: capitalise(this.subscription.subscription_status)
        },
        {
          key: "Renew Frequency",
          value: capitalise(this.subscription.renewal_frequency)
        },
        {
          key: "Next Payment Due",
          value: new Date(this.subscription.end_time).toLocaleDateString()
        }
      ]
    }
  }
}
</script>
