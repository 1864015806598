<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <v-icon class="mr-2">mdi-database</v-icon>
      Where do you want to store your data?
    </v-card-title>
    <v-card-text>
      <v-list dense>
        <v-list-item-group
          v-model="selectedInstance"
          active-class="primary white--text rounded-10"
          v-for="(dataStorage, index) in instances"
          :key="index"
        >
          <v-list-item :value="dataStorage.uid">
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center">
                <CountryFlag
                  :country="dataStorage.country_code"
                  style="margin-bottom: 1px"
                />
                <span class="ml-3">
                  {{ dataStorage.name }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from "vue-country-flag"
import { mapGetters } from "vuex"

export default {
  name: "DataStorageSelector",

  props: ["value"],

  components: {
    CountryFlag
  },

  data() {
    return {
      dataStorages: []
    }
  },

  computed: {
    ...mapGetters(["instances"]),
    selectedInstance: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  }
}
</script>
