<template>
  <v-card flat class="card-bg">
    <v-card flat class="ma-4 rounded-20">
      <v-card-title class="title-font"> ORDER FORM </v-card-title>
    </v-card>
    <v-card flat class="ma-4 rounded-20">
      <v-form ref="orderForm">
        <v-card-text>
          <v-autocomplete
            v-model="form.client_uid"
            :items="clientItems"
            outlined
            dense
            label="Client (Required)"
            :rules="rule"
            :menu-props="{ rounded: '10' }"
          />
          <v-text-field
            v-model="form.patient_reference"
            outlined
            dense
            label="Patient Reference (Required)"
            :rules="rule"
          />
          <v-autocomplete
            v-model="form.scan_gauge_kit"
            :items="scanGaugeKitItems"
            outlined
            dense
            label="Scan Gauge Kit (Required)"
            :rules="rule"
            :menu-props="{ rounded: '10' }"
          />
          <v-card flat>
            <v-card-title class="px-0">Upload Files</v-card-title>
            <v-card-text class="px-0">
              <v-row>
                <v-col cols="4">
                  <v-file-input
                    v-model="scanFileUploads"
                    dense
                    outlined
                    multiple
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    hide-details
                    label="Scan File"
                    accept=".stl, .ply"
                  />
                  <v-chip
                    small
                    v-for="(file, index) in scanFileUploads"
                    :key="index"
                    close
                    class="ma-1 rounded-10"
                    @click:close="removeFile(scanFileUploads, index)"
                  >
                    <v-icon small>mdi-paperclip</v-icon>
                    <span class="text-truncate">
                      {{ file.name }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col cols="4">
                  <v-file-input
                    v-model="photoFileUploads"
                    dense
                    outlined
                    multiple
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    hide-details
                    label="Photo File"
                    accept=".png, .jpg, .jpeg, .svg"
                  />
                  <v-chip
                    small
                    v-for="(file, index) in photoFileUploads"
                    :key="index"
                    close
                    class="ma-1 rounded-10"
                    @click:close="removeFile(photoFileUploads, index)"
                  >
                    <v-icon small>mdi-paperclip</v-icon>
                    <span class="text-truncate">
                      {{ file.name }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col cols="4">
                  <v-file-input
                    v-model="otherFileUploads"
                    dense
                    outlined
                    multiple
                    prepend-icon=""
                    prepend-inner-icon="mdi-paperclip"
                    hide-details
                    label="Other File"
                  />
                  <v-chip
                    small
                    v-for="(file, index) in otherFileUploads"
                    :key="index"
                    close
                    class="ma-1 rounded-10"
                    @click:close="removeFile(otherFileUploads, index)"
                  >
                    <v-icon small>mdi-paperclip</v-icon>
                    <span class="text-truncate">
                      {{ file.name }}
                    </span>
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card flat>
            <v-card-text
              v-if="initialising && !questions.length"
              class="pa-5 text-center"
            >
              <v-progress-circular indeterminate />
            </v-card-text>
            <v-card-text v-if="!initialising && !questions.length" class="pa-5">
              There are no Questions for this Order Form
            </v-card-text>

            <v-card-text v-if="questions.length" class="pa-0">
              <v-card
                class="rounded-10"
                v-for="(question, index) in questions"
                :key="index"
                flat
              >
                <v-card-title
                  :class="['mx-0', 'px-0', { 'pt-0': index === 0 }]"
                >
                  <v-icon small v-if="question.mandatory" class="mr-1">
                  </v-icon>
                  {{ question.name }}
                </v-card-title>

                <v-card-subtitle>
                  {{ question.description }}
                </v-card-subtitle>

                <v-text-field
                  v-if="question.widget === 'text_field'"
                  v-model="form.responses[index].value"
                  :label="`Answer ${question.mandatory ? '(Required)' : ''}`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="question.mandatory ? rule : []"
                />
                <v-textarea
                  v-if="question.widget === 'text_area'"
                  v-model="form.responses[index].value"
                  label="Answer"
                  rows="1"
                  hide-details="auto"
                  auto-grow
                  outlined
                  dense
                  :rules="question.mandatory ? rule : []"
                />
                <v-select
                  v-if="question.widget === 'drop_down_picker'"
                  v-model="form.responses[index].value"
                  label="Answer"
                  :items="availableResponses(question)"
                  outlined
                  dense
                  hide-details="auto"
                  :menu-props="{ rounded: '10' }"
                  :rules="question.mandatory ? rule : []"
                />
                <v-card
                  v-if="question.widget === 'tooth_picker'"
                  style="max-width: fit-content"
                  flat
                >
                  <MultiUnitConfirmation
                    v-model="form.responses[index].value"
                    :responses="availableResponses(question)"
                    :interactive="true"
                  />
                </v-card>
                <DatetimePicker v-if="question.widget === 'datetime_picker'" />
              </v-card>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            text
            class="ma-2 btn-primary rounded-10"
            @click="createOrder"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-card>
</template>

<script>
import DatetimePicker from "../shared/Widgets/DatetimePicker"
import client from "@/lib/ApiClient"
import MultiUnitConfirmation from "../shared/MultiUnitConfirmation"

export default {
  name: "NewOrder",

  components: { DatetimePicker, MultiUnitConfirmation },

  data() {
    return {
      initialising: false,
      loading: false,
      questions: [],
      clients: [],
      scanGaugeKits: [],
      form: {
        responses: []
      },
      scanFileUploads: [],
      photoFileUploads: [],
      otherFileUploads: [],
      rule: [(v) => !!v || "Required"],
      internationalToothConvention: "fdi"
    }
  },

  async mounted() {
    this.initialising = true
    this.questions = await client.instance.orders.getQuestions()
    this.questions.forEach((question, index) => {
      this.form.responses.push({
        order: index + 1,
        name: question.name,
        description: question.description,
        widget: question.widget,
        value: question.widget === "tooth_picker" ? [] : ""
      })
    })
    this.clients = await client.instance.clients.fetchClients()
    this.scanGaugeKits = await client.gateway.gaugeKits.fetchScanGaugeKits()
    this.initialising = false
  },

  computed: {
    clientItems() {
      return this.clients.map((client) => {
        return {
          text: client.name,
          value: client.uid
        }
      })
    },

    scanGaugeKitItems() {
      return this.scanGaugeKits.map((scanGaugeKit) => {
        return {
          text: scanGaugeKit.kit_box_id,
          value: scanGaugeKit.uid
        }
      })
    }
  },

  methods: {
    availableResponses(question) {
      return question.metadata?.responses?.map((response) => {
        return {
          text: response.name,
          value: response.name
        }
      })
    },

    async createOrder() {
      if (this.$refs.orderForm.validate()) {
        this.loading = true
        const submitted_at = new Date().toISOString()
        await client.instance.orders
          .createOrder({ ...this.form, submitted_at })
          .then(async (order) => {
            await this.uploadFiles(order.uid, this.scanFileUploads, "scan")
            await this.uploadFiles(order.uid, this.photoFileUploads, "photo")
            await this.uploadFiles(order.uid, this.otherFileUploads, "other")
          })
        this.loading = false
        this.$emit("submission")
      }
    },

    async uploadFiles(orderUid, files, fileType) {
      const formData = new FormData()
      formData.append("file_type", fileType)
      files.forEach((file) => {
        formData.append("files", file, file.name)
      })
      await client.instance.orders.uploadFiles(orderUid, formData)
    },

    removeFile(files, index) {
      files.splice(index, 1)
    }
  }
}
</script>
