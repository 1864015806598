<template>
  <v-row>
    <v-col cols="12">
      <v-expansion-panels accordion flat>
        <v-expansion-panel class="btn-primary">
          <v-expansion-panel-header>
            View 3D Design{{ approvalFiles.length > 1 ? "s" : "" }} ({{
              approvalFiles.length
            }})
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul style="padding-left: 0; list-style-type: none">
              <li v-for="(file, index) in approvalFiles" :key="index">
                <v-chip
                  class="mb-1 rounded-10 primary--text"
                  small
                  style="width: 100%"
                  @click="viewFile(file)"
                >
                  <v-icon small>mdi-paperclip</v-icon>
                  {{ cleanFilename(file) }}
                </v-chip>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <FileViewer v-model="file" />
  </v-row>
</template>

<script>
import FileViewer from "@/components/shared/FileViewer.vue"

export default {
  props: ["approvalFiles"],

  components: { FileViewer },

  data() {
    return {
      dialog: false,
      file: null
    }
  },

  methods: {
    viewFile(file) {
      this.file = file
    },

    fileType(file) {
      if (!file?.filename) {
        return
      }
      const fileExt = file.filename.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "html":
          return "html"
        case "jpg":
        case "jpeg":
        case "png":
          return "image"
        case "ply":
        case "stl":
          return "model"
        default:
          return "download"
      }
    },

    cleanFilename(file) {
      return file.filename.slice(7)
    }
  }
}
</script>
