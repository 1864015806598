<template>
  <v-card class="rounded-20 mr-4" height="100%" flat>
    <v-card-title class="title-font">UPLOAD PROJECT FILES</v-card-title>
    <v-card-text class="black--text">
      If your tooth design has been completed, upload each file in the relevant
      upload box:
    </v-card-text>
    <v-row class="ma-2">
      <v-card outlined class="mx-2 rounded-10">
        <v-expansion-panels accordion tile flat v-model="openIndex">
          <v-expansion-panel
            v-for="([key, value], index) in Object.entries(files)"
            :key="key"
          >
            <v-expansion-panel-header
              :class="['black--text', 'title-font', value ? 'py-0' : '']"
              style="font-size: 16px"
            >
              <v-icon
                v-if="!value"
                small
                color="error"
                class="mr-5 flex-grow-0"
              >
                mdi-close-circle
              </v-icon>
              <v-icon
                v-if="value"
                small
                color="success"
                class="mr-5 flex-grow-0"
              >
                mdi-check-circle
              </v-icon>
              <span>
                {{ categories[key].title }}
              </span>
              <div
                @click="(e) => e.stopImmediatePropagation()"
                class="flex-grow-0"
              >
                <ModelViewer
                  v-if="value"
                  :style="
                    !isOpen(index)
                      ? 'visibility: visible'
                      : 'visibility: hidden'
                  "
                  :filename="value.filename"
                  :url="value.url"
                  :height="40"
                  :width="40"
                  :camera-positions="{ x: 0, y: 70, z: 0 }"
                />
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="elevation-0">
                <div v-if="!value">
                  <div v-if="key !== 'abutmentAnalogPositions'">
                    <v-card-text
                      v-if="fileExistForCategory(categories[key].value)"
                    >
                      <v-btn
                        class="elevation-0 rounded-10"
                        width="100%"
                        height="86"
                        style="border: 2px rgb(187, 187, 187) solid"
                        @click="categories[key].dialog = true"
                        :disabled="initialising"
                      >
                        Choose Existing
                      </v-btn>
                      <v-dialog v-model="categories[key].dialog" width="90vw">
                        <v-card>
                          <ExistingFileSelector
                            :category="categories[key]"
                            :files="existingFiles"
                            @selection="handleSelection"
                          />
                        </v-card>
                      </v-dialog>
                    </v-card-text>
                    <div
                      class="d-flex align-center px-5"
                      v-if="fileExistForCategory(categories[key].value)"
                    >
                      <v-divider /> <span class="text-caption mx-5"> OR </span>
                      <v-divider />
                    </div>
                    <v-card-text>
                      <FileUploader
                        v-if="!value"
                        :text="categories[key].title"
                        :fileExtension="['stl', 'ply']"
                        :category="categories[key].value"
                        @uploaded="handleUploaded"
                      />
                    </v-card-text>
                  </div>
                  <div v-if="key === 'abutmentAnalogPositions'">
                    <v-select
                      v-model="files[key]"
                      label="Select Abutment Export"
                      outlined
                      dense
                      :items="filteredScanBodyLibraries"
                    />
                  </div>
                </div>
                <ModelViewer
                  v-if="value"
                  :filename="value.filename"
                  :url="value.url"
                  :height="150"
                  :camera-positions="{ x: 0, y: 70, z: 0 }"
                />
                <v-btn
                  v-if="value"
                  small
                  text
                  class="elevation-0 flex-grow-0 ml-4 mt-n10"
                  @click.stop="handleClear(key)"
                  style="position: absolute; right: 0"
                >
                  Clear
                </v-btn>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-row>
    <v-card-text class="black--text">
      Add any case related comments below:
    </v-card-text>
    <v-textarea
      label="Special Requests"
      full-width
      outlined
      class="mx-4"
      hide-details
    />
    <v-card-actions>
      <v-btn
        class="btn-primary white--text my-4 mx-2"
        @click="handleSubmission"
      >
        Submit
      </v-btn>
      <v-btn class="rounded-10" elevation="0" @click="$emit('step', 1)">
        Cancel
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog" width="30vw" content-class="rounded-20">
      <PurchaseCard
        v-model="dialog"
        :item="lineItem"
        :acquisitionMethod="acquisitionMethod"
        :loading="loading"
        @confirm="handleConfirm"
      />
    </v-dialog>
  </v-card>
</template>

<style scoped>
.active.clear {
  visibility: visible;
}
</style>

<script>
import { mapGetters, mapActions } from "vuex"
import client from "@/lib/ApiClient"
import FileUploader from "@/components/shared/FileUploader"
import ModelViewer from "@/components/shared/ModelViewer.vue"
import ExistingFileSelector from "@/components/shared/ExistingFileSelector.vue"
import PurchaseCard from "@/components/shared/PurchaseCard.vue"
import { toCamelCase, toSnakeCase, scanBodyLibraries } from "@/utils"

export default {
  name: "UploadProjectFiles",

  props: ["value", "options"],

  components: { FileUploader, ModelViewer, ExistingFileSelector, PurchaseCard },

  data() {
    return {
      loading: false,
      initialising: false,
      dialog: false,
      acquisitionStrategy: null,
      openIndex: 0,
      existingFiles: [],
      historyExports: [],
      scanBodyLibraries: scanBodyLibraries,

      categories: {
        antagonistDentition: {
          title: "Antagonist Dentition",
          subtitle: "",
          value: "antagonist_dentition",
          dialog: false
        },

        toothDesign: {
          title: "Tooth Design",
          subtitle: "",
          value: "tooth_design",
          dialog: false
        },
        tissueOfRestoringArch: {
          title: "Tissue of Restoring Arch",
          subtitle: "",
          value: "tissue_of_restoring_arch",
          dialog: false
        },
        abutmentAnalogPositions: {
          title: "Abutment Analog Positions",
          subtitle: "",
          value: "abutment_analog_positions",
          dialog: false
        }
      },
      lineItem: {
        description: "Milled Bar",
        amount: "2 000",
        currency: "AUD",
        quantity: 1,
        product_code: "BAR",
        quota_code: "bars"
      },
      toSnakeCase
    }
  },

  watch: {
    abutmentPositions(value) {
      if (value) this.openNext()
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "selectedForwardedOrder"]),

    files: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },
    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "BAR")
        .method
    },
    filteredScanBodyLibraries() {
      return this.historyExports.map((ex) =>
        scanBodyLibraries.find((lib) => lib.value === ex.library)
      )
    },
    abutmentPositions() {
      return this.files.abutmentAnalogPositions
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas"]),
    openNext() {
      for (let index = 0; index < Object.values(this.files).length; index++) {
        if (Object.values(this.files)[index] === null)
          return (this.openIndex = index)
      }
      return (this.openIndex = null)
    },
    handleUploaded(uploaded) {
      uploaded.forEach((upload) => {
        this.files[toCamelCase(upload.file_category)] = upload
      })
      this.openNext()
    },
    handleSelection(file, file_category) {
      const category = toCamelCase(file_category)
      this.files[category] = file
      this.categories[category].dialog = false
      this.openNext()
    },
    handleClear(category) {
      this.files[category] = null
    },
    isOpen(index) {
      return index === this.openIndex
    },
    async fetchOrderFiles() {
      this.initialising = true
      this.existingFiles = await client.instance.orders.fetchOrderFiles(
        this.selectedOrder.uid
      )

      this.initialising = false
    },
    fileExistForCategory(category) {
      if (this.initialising) return true
      // Uncategorised or Categorised files exist for Category
      return this.existingFiles.some((f) =>
        ["", category].includes(f.file_category)
      )
    },
    async handleSubmission() {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
        purchaseForm
      )
      this.loading = false
      this.dialog = true
    },
    async submitForwardedOrder(token) {
      const form = {
        token: token,
        product: "BAR",
        step: 3,
        order_form: this.options,
        submitted_at: new Date().toISOString()
      }
      await client.instance.orders.updateForwardedOrder(
        this.selectedOrder.uid,
        this.selectedForwardedOrder.uid,
        form
      )
    },
    checkTokenActivation(token) {
      if (!token) return false
      const activated = token.product_codes.find(
        (p) => p.product_code === "BAR"
      ).activated

      return activated > 0 ? true : false
    },

    async pollTokenActivation(token) {
      const polledToken = await client.gateway.billing.checkToken(token.uid)
      const activated = this.checkTokenActivation(polledToken)
      if (!activated)
        return setTimeout(this.pollTokenActivation, 500, polledToken)
      else await this.submitForwardedOrder(token)
    },
    async handleConfirm() {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      const token = await client.gateway.billing.commitPurchase(purchaseForm)
      if (!this.checkTokenActivation(token))
        await this.pollTokenActivation(token)
      else await this.submitForwardedOrder(token)

      if (this.acquisitionMethod === "quota") {
        const quotasResponse = await client.gateway.billing.fetchQuotas()
        this.setCurrentQuotas(quotasResponse)
      }
      this.loading = false
      this.dialog = false
      this.$emit("step", 3)
    },

    async fetchHistoryExports() {
      this.loading = true
      this.historyExports = await client.instance.orders.fetchHistoryExports(
        this.selectedOrder.uid
      )
      this.historyExports.sort((a, b) =>
        b.created_at.localeCompare(a.created_at)
      )
      this.loading = false
    }
  },

  mounted() {
    this.fetchOrderFiles()
    this.fetchHistoryExports()
  }
}
</script>
