<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-form ref="basicInfoForm" v-model="valid" lazy-validation>
        <v-text-field
          v-model="form.firstName"
          required
          :rules="rules"
          outlined
          dense
          label="First name"
        />
        <v-text-field
          v-model="form.lastName"
          required
          :rules="rules"
          outlined
          dense
          label="Last name"
        />
        <v-text-field
          v-model="form.email"
          required
          :rules="[...rules, ...emailRule]"
          outlined
          dense
          type="email"
          label="Email"
          :disabled="idString === 'email'"
        />
        <vue-tel-input-vuetify
          @input="handlePhone"
          v-model="form.phone"
          outlined
          label="Phone"
          dense
          :rules="[...rules, ...phoneRule]"
          :disabled="idString === 'phone'"
          :defaultCountry="country"
          :disabledFetchingCountry="true"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style>
.vue-tel-input-vuetify .country-code {
  width: 45px;
  margin-right: 1em;
}
</style>

<script>
import { mapGetters } from "vuex"

export default {
  name: "BasicInformation",

  props: ["value", "idString"],

  data() {
    return {
      valid: true,
      rules: [(v) => !!v || "Required"],
      emailRule: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) ||
          "E-mail must be valid"
      ],
      phoneRule: [() => this.phoneValid || "This phone number is invalid"],
      phoneValid: false
    }
  },

  computed: {
    ...mapGetters(["country"]),

    form: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  methods: {
    validateForm() {
      return this.$refs.basicInfoForm.validate()
    },

    handlePhone(input, { number, valid }) {
      this.form.phone = number.international || ""
      this.phoneValid = valid
    }
  }
}
</script>
