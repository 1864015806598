<template>
  <v-row class="my-2">
    <v-col cols="3" class="pr-0 d-flex flex-column align-center">
      <ToothPicker
        v-model="teeth"
        :convention="internationalToothConvention"
        style="max-height: 350px"
        :interactive="interactive ? true : false"
      />
      <v-btn-toggle v-model="internationalToothConvention" class="rounded-10">
        <v-btn x-small value="fdi">FDI</v-btn>
        <v-btn x-small value="palmer">Pal.</v-btn>
        <v-btn x-small value="universal">Uni.</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col class="mr-4">
      <v-card-title v-if="interactive" class="title-font pl-0">
        MULTI-UNIT ABUTMENT SELECTION
      </v-card-title>
      <v-card-text v-if="interactive" class="black--text pl-0">
        Confirm the Multi-Unit Abutments used by the clinician. Select the
        location of each MUA in the closest matching tooth on the diagram.
      </v-card-text>
      <v-row>
        <v-col>
          <v-card outlined class="rounded-10">
            <v-card-title class="pa-2 pb-0 secondary--text">
              Upper Arch
            </v-card-title>
            <v-list dense class="mx-2">
              <v-list-item
                v-for="(tooth, index) in selected?.filter(
                  (item) => item.tooth < 30
                )"
                :key="index"
                :style="interactive ? 'min-height: 50px' : ''"
                class="accent my-1 rounded"
              >
                <v-row>
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-list-item-title class="text-center">
                      <strong>
                        {{ getToothNumber(tooth.tooth) }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="10" class="text-caption pa-0 primary--text">
                    <v-select
                      v-if="interactive"
                      v-model="tooth.mua"
                      :items="muaResponses"
                      label="Select MUA"
                      dense
                      hide-details
                      :menu-props="{ rounded: '10' }"
                      class="mx-4"
                    />
                    <v-list-item-title v-if="!interactive" class="text-center">
                      <strong>
                        {{ tooth.mua }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-btn
                      v-if="interactive"
                      icon
                      x-small
                      @click="teeth.splice(teeth.indexOf(tooth.tooth), 1)"
                    >
                      <v-icon small color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined class="rounded-10">
            <v-card-title class="pa-2 pb-0 secondary--text">
              Lower Arch
            </v-card-title>
            <v-list dense class="mx-2">
              <v-list-item
                v-for="(tooth, index) in selected?.filter(
                  (item) => item.tooth > 30
                )"
                :key="index"
                :style="interactive ? 'min-height: 50px' : ''"
                class="accent my-1 rounded"
              >
                <v-row>
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-list-item-title class="text-center">
                      <strong>
                        {{ getToothNumber(tooth.tooth) }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-col cols="10" class="text-caption pa-0">
                    <v-select
                      v-if="interactive"
                      v-model="tooth.mua"
                      :items="muaResponses"
                      label="Select MUA"
                      dense
                      hide-details
                      class="mx-4"
                      :menu-props="{ rounded: '10' }"
                    />
                    <v-list-item-title v-if="!interactive" class="text-center">
                      <strong>
                        {{ tooth.mua }}
                      </strong>
                    </v-list-item-title>
                  </v-col>
                  <v-spacer />
                  <v-col
                    cols="1"
                    class="text-caption pa-0 d-flex align-center justify-center"
                  >
                    <v-btn
                      v-if="interactive"
                      icon
                      x-small
                      @click="teeth.splice(teeth.indexOf(tooth.tooth), 1)"
                    >
                      <v-icon small color="red">mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import client from "@/lib/ApiClient"
import ToothPicker from "@/components/shared/ToothPicker"
import internationalToothNumbers from "@/components/shared/international_tooth_numbers"

export default {
  name: "MultiUnitConfirmation",

  props: ["order", "value", "responses", "interactive"],

  components: { ToothPicker },

  data() {
    return {
      teeth: [],
      internationalToothConvention: "fdi",
      fetchedResponses: []
    }
  },

  async mounted() {
    if (!this.responses) {
      let questions = await client.instance.orders.getQuestions()
      this.fetchedResponses = this.availableResponses(
        questions.find((item) => item.widget === "tooth_picker")
      )
    }
    this.teeth = this.selected.map((item) => item.tooth)
  },

  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    muaResponses() {
      if (this.responses) {
        return this.responses
      } else {
        return this.fetchedResponses
      }
    }
  },

  watch: {
    teeth(newVal) {
      if (newVal.length > this.selected.length) {
        this.selected.push({
          tooth: newVal[newVal.length - 1],
          mua: ""
        })
      } else if (newVal.length < this.selected.length) {
        this.selected = this.selected.filter((item) =>
          this.teeth.includes(item.tooth)
        )
      }
    }
  },

  methods: {
    availableResponses(question) {
      return question.metadata?.responses?.map((response) => {
        return {
          text: response.name,
          value: response.name
        }
      })
    },

    getToothNumber(toothNumber) {
      return internationalToothNumbers[toothNumber][
        this.internationalToothConvention
      ].padStart(2, "0")
    }
  }
}
</script>
