<template>
  <v-container
    fluid
    fill-height
    class="black d-flex align-center justify-center"
  >
    <SelectTenant
      v-model="selectedTenantUid"
      @onContinue="onContinue"
      :tenants="tenants"
    />
  </v-container>
</template>

<script>
import SelectTenant from "./SelectTenantPage/SelectTenant"

import { mapActions } from "vuex"

export default {
  name: "SelectTenantPage",

  props: ["tenants"],

  components: { SelectTenant },

  data() {
    return {
      selectedTenantUid: null
    }
  },

  methods: {
    ...mapActions(["setCurrentTenant"]),

    onContinue() {
      this.setCurrentTenant(
        this.tenants.find((item) => item.tenant_uid === this.selectedTenantUid)
      )
      // TODO: Set Subscription in Store

      this.$router.push("/inbox")
    }
  }
}
</script>
