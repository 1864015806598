<template>
  <div>
    <v-card flat class="rounded-20 ma-4">
      <v-row class="ma-0">
        <v-col cols="8" class="pa-0">
          <v-card-title class="title-font">
            <v-icon large class="mr-2">$toothdesigns</v-icon> DESIGN ONLY ORDER
            <v-progress-circular class="ml-5" v-if="loading" indeterminate />
          </v-card-title>
          <v-card-text v-if="workflowStep === 0">
            Confirm your order by continuing.
          </v-card-text>
          <v-card-text v-if="workflowStep === 1">
            Continue your order by completing the necessary options.
          </v-card-text>
          <v-card-text v-if="workflowStep === 2">
            Your case has been submitted, awaiting review.
          </v-card-text>
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-card-text class="fill-width pa-0 d-flex justify-center">
            <v-img
              :src="require('@/assets/design-only-prosthesis.png')"
              class="ma-4"
              contain
              max-width="200"
            ></v-img>
          </v-card-text>
        </v-col>
      </v-row>
      <div v-if="workflowStep === 0">
        <v-btn
          class="rounded-10 btn-primary white--text my-4 mx-4"
          @click="handleContinue"
        >
          Continue Order
        </v-btn>
        <v-btn class="rounded-10" elevation="0" @click="$emit('back')">
          Go Back
        </v-btn>
      </div>
      <div v-if="workflowStep === 1">
        <WorkflowStep1
          :order="order"
          v-model="options"
          @back="handleStepChange(--workflowStep)"
          @next="handleStepChange(++workflowStep)"
        />
      </div>
    </v-card>
    <CaseChat v-if="workflowStep > 1" :order="order" class="mt-4" />
    <!-- <v-dialog v-model="dialog" width="30vw" content-class="rounded-20">
      <PurchaseCard
        v-model="dialog"
        :item="lineItem"
        :acquisitionMethod="acquisitionMethod"
        :loading="loading"
        @confirm="handleConfirm"
      />
    </v-dialog> -->
  </div>
</template>

<style>
.btn-hover:hover {
  cursor: pointer;
  background-color: #bbdfff80;
}

.btn-selected {
  background-color: #bbdfff80 !important;
  border-color: #007bee !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex"
import WorkflowStep1 from "./DesignOnlyOrder/WorkflowStep1"
import CaseChat from "@/components/shared/CaseChat/CaseChat"
// import PurchaseCard from "@/components/shared/PurchaseCard.vue"
import client from "@/lib/ApiClient"

export default {
  name: "DesignOnlyOrder",

  components: {
    CaseChat,
    WorkflowStep1
    // PurchaseCard
  },

  props: ["order", "value"],

  data() {
    return {
      loading: false,
      // acquisitionStrategy: null,
      workflowStep: 0,
      dialog: false
      // partialOrders: [],
      // lineItem: {
      //   description: "Design Only",
      //   amount: "500",
      //   currency: "AUD",
      //   quantity: 1,
      //   product_code: "DESIGN",
      //   quota_code: "designs"
      // }
    }
  },

  computed: {
    ...mapGetters(["selectedOrder", "paymentMethods"]),

    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    },

    acquisitionMethod() {
      return this.acquisitionStrategy?.find((s) => s.product_code === "DESIGN")
        .method
    }
  },

  methods: {
    ...mapActions([
      "setSelectedForwardedOrder",
      "setCurrentQuotas",
      "setPaymentMethods"
    ]),

    handleStepChange(step) {
      this.workflowStep = step
    },

    async handleContinue() {
      await this.createForwardedOrder()
      return this.workflowStep++

      // this.loading = true
      // const purchaseForm = {
      //   items: [
      //     {
      //       product_code: "DESIGN",
      //       quantity: 1
      //     }
      //   ],
      //   payment_type: "stripe",
      //   order_uid: this.selectedOrder.uid
      // }
      // this.acquisitionStrategy = await client.gateway.billing.checkStrategy(
      //   purchaseForm
      // )
      // this.loading = false
      // this.dialog = true
    },

    async createForwardedOrder() {
      const form = {
        product: "DESIGN",
        step: 1
      }
      const forwardedOrder = await client.instance.orders.createForwardedOrder(
        this.selectedOrder.uid,
        form
      )
      this.setSelectedForwardedOrder(forwardedOrder)
    },

    checkTokenActivation(token) {
      if (!token) return false
      const activated = token.product_codes.find(
        (p) => p.product_code === "BAR"
      ).activated

      return activated > 0 ? true : false
    },

    async pollTokenActivation(token) {
      const polledToken = await client.gateway.billing.checkToken(token.uid)
      const activated = this.checkTokenActivation(polledToken)
      if (!activated)
        return setTimeout(this.pollTokenActivation, 500, polledToken)
      else await this.createForwardedOrder(token)
    },

    async handleConfirm() {
      this.loading = true
      const purchaseForm = {
        items: [
          {
            product_code: "BAR",
            quantity: 1
          }
        ],
        payment_type: "stripe",
        order_uid: this.selectedOrder.uid
      }
      const token = await client.gateway.billing.commitPurchase(purchaseForm)
      if (!this.checkTokenActivation(token))
        await this.pollTokenActivation(token)
      else await this.createForwardedOrder(token)

      if (this.acquisitionMethod === "quota") {
        const quotasResponse = await client.gateway.billing.fetchQuotas()
        this.setCurrentQuotas(quotasResponse)
      }
      this.loading = false
      this.workflowStep++
      this.dialog = false
    }
  },

  async mounted() {
    const result = await client.instance.orders.getForwardedOrders(
      this.selectedOrder.uid
    )
    this.partialOrders = result
      .filter((o) => !o.submitted_at)
      .sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )
  }
}
</script>
