<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.9951 14.0833L14.4142 10.5025L13 9.08824L11.5858 10.5025L8.00491 14.0833H6.5C4.70507 14.0833 3.25 12.6283 3.25 10.8333C3.25 9.03841 4.70507 7.58333 6.5 7.58333C6.85556 7.58333 7.03333 7.58333 7.16126 7.54882C7.4028 7.48367 7.51659 7.41002 7.67496 7.21636C7.75884 7.11378 7.85811 6.89267 8.05665 6.45044C8.90363 4.56389 10.7987 3.25 13.0007 3.25C15.2031 3.25 17.0985 4.56444 17.9452 6.45163C18.1431 6.89266 18.242 7.11318 18.3255 7.21551C18.4841 7.40993 18.5984 7.48391 18.8407 7.54905C18.9683 7.58333 19.1455 7.58333 19.5 7.58333C21.2949 7.58333 22.75 9.03841 22.75 10.8333C22.75 12.6283 21.2949 14.0833 19.5 14.0833H17.9951Z"
      fill="#BBBBBB"
    />
    <path
      d="M12.9993 13L12.2922 12.2929L12.9993 11.5858L13.7065 12.2929L12.9993 13ZM13.9993 22.75C13.9993 23.3023 13.5516 23.75 12.9993 23.75C12.4471 23.75 11.9993 23.3023 11.9993 22.75L13.9993 22.75ZM7.95891 16.6262L12.2922 12.2929L13.7065 13.7071L9.37312 18.0404L7.95891 16.6262ZM13.7065 12.2929L18.0398 16.6262L16.6256 18.0404L12.2922 13.7071L13.7065 12.2929ZM13.9993 13L13.9993 22.75L11.9993 22.75L11.9993 13L13.9993 13Z"
      fill="#BBBBBB"
    />
  </svg>
</template>

<script>
export default {
  name: "UploadIcon"
}
</script>
