<template>
  <v-dialog v-model="dialog" width="30vw" content-class="rounded-20">
    <PurchaseCard
      v-model="dialog"
      :item="lineItem"
      :loading="loading"
      @confirm="orderScanGaugeKit"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import client from "@/lib/ApiClient"
import PurchaseCard from "@/components/shared/PurchaseCard.vue"

export default {
  name: "PurchaseDialog",

  props: ["value"],

  components: { PurchaseCard },

  data() {
    return {
      loading: false,
      invoice_uid: null,
      lineItem: {
        description: "Scan Gauge Kit",
        amount: "3 995",
        currency: "AUD",
        quantity: 1,
        product_code: "GAUGEKIT-GEN2",
        quota_code: "registered_kits"
      }
    }
  },

  computed: {
    ...mapGetters(["currentQuotas"]),

    dialog: {
      get() {
        return this.value
      },

      set(value) {
        return this.$emit("input", value)
      }
    }
  },

  methods: {
    ...mapActions(["setCurrentQuotas"]),

    async poll() {
      if (!this.invoice_uid) return
      const invoice_paid = await client.gateway.billing.checkInvoice(
        this.invoice_uid
      )
      if (invoice_paid) {
        const quotasResponse = await client.gateway.billing.fetchQuotas()
        this.setCurrentQuotas(quotasResponse)
        this.$emit("refresh")
        this.loading = false
        this.dialog = false
      } else setTimeout(this.poll, 500)
    },

    async orderScanGaugeKit() {
      this.loading = true
      try {
        this.invoice_uid = await client.gateway.gaugeKits.orderScanGaugeKit()
      } catch {
        // Error states?
        console.error("Failed to order Scan Gauge Kit.")
        this.loading = false
        return
      }
      this.poll()
    }
  }
}
</script>
