<template>
  <div>
    <ChooseOrder
      :order="order"
      v-if="!orderOptions.orderType"
      @chooseOrder="chooseOrder"
      @cancelled="cancel"
    />
    <MilledBarOrder
      v-if="orderOptions.orderType === 'milled_bar'"
      @back="chooseOrder(null)"
      @toExport="$emit('toExport')"
      :order="order"
      v-model="orderOptions"
    />
    <DesignOnlyOrder
      v-if="orderOptions.orderType === 'design_only'"
      @back="chooseOrder(null)"
      :order="order"
      v-model="orderOptions"
    />
  </div>
</template>

<script>
import ChooseOrder from "./OrderComponent/ChooseOrder"
import MilledBarOrder from "./OrderComponent/MilledBarOrder"
import DesignOnlyOrder from "./OrderComponent/DesignOnlyOrder"

export default {
  name: "OrderComponent",

  props: ["order"],

  components: { ChooseOrder, MilledBarOrder, DesignOnlyOrder },

  data() {
    return {
      orderOptions: {}
    }
  },

  methods: {
    chooseOrder(type) {
      this.$set(this.orderOptions, "orderType", type)
    },

    cancel() {
      this.$emit("cancelled")
    }
  }
}
</script>
