<template>
  <v-dialog
    v-model="dialog"
    max-width="1500px"
    content-class="rounded-20"
    style="z-index: 999999"
  >
    <v-card flat v-if="value">
      <v-card-title class="off">
        <span class="text-h5">{{ file.filename }}</span>
        <v-spacer />
        <v-btn v-if="file" class="btn-primary mr-2" @click="downloadFile()">
          <v-icon class="mr-2">mdi-download</v-icon>
          Download
        </v-btn>
        <v-btn class="rounded-10" text @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card flat class="overflow-y-auto overflow-x-hidden">
        <v-container fluid>
          <v-img
            contain
            v-if="fileType === 'image'"
            :src="`${file.url}`"
            max-height="80vh"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader type="image" />
            </template>
          </v-img>
          <div v-if="fileType === 'html'" class="iframe-container">
            <iframe :src="`${file.url}`" />
          </div>
          <div>
            <ModelViewer
              v-if="fileType === 'model'"
              :filename="file.filename"
              :url="file.url"
              :height="720"
              :width="1450"
              :camera-positions="{ x: 20, y: 60, z: 0 }"
            />
          </div>
          <v-row v-if="cannotView">
            <v-col class="text-center my-5">
              <p>We cannot preview this type of file...</p>
              <p class="mb-5">Please download it to your device</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelViewer from "@/components/shared/ModelViewer.vue"

export default {
  props: ["value"],

  components: { ModelViewer },

  computed: {
    file() {
      if (!this.value) {
        return
      }
      return this.value
    },

    dialog: {
      get() {
        if (this.value) {
          return true
        }
        return false
      },
      set(value) {
        this.$emit("input", value)
      }
    },

    fileType() {
      if (!this.file?.filename) {
        return
      }
      const fileExt = this.file.filename.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "html":
          return "html"
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
          return "image"
        case "ply":
        case "stl":
          return "model"
        default:
          return "download"
      }
    },

    cannotView() {
      if (this.fileType === "download") {
        return true
      }
      return false
    }
  },

  methods: {
    downloadFile() {
      window.open(this.file.url, "_blank")
      this.$emit("input", null)
    }
  }
}
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 80%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
