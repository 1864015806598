<template>
  <div>
    <v-divider class="mx-4" />
    <v-card-text class="py-0">
      <v-card-title class="title-font">
        UPLOAD PATIENT SCANS AND PHOTOS
      </v-card-title>
      <v-card-text class="pb-0">
        To accurately design a case, the correct scans and photos must be
        provided. Failure to provide adequate data may result in an undesirable
        design.
      </v-card-text>
      <v-card-text class="pt-1">
        <UploadProjectFiles v-model="files" />
      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>
import UploadProjectFiles from "./OrderOptions3/UploadProjectFiles"

export default {
  name: "OrderOptions3",

  components: { UploadProjectFiles },

  props: ["value", "order"],

  data() {
    return {
      files: {
        existingProsthesisInSituScan: null,
        tissueOfRestoringArchUsingNarrowScanBodies: null,
        existingProsthesisExtraOrally360UsingScanAnalogs: null,
        antagonistDentition: null,
        biteScan: null,
        fullFaceSmilingWithRelaxedBite: null,
        fullFaceSmilingWithRelaxedBiteUsingRetractors: null
      }
    }
  },

  computed: {
    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  watch: {
    files(files) {
      this.options.uploadedFiles = [...Object.values(files)]
    }
  }
}
</script>
