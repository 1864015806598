<template>
  <v-card
    outlined
    class="rounded-10 mb-1"
    style="max-width: 200px"
    @click="clicked"
  >
    <div v-if="!imageURL" class="text-center my-5">
      <v-progress-circular indeterminate />
    </div>
    <v-img v-if="imageURL" :src="imageURL" />
  </v-card>
</template>

<script>
export default {
  name: "ImagePreview",

  props: ["image"],

  data() {
    return {
      imageURL: this.image.url
    }
  },

  methods: {
    clicked() {
      this.$emit("clicked", this.image)
    }
  }
}
</script>
