<template>
  <v-card flat class="rounded-20" height="70vh">
    <v-card-title class="title-font ml-1 mt-6">
      SCANS &amp; FILES
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="2">
          <v-list
            dense
            class="transparent pa-0"
            v-for="(category, index) in categories"
            :key="index"
          >
            <v-list-item
              class="mb-2"
              style="background-color: #c7c9c720"
              :input-value="selectedCategory === category.value"
              @click="selectedCategory = category.value"
            >
              <v-list-item-content class="title-font">
                <v-list-item-title class="text-wrap">
                  {{ category.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-wrap">
                  {{ category.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-chip
                small
                :color="fileCount(category.value) > 0 ? 'primary' : ''"
                class="rounded-10"
              >
                {{ fileCount(category.value) }}
              </v-chip>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="10">
          <v-card
            flat
            class="rounded-10 overflow-y-auto"
            max-height="65vh"
            color="#c7c9c720"
          >
            <v-card-text>
              <v-row v-if="!filteredFiles.length && loading">
                <v-col cols="3" v-for="index in 4" :key="index">
                  <v-skeleton-loader type="image" />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  class="text-center"
                  v-if="!filteredFiles.length && !loading"
                >
                  No files found
                </v-col>
                <v-col
                  cols="3"
                  v-for="(file, index) in filteredFiles"
                  :key="index"
                >
                  <v-card
                    flat
                    class="rounded-10 d-flex flex-column"
                    height="100%"
                    @click="handleSelection(file)"
                  >
                    <v-row align="center">
                      <v-col>
                        <v-img
                          v-if="fileType(file) === 'image'"
                          height="150"
                          contain
                          :src="file.url"
                        />
                        <div @click="(e) => e.stopImmediatePropagation()">
                          <ModelViewer
                            v-if="fileType(file) === 'model'"
                            :filename="file.filename"
                            :url="file.url"
                            :height="150"
                            :camera-positions="{ x: 0, y: 60, z: 0 }"
                          />
                        </div>
                        <v-card
                          v-if="
                            fileType(file) === 'download' ||
                            fileType(file) === 'html'
                          "
                          flat
                          max-height="150"
                        >
                          <v-card-text class="text-center">
                            <v-icon>mdi-information</v-icon>
                            Cannot Preview File
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-card-text class="text-center">
                      <p class="off rounded-10">
                        {{ file.filename }}
                      </p>
                      <v-chip
                        small
                        :color="!!file.file_category ? 'success' : ''"
                        class="rounded-10"
                      >
                        <div class="text-truncate">
                          {{
                            prettyFileCategory(file.file_category) ||
                            "Uncategorized"
                          }}
                        </div>
                      </v-chip>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import client from "@/lib/ApiClient"
import ModelViewer from "@/components/shared/ModelViewer.vue"
export default {
  props: ["category", "files"],
  components: { ModelViewer },
  data() {
    return {
      loading: false,
      selectedCategory: "",
      selectedFile: ""
    }
  },
  computed: {
    categories() {
      return [
        { title: "All Uncategorized Files", subtitle: "", value: "" },
        this.category
      ]
    },
    filteredFiles() {
      if (!this.selectedCategory) {
        return this.files.filter((file) => file.file_category === "")
      }
      return this.files.filter(
        (file) => file.file_category === this.selectedCategory
      )
    }
  },
  methods: {
    async setFileCategory(file, category) {
      this.loading = true
      await client.instance.orders.updateFileCategory(
        this.selectedOrder.uid,
        file.uid,
        category
      )
      // this.fetchOrderFiles()
      this.loading = false
    },

    fileCount(category) {
      return this.files.filter((file) => file.file_category === category).length
    },

    fetchFileUrl(category) {
      return this.files.filter((file) => file.file_category === category)[0].url
    },

    fileType(file) {
      if (!file?.filename) {
        return
      }
      const fileExt = file.filename.toLowerCase().split(".").pop()
      switch (fileExt) {
        case "html":
          return "html"
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
          return "image"
        case "ply":
        case "stl":
          return "model"
        default:
          return "download"
      }
    },

    prettyFileCategory(value) {
      if (!value) {
        return
      }
      const category = this.categories.find(
        (category) => category.value === value
      )

      return `${category.title} ${category.subtitle}`
    },

    handleSelection(file) {
      if (file.file_category === "")
        this.setFileCategory(file, this.category.value)
      this.$emit("selection", file, this.category.value)
    }
  },
  mounted() {
    this.$nextTick(() => {
      const categorisedFiles = this.files.filter(
        (file) => file.file_category === this.category.value
      )

      if (categorisedFiles.length) this.selectedCategory = this.category.value
    })
  }
}
</script>
