<template>
  <div>
    <v-btn
      block
      outlined
      color="error"
      class="rounded-10"
      @click="dialog = true"
    >
      Request Changes
    </v-btn>
    <v-dialog v-model="dialog" max-width="600px" content-class="rounded-20">
      <v-card>
        <v-card-title>IMPORTANT: REQUEST CHANGES</v-card-title>
        <v-card-text>
          <p>
            You will receive another design for approval before the case
            continues to production. This is
            <strong>recommended for all major changes.</strong>
          </p>
          <v-textarea
            v-model="requestChanges"
            outlined
            hide-details
            placeholder="Enter changes to be made"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn class="btn-primary">Request Changes</v-btn>
          <v-btn elevation="0" class="rounded-10" @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      requestChanges: ""
    }
  }
}
</script>
