export default [
  {
    plan_code: "BASIC",
    country_code: "AU",
    currency_code: "AUD",
    price: 1999
  },
  {
    plan_code: "BASIC",
    country_code: "US",
    currency_code: "USD",
    price: 1999
  },
  {
    plan_code: "PREMIUM",
    country_code: "AU",
    currency_code: "AUD",
    price: 3999
  },
  {
    plan_code: "PREMIUM",
    country_code: "US",
    currency_code: "USD",
    price: 3999
  },
  {
    plan_code: "PRO",
    country_code: "AU",
    currency_code: "AUD",
    price: 5999
  },
  {
    plan_code: "PRO",
    country_code: "US",
    currency_code: "USD",
    price: 5999
  }
]
