<template>
  <div>
    <v-divider class="mx-4" />
    <MultiUnitConfirmation
      v-model="options.muaConfirmation"
      :interactive="true"
    />
    <v-card-text>
      <v-card-title class="title-font"> CONNECTION TYPE </v-card-title>
      <v-card-text>
        Choose which type of connection the file will be designed to.
      </v-card-text>
      <v-row class="mx-1 mb-2">
        <v-col cols="5">
          <v-card
            outlined
            :class="[
              'd-flex flex-row rounded-10 pa-2 fill-height btn-hover',
              { 'btn-selected': options.connectionType === 'direct' }
            ]"
            v-ripple
            style="user-select: none; border-width: 2px"
            @click="handleButtonSelect('direct')"
          >
            <v-img
              contain
              :src="require('@/assets/screw-comparison-rosen.png')"
            ></v-img>
            <v-card-text class="fill-height d-flex flex-column justify-center">
              <p class="mb-0">
                <strong>Direct to Multi-Unit Abutment</strong>
              </p>
              <p class="mb-0">with Tapered Screw seat (Rosen)</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="2" />
        <v-col cols="5">
          <v-card
            outlined
            :class="[
              'd-flex flex-row rounded-10 pa-2 fill-height btn-hover ',
              { 'btn-selected': options.connectionType === 'tiBase' }
            ]"
            v-ripple
            style="user-select: none; border-width: 2px"
            @click="handleButtonSelect('tiBase')"
          >
            <v-img
              contain
              :src="require('@/assets/screw-comparison-Ti-base.png')"
            ></v-img>
            <v-card-text class="fill-height d-flex flex-column justify-center">
              <p class="mb-0">
                <strong>Ti-Base to Multi-Unit Abutment</strong>
              </p>
              <p class="mb-0">with prosthetic screw seat</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import MultiUnitConfirmation from "@/components/shared/MultiUnitConfirmation"

export default {
  name: "OrderOptions1",

  props: ["order", "value"],

  components: { MultiUnitConfirmation },

  data() {
    return {}
  },

  computed: {
    options: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit("input", value)
      }
    }
  },

  watch: {
    muaConfirmation(newVal) {
      this.$set(this.options, "muaConfirmation", newVal)
    }
  },

  methods: {
    handleButtonSelect(selection) {
      if (selection === this.options.connectionType) {
        this.$set(this.options, "connectionType", null)
      } else {
        this.$set(this.options, "connectionType", selection)
      }
    }
  }
}
</script>
