<template>
  <v-row>
    <v-col>
      <input
        type="file"
        ref="fileInput"
        style="display: none"
        multiple
        @change="handleFileUpload"
      />
      <v-menu top offset-y rounded="10">
        <template v-slot:activator="{ on, attrs }">
          <strong v-bind="attrs" v-on="on">
            {{ selectedVisibility }}
          </strong>
        </template>
        <v-list dense nav>
          <v-list-item
            v-for="(item, index) in visibilityItems"
            :key="index"
            @click="visibility = item.value"
          >
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-textarea
        v-model="note"
        class="rounded-10"
        hide-details
        outlined
        dense
        rows="1"
        placeholder="Message"
        auto-grow
        prepend-inner-icon="mdi-paperclip"
        @click:prepend-inner="openFileInput"
      >
        <template v-slot:append>
          <v-btn icon small :loading="sendingMessage" @click="sendMessage()">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </template>
      </v-textarea>
    </v-col>
    <v-col v-if="attachments.length > 0" cols="12" align="end">
      <v-chip
        small
        v-for="(attachment, index) in attachments"
        :key="index"
        close
        class="ma-1 rounded-10"
        @click:close="removeAttachment(index)"
      >
        <v-icon small>mdi-paperclip</v-icon>
        <span class="text-truncate">
          {{ attachment.name }}
        </span>
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: ["sendingMessage"],

  data() {
    return {
      note: "",
      attachments: [],
      visibility: "LAB_ONLY",
      visibilityItems: [
        { text: "Lab Internal Notes", value: "LAB_ONLY" },
        { text: "Lab and Dentist Messages", value: "LAB_AND_DENTIST" },
        { text: "Lab and Osteon Messages", value: "LAB_AND_OSTEON" }
      ]
    }
  },

  computed: {
    ...mapGetters(["currentTenant"]),
    selectedVisibility() {
      return this.visibilityItems.find((item) => item.value === this.visibility)
        .text
    }
  },

  methods: {
    async sendMessage() {
      if (!this.note.length) {
        return
      }
      const formData = new FormData()
      formData.append("direction", "OUTBOUND")
      formData.append("visibility", this.visibility)
      formData.append("author_reference", this.currentTenant.name)
      formData.append("recipient_reference", "")
      formData.append("note", this.note)
      this.attachments.forEach((attachment) => {
        formData.append("attachments", attachment, attachment.name)
      })
      this.$emit("compose", formData)
      this.reset()
    },

    removeAttachment(index) {
      this.attachments.splice(index, 1)
    },

    reset() {
      this.note = ""
      this.attachments = []
    },

    openFileInput() {
      this.$refs.fileInput.click()
    },

    handleFileUpload(event) {
      const files = [...event.target.files]
      files.forEach((file) => {
        this.attachments.push(file)
      })
    }
  }
}
</script>
