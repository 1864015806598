<template>
  <v-dialog v-model="dialog" max-width="500px" content-class="rounded-20">
    <v-card>
      <v-card-title class="text-h5"> Cancel your subscription? </v-card-title>
      <v-card-text>
        This will remove your access to the Nexus Connect Inbox.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="btn-error" @click="dialog = false">Abort</v-btn>
        <v-btn class="btn-primary" @click="cancelSubscription">
          Confirm Cancellation
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import client from "@/lib/ApiClient"

export default {
  name: "CancelDialog",

  props: ["value"],

  data() {
    return {
      loading: false
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },

      set(payload) {
        return this.$emit("input", payload)
      }
    }
  },

  methods: {
    async cancelSubscription() {
      await client.gateway.billing.cancelSubscription()
    }
  }
}
</script>
